import { createAction, createAsyncAction } from 'typesafe-actions';
import { Organization } from 'OrganizationFeature';

export const fetchOrganizationAsync = createAsyncAction(
  'LOAD_ORGANIZATION_REQUEST',
  'LOAD_ORGANIZATION_SUCCESS',
  'LOAD_ORGANIZATION_FAILURE',
)<void, Organization, string>();

export const clearError = createAction('CLEAR_ERROR', () => { })();

