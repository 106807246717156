import styled from 'styled-components/native';

type Props = {
  height: string;
  width?: string;
};

const SpacingView = styled.View<Props>`
  width: ${(props) => `${props.width}` ?? '100%'};
  height: ${(props) => `${props.height}` ?? '100%'};
`;

export default SpacingView;