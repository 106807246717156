import React, { useEffect } from 'react';
import { FlatList } from 'react-native';
import {connect} from 'react-redux';
import {RootState} from 'StoreTypes';
import { User } from 'UserFeature';
import { Booking } from 'BookingFeature';
import { Language } from 'OrganizationFeature';
import _ from 'lodash';
import { StyledText } from '@space/common';
import { localizedName } from '../../localization';
import moment from 'moment';
import config from '../../config';
import { loadUsersAsync } from '@features/user/actions';
import { colors } from '@features/preferences/style/themes';

type UserBookingSpacesProps = {
  user: User;
  activeLanguages: Language[];
  item: Booking;
};

const mapStateToProps = (state: RootState) => ({
  activeLanguages: state.organization.languages,
  selectedDate: state.booking.selectedDate,
});

const dispatchProps = {
  fetchUsers: loadUsersAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & UserBookingSpacesProps & any;

const UserBookingSpaces: React.FC<Props> = ({
  user,
  activeLanguages,
  selectedDate,
  fetchUsers
}) => {

  const findBookingSpaces = () => {

    const selectedDayBookings = user.bookings.filter((booking: Booking) => 
      moment(booking.schedule.startDate).format(config.defaultDateFormat) === selectedDate);

    const getSpacesName = selectedDayBookings.map((booking: Booking) => 
      localizedName(_.first(booking.spaces).name, activeLanguages));

    const sortedSpacesName = getSpacesName.sort((a: string, b: string) => {
      return a
        .localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    });
    return sortedSpacesName; 
  }

  useEffect(() => {
    fetchUsers();
  }, [selectedDate])

  const renderItem = ({item}) => {

    return (
      <>
        <StyledText variant={'body1'} fontColor={colors.grey8}>
          {item}
        </StyledText>
      </>
    )
  };

  return (
    <>
      <FlatList
        data={findBookingSpaces()}
        renderItem={renderItem}
        keyExtractor={(item) => item}
      />
    </>
  ) 
}

export default connect(mapStateToProps, dispatchProps)(UserBookingSpaces);