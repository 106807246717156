import theme from 'styled-theming';

export type Colors =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success'
  | 'background'
  | 'stroke';

export type TextColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'disabled'
  | 'hint';

export const palette = {
  primary: theme('mode', {
    light: '#297F6F',
    dark: '#8DC89D',
  }),

  secondary: theme('mode', {
    light: '#BFD9D4',
    dark: '#3A6445',
  }),

  tertiary: theme('mode', {
    light: '#7D7D7D',
    dark: '#999999',
  }),

  destructive: theme('mode', {
    light: '#FF6A6A',
    dark: '#FF6A6A',
  }),

  error: theme('mode', {
    light: '#FF6A6A',
    dark: '#FF6A6A',
  }),

  warning: theme('mode', {
    light: '#EEBC09',
    dark: '#EEBC09',
  }),

  info: theme('mode', {
    light: '#2A8373',
    dark: '#FFF',
  }),

  success: theme('mode', {
    light: '#3A6445',
    dark: '#3A6445',
  }),

  common: theme('mode', {
    light: '#FFFFFF',
    dark: '#202020',
  }),

  commonInverted: theme('mode', {
    light: '#202020',
    dark: '#FFFFFF',
  }),

  border: theme('mode', {
    light: '#E0E0E0',
    dark: '#7D7D7D',
  }),

  background: theme('mode', {
    light: '#F2F2F2',
    dark: '#1C1C1C',
  }),

  disable: theme('mode', {
    light: '#E0E0E0',
    dark: '#E0E0E0',
  }),
  
  actionItem: theme('mode', {
    light: '#F2F2F2',
    dark: '#111111',
  }),

  text: theme.variants('mode', 'color', {
    primary: {light: '#202020', dark: '#FFFFFF'},
    secondary: {light: '#7D7D7D', dark: '#7D7D7D'},
    tertiary: {light: '#7D7D7D', dark: '#999999'},
    quaternary: {light: '#34A28E', dark: '#8DC89D'},
    disabled: {light: 'rgba(0, 0, 0, 0.38)', dark: 'rgba(255, 255, 255, 0.5)'},
    hint: {light: '#E0E0E0', dark: '#999999'},
  }),

  action: theme.variants('mode', 'state', {
    active: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    hover: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    hoverOpacity: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    selected: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    selectedOpacity: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    disabled: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    disabledBackground: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    disabledOpacity: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    focus: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    focusOpacity: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
    activatedOpacity: {light: 'rgba(0, 0, 0, 0.87)', dark: '#fff'},
  }),
};

export const colors = {
  grey1: '#F2F2F2',
  grey2: '#C5C5C5',
  grey3: '#999999',
  grey4: '#202020',
  grey5: '#202020',
  grey6: '#222222',
  grey7: '#595959',
  grey8: '#808080',
  grey9: '#757575',
  border: '#E0E0E0',
  white: '#FFFFFF',
  black: '#202020',
  primaryLight: '#297F6F',
  primaryDark: '#8DC89D',
  backgroundLight: '#FFFFFF',
  backgroundDark: '#1C1C1C',
  destructive: '#FF6A6A',
  quaternary: '#34A28E',
}

export const shadow = theme('mode', {
  light: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  dark: '0px 5px 10px rgba(0, 0, 0, 0.6)',
});
