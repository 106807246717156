import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreTypes';
import { colors, palette, shadow } from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import { Divider, StyledText } from '@space/common';
import { SpaceModel } from '@features/space';
import moment from 'moment';
import config from '../../config';
import { SpaceGroup, SpaceTag } from 'SpaceFeature';
import { Booking } from 'BookingFeature';
import { localize, localizedName } from '../../localization';
import Pod from '../../img/pod.svg';
import Seat from '../../img/seat.svg';
import Room from '../../img/room.svg';
import Edit from '../../img/edit_button.svg'
import Delete from '../../img/delete_button.svg'
import Calendar from '../../img/calendar.svg'
import _ from 'lodash';
import { Language } from 'OrganizationFeature';
import ModalOfficeMap from '@components/modal/ModalOfficeMap';
import SpaceHelper from '../../utils/SpaceHelper';
import { View } from 'react-native';
import Avatar from '@components/Avatar';
import { capitalize } from "../../utils/dateutils";

type BookingItemProps = {
  booking: Booking;
  spaceTags: SpaceTag[];
  spaceGroups: SpaceGroup[];
  isNew: boolean;
  onPress?: () => void;
  onClosePress?: () => void;
  activeLanguages: Language[];
  confirmationView: boolean;
  confirmPress?: () => void;
  cancelPress?: () => void;
};

const mapStateToProps = (state: RootState) => ({
  activeLanguages: state.organization.languages,
  spaceList: state.space.space,
});

const generateTitle = (booking: Booking, isNew: boolean) => {
  const title = moment(booking.schedule.startDate).format(config.defaultDateFormat) ===
    moment().format(config.defaultDateFormat)
    ? localize('BOOKING_TODAY_DATE', { date: capitalize(moment(booking.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_DAY_FORMAT')))})
    : capitalize(moment(booking.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_FULLDAY_FORMAT')));
  return title;
};

const generateSpacesName = (booking: Booking, activeLanguages: Language[], groups?: SpaceGroup[]) => {
  
  const bookingType = booking.spaces[0]?.type;
  if (bookingType.model === SpaceModel.POD) {
    return groups?.map((group, index) => {
      return index === 0 ? localizedName(group.name, activeLanguages) : ', ' + localizedName(group.name, activeLanguages);
    });
  } else {
    return booking.spaces.map((space, index) => {
      return index === 0 ? localizedName(space.name, activeLanguages) : ', ' + localizedName(space.name, activeLanguages);
    });
  }
};

const items = (booking: Booking, activeLanguages: Language[]): any[] => {
  return booking.users.map((user) => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      src: user.avatar,
      spaceName: localizedName(booking.spaces[0].name, activeLanguages),
      tags: booking.spaces[0].tagsList.map((tag: SpaceTag) => localizedName(tag.name, activeLanguages)).join(', '),
    };
  });
};

const occupationLabel = (
  isPodBooking: boolean,
  booking: Booking,
  groups?: SpaceGroup[],
) => {
  const occupation = isPodBooking ? booking.spaces.length : null;

  const bookingGroups = groups?.map((group) => group.spaces.length ?? 0);
  const groupMaxOccupation = bookingGroups?.reduce((a, b) => a + b, 0);

  return isPodBooking
    ? localize('SPACE_OCCUPATION',
      { users: occupation, of: groupMaxOccupation })
    : localize('SPACE_OCCUPATION_MAX', { capacity: booking.spaces[0]?.capacity });
};

const BookingItemView: React.FC<BookingItemProps> = ({
  booking,
  spaceGroups,
  spaceTags,
  isNew,
  activeLanguages,
  onClosePress,
  onPress,
  confirmationView,
  confirmPress,
  cancelPress
}) => {

  const bookingType = booking.spaces[0]?.type;
  const isPodBooking = bookingType.model === SpaceModel.POD;
  const groupids = booking.spaces.map((space) => space.groupId);
  const groups = spaceGroups.filter((group) => groupids.includes(group.id));
  const groupsTags = groups.map((group, index) => {
    const groupTags = group.tags.map((tag) => tag.name);
    return index === 0 ? groupTags : ', ' + groupTags;
  });
  const model = bookingType.model;
  const title = generateTitle(booking, isNew);
  const tags = isPodBooking
    ? groupsTags
    : spaceTags.filter((tag) => booking.spaces[0]?.tagsList.includes(tag.id)).map((tag) => tag.name);
  const user = _.first(booking.users);

  const svgType = (spaceModel: SpaceModel) => {

    const props = {
      width: '32px',
      height: '32px',
      fill: colors.black
    }

    if(spaceModel === SpaceModel.POD){
      return <Pod {...props}/>
    } else if(spaceModel === SpaceModel.ROOM){
      return <Room {...props}/>
    } else if (spaceModel === SpaceModel.SEAT){
      return <Seat {...props}/>
    } else {
      return <Seat {...props}/>
    }
  };    

  return (
    <Container>
      <View>
        {confirmationView && 
          <>
            <UserNameContainer>
              <View style={{paddingRight: '8px'}}>
                <Avatar
                  name={user.firstName}
                  lastName={user.lastName}
                  src={_.first(booking.users).avatar}
                  size={'small'}
                />
              </View>
              <View style={{top: '12px', flex: 1}}>
                <StyledText variant={'h3'} color={'primary'} letterSpacing={'0.01em'} lineHeight={'100%'}>
                  {`${user.firstName} ${user.lastName}`}
                </StyledText>
                <VerticalSpace/>
                <StyledText variant={'body1'} color={'primary'} letterSpacing={'0.01em'} lineHeight={'18px'}>
                  {user.jobTitle}
                </StyledText>
              </View>
            </UserNameContainer>
            <Divider variant={'middle'}/>
          </>
        }
        <CalendarContainer confirmationView={confirmationView}>
          {confirmationView &&
            <SvgContainer>
              <Calendar width={32} height={32}/>
            </SvgContainer>
          }
          <CalendarTextContainer confirmationView={confirmationView}>
            <HeaderTitleContainer>
              <StyledText
                variant={'h3'}
                color={'primary'}
                lineHeight={'100%'}
                letterSpacing={'0.01em'}>
                {title}
              </StyledText>
              {isNew ? (
                <NewItemContainer>
                  <StyledText variant={'caption'} fontWeight={'600'} fontColor={colors.black}>
                    {localize('NEW')}
                  </StyledText>
                </NewItemContainer>
              ) : null} 
            </HeaderTitleContainer>
            <HourDescriptionContainer isNew={isNew}>
            <StyledText
              variant={'body1'}
              color={'primary'}
              fontWeight={'400'}
              lineHeight={'18px'}
              letterSpacing={'0.01em'}>
              {localize('BOOKING_SUBTITLE')}
            </StyledText>
            </HourDescriptionContainer>
          </CalendarTextContainer>
        </CalendarContainer>
        <Divider variant={'middle'}/>
        <ContentContainer>
          <SvgContainer>
            {svgType(model)}
          </SvgContainer>
          <SpaceDescriptionContainer>
            <StyledText variant={'h3'} color={'primary'} letterSpacing={'0.01em'} lineHeight={'100%'}>
              {generateSpacesName(booking, activeLanguages, groups)}
            </StyledText>
            <TagsContainer>
              <StyledText variant={'body1'} color={'primary'} letterSpacing={'0.01em'} lineHeight={'20px'}>
                {_.first(booking.spaces).tagsList.map((tag: SpaceTag) => localizedName(tag.name, activeLanguages)).join(', ')}
              </StyledText>
            </TagsContainer>
            <ModalOfficeMap 
              plan={SpaceHelper.getMapImageUrl(booking.spaces[0], activeLanguages)} 
              activeLanguages={activeLanguages} 
              space={_.first(booking.spaces)}
              selected={confirmationView ? booking : null}
              spaceName={generateSpacesName(booking, activeLanguages, groups)}
              isFromMyReservation={true}
              isConfirmationView={confirmationView}/>
          </SpaceDescriptionContainer>
        </ContentContainer>
      </View>
      {confirmationView ? (
      <View style={{paddingTop: confirmationView ? '22px': '0'}}>
        <FooterContainer>
          <FooterContentContainer onPress={cancelPress}>
            <StyledText variant={'button'} fontColor={palette.primary}>
              {localize('CANCEL')}
            </StyledText>
          </FooterContentContainer>
          <ModifyContainer onPress={confirmPress}>
            <StyledText variant={'button'} fontColor={colors.white}>
              {localize('CONFIRM')}
            </StyledText>
          </ModifyContainer>
        </FooterContainer>
      </View>
      ) : (
      <>
        <Divider variant={'middle'}/>
        <FooterContainer>
          <FooterContentContainer onPress={onClosePress}>
            <DeleteSvgContainer>
              <Delete/>
            </DeleteSvgContainer>
            <StyledText variant={'button'} fontColor={palette.primary}>
              {localize('DELETE')}
            </StyledText>
          </FooterContentContainer>
          <ModifyContainer onPress={onPress}>
            <UpdateIcon>
              <Edit/>
            </UpdateIcon>
            <StyledText variant={'button'} fontColor={colors.white}>
              {localize('MODIFY')}
            </StyledText>
          </ModifyContainer>
        </FooterContainer>
      </>)}
      
    </Container>
  );
};

type ConfirmationProps = {
  confirmationView: boolean;
}

type HourDescriptionProps = {
  isNew: boolean;
}

const NewItemContainer = styled.View`
  border-radius: 8px;
  padding: 8px 10px 8px 10px;
  margin: 0px 0px 0px 8px;
  background-color: ${palette.border};
`;

const Container = styled.View`
  width: 100%;
  max-width: 343px;
  margin: 0px 0px 16px 0px;
  padding: 24px 0px 16px 0px;
  border-radius: 16px;
  box-shadow: ${shadow};
  background-color: ${colors.white};
  justify-self: center;
  align-self: center;
`;

const UserNameContainer = styled.View`
  flex-direction: row;
  padding: 0 16px;
  align-items: center;
  margin-bottom: 36px;
`;

const CalendarContainer = styled.View<ConfirmationProps>`
  flex-direction: ${({confirmationView}) => confirmationView ? 'row' : 'column'};
  justify-content: ${({confirmationView}) => confirmationView ? 'flex-start' : ''};
  padding: ${({confirmationView}) => confirmationView ? '24px 16px 24px 16px' : '0px 16px 24px 16px'};
`;

const CalendarTextContainer = styled.View<ConfirmationProps>`
  padding: ${({confirmationView}) => confirmationView ? '8px 0px 0px 8px' : '0'};
`;

const HeaderTitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const HourDescriptionContainer = styled.View<HourDescriptionProps>`
  padding-top: ${({isNew}) => isNew ? '0' : '8px'};
`;

const ContentContainer = styled.View`
  padding: 24px 16px 26px 16px;
  flex-direction: row;
  justify-content: flex-start;
`;

const SvgContainer = styled.View`
  width: 32px;
  height: 32px;
`;

const SpaceDescriptionContainer = styled.View`
  padding: 8px 0 0 8px;
`;

const TagsContainer = styled.View`
  padding: 4px 0 14px 0;
  max-width: 215px;
  flex-wrap: wrap;
`;

const FooterContainer = styled.View`
  flex-direction: row;
  margin: 16px 0px 0px 0px;
  max-width: 343px;
  padding: 0 16px;
  justify-content: space-between;
`;

const FooterContentContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 148px;
  width: 100%;
  height: 48px;
  border-radius: 32px;
  border: 1px solid ${palette.primary};
`;

const DeleteSvgContainer = styled.View`
  padding-right: 8px;
`;

const ModifyContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 148px;
  width: 100%;
  height: 48px;
  border-radius: 32px;
  background: ${palette.primary};
`;

const UpdateIcon = styled.View`
  padding-right: 8px;
`;

const VerticalSpace = styled.View`
  height: 8px;
`;
export default connect(mapStateToProps)(BookingItemView);



