import {
  Occupancy,
  Space,
  SpaceGroup,
  SpaceType,
  SpaceTypeWithAvailability,
  SpaceCache
} from 'SpaceFeature';
import { User } from 'UserFeature';
import { UserRole } from 'UserRoleFeature';
import moment from 'moment';
import _ from 'lodash';
import { SpaceModel } from '@features/space';
import { Language, Office, Organization } from 'OrganizationFeature';
import { RRuleSet, rrulestr } from 'rrule';
import config from '../config';
import { localizedName } from '../localization';
import _head from 'lodash/head';
import { SpaceHelper as CommonSpaceHelper } from "@space/common"

export class SpaceHelper {

  static isOfficeOpen = (
    office: Office,
    date: moment.Moment,
  ) => {
    return CommonSpaceHelper.isOfficeOpen(office, date, config.defaultDateFormat);
  };

  static isOfficeOpened = (date: String, officeOpenCache: Map<string, boolean>, spacesList: Space[], availableSpacesCache: Map<string, SpaceCache>) => {
    return CommonSpaceHelper.isOfficeOpened(date, officeOpenCache, spacesList, availableSpacesCache, config.defaultDateFormat);
  };

  static getRruleCurrentOffice = (organization: Organization) => {
    return CommonSpaceHelper.getRruleCurrentOffice(organization);
  }

  static occupationForDate = (
    spacesList: Space[],
    spacesOpen: Map<string, boolean>,
    date: string,
    type?: SpaceModel,
  ): Occupancy | null => {
    return CommonSpaceHelper.occupationForDate(spacesList, spacesOpen, date, type);
  };

  static cacheForOccupancy = (
    date: string, 
    availableSpacesCache: Map<string, SpaceCache>, 
    type?: SpaceModel,
  ): Occupancy | null => {
    return CommonSpaceHelper.cacheForOccupancy(date, availableSpacesCache, type);
  };

  static isOpenSpace = (space: Space, date: moment.Moment): boolean => {
    return CommonSpaceHelper.isOpenSpace(space, date, config.defaultDateFormat);
  }

  static isActiveSpace = (value: Date | Boolean): boolean => {
    return CommonSpaceHelper.isActiveSpace(value, config.defaultDateFormat);
  };

  static spaceGroupFromSpaceList = (spaceList: Space[]): any => {
    return CommonSpaceHelper.spaceGroupFromSpaceList(spaceList);
  };

  static availableSpacesPerType = (
    spacesList: Space[],
    availableSpacesCache: Map<string, SpaceCache>,
    spaceGroups: SpaceGroup[],
    date: moment.Moment
  ): SpaceTypeWithAvailability[] => {
    return CommonSpaceHelper.availableSpacesPerType(spacesList, availableSpacesCache, spaceGroups, date, config.defaultDateFormat);
  }

  static availableSpaceGroupsForType = (
    spacesList: Space[],
    spaceGroupsList: SpaceGroup[],
    date: moment.Moment,
    type: SpaceType
  ): SpaceGroup[] => {
    return CommonSpaceHelper.availableSpaceGroupsForType(spacesList, spaceGroupsList, date, type, config.defaultDateFormat);
  }; 

  static groupOccupationForDate = (
    spacesList: Space[],
    spaceGroups: SpaceGroup[],
    date: string,
    model?: SpaceModel,
    type?: SpaceType
  ) => {
    return CommonSpaceHelper.groupOccupationForDate(spacesList, spaceGroups, date, model, type);
  }

  static userMaxReservation = (
    user: User,
  ) => {
    const maxBookings = !_.isEmpty(user.rolesList) ? Math.max(...user.rolesList.map((role : UserRole) => role.maxReservationNumber)) : 0 ;
    return maxBookings;
  };

  static getMapImageUrl = (space : Space, activeLanguages: Language[]) => {
    const deskNumber = _.last(localizedName(space.name, activeLanguages).split(' '));

    const languageFr: Language = activeLanguages.find((language) => language.name == "fr-ca");
    const spaceName = space.name.find((name) => name.languageId == languageFr?.id)?.name;

    if (spaceName?.includes('Bureau')) {
      return (`${config.officeMapImagesUrl}/${config.officeId}/bureau-${config.officeId}-user-${deskNumber}.jpg`)
    } else {
      return (`${config.officeMapImagesUrl}/${config.officeId}/bureau-${config.officeId}-user-${spaceName?.toLowerCase().replace(/[^\w]/g, "")}.jpg`)
    }
  }

  static availableSpacesForType = (
    spaces: Space[],
    availableSpacesCache: Map<string, SpaceCache>,
    date: moment.Moment,
    type: SpaceType,
    user: User
  ): Space[] => {
    const formatedDate = date.format(config.defaultDateFormat);
    const spacesOpen = availableSpacesCache.get(formatedDate)?.openSpaces ?? new Map();

    return spaces.filter(
      (item) =>
        item &&
        (item.type?.id ? item.type.id : item.type?.id) === type.id &&
        (spacesOpen && spacesOpen.get(item.id)) &&
        (_.isEmpty(item.groupsList) || user.groupsList?.some((userGroup: string) => SpaceHelper.spaceHasGroups(item.groupsList, userGroup))) &&
        item.bookings.filter((booking) =>
          date.isSame(moment(booking.schedule.startDate), 'day'),
        ).length === 0,
    );
  };

  static spaceHasGroups = (spaceGroups: any[], spaceGroup: any) : Boolean => {
    return spaceGroups.some((group => group.id === spaceGroup.id))
  };
}

export default SpaceHelper;
