import { AuthToken, LoginCredential } from "LoginFeature";
import config from "../config";

export const login = async (
  credential: LoginCredential
): Promise<AuthToken> => {
  return fetch(`${config.url.server}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'X-Office-Identifier': config.url.base
    },
    body: JSON.stringify(credential),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    });
};

export const logout = async (authToken: AuthToken): Promise<void> => {
  return fetch(`${config.url.server}/auth/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'X-Office-Identifier': config.url.base
    },
    body: JSON.stringify(authToken),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        throw Error(data.error);
      }
      return data;
    });
};

export const refreshAuthToken = async (
  refreshToken: string
): Promise<AuthToken> => {
  return fetch(`${config.url.server}/login/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'X-Office-Identifier': config.url.base
    },
    body: JSON.stringify({ refreshToken: refreshToken }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        throw Error(data.error);
      }
      return data;
    });
};
