import React, {useMemo} from 'react';
import {RootState} from 'StoreTypes';
import {connect, useDispatch} from 'react-redux';
import {ActivityIndicator, StyleSheet} from 'react-native';
import {createBookingAsync, updateBookingAsync, clearError} from '@features/booking/actions';
import styled from 'styled-components/native';
import { Divider, StyledText}  from '@space/common';
import {palette, shadow} from '@features/preferences/style/themes';
import BookingItemView from '@components/bookings/BookingItemView';
import {HomeViewName} from './HomeView';
import {
  selectBooking,
  setSelectedSpacesForBooking,
  setSelectedUserMappingForBooking,
} from '@features/booking/actions';
import { localize } from '../localization';
import {BookingViewName} from './BookingView';
import ModalHeader from '@components/modal/ModalHeader';
import { ConditionsViewName } from './ConditionsView';
import { handleResetStep } from '@features/booking/actions';
import TagManager from 'react-gtm-module'
import _ from 'lodash';
import moment from 'moment';
import { event } from '../utils/analysticsConstants'
import SpaceHelper from '../utils/SpaceHelper';

const mapStateToProps = (state: RootState) => ({
  bookingRequest: state.booking.lastBookingRequest,
  booking: state.booking.userLastBooking,
  selectedBooking: state.booking.selectedBooking,
  bookingError: state.booking.bookingError,
  currentUserBookings: state.booking.currentUserBookings,
  spaceGroups: state.space.spaceGroups,
  spaceTags: state.space.spaceTags,
  stepCounter: state.booking.stepCounter,
  organization: state.organization.organization
});

const dispatchProps = {
  createBooking: createBookingAsync.request,
  updateBooking: updateBookingAsync.request,
  clearBookingError: clearError,
  selectBooking: selectBooking,
  selectSpaces: setSelectedSpacesForBooking,
  selectUserMapping: setSelectedUserMappingForBooking,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const BookingDetailsView: React.FC<Props> = ({
  booking,
  bookingError,
  navigation,
  selectedBooking,
  currentUserBookings,
  createBooking,
  updateBooking,
  bookingRequest,
  clearBookingError,
  spaceGroups,
  selectBooking,
  selectSpaces,
  selectUserMapping,
  spaceTags,
  organization
}) => {

  const dispatch = useDispatch();

  const bookingStartDate = useMemo(() => {
    const officeTimezone = SpaceHelper.getRruleCurrentOffice(organization).options.tzid;
    return moment(selectedBooking?.schedule.startDate).tz(officeTimezone).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_ONLYDAY_FORMAT'));;
  }, [selectedBooking]); 
  
  const handleButtonPress = () => {
    selectedBooking.id ? updateBooking(selectedBooking) : createBooking(selectedBooking);
    navigation.navigate(HomeViewName);
    //clear booking state
    selectBooking(null);
    selectSpaces([]);
    selectUserMapping([]);
    dispatch(handleResetStep());
    TagManager.dataLayer({
      dataLayer: {
        event: `${selectedBooking.id ? event.GA4EditBooking : event.GA4BookSpace}`,
        current_page: `${selectedBooking.id ? 'Edit' : 'Made'} a reservation`,
        button_label: `${bookingStartDate} - ${_.first(selectedBooking.spaces).office.location}`,
        step: `4`,
      }
    })
  };

  const handleCancelPress = () => {
    selectSpaces([]);
    dispatch(handleResetStep())
    navigation.navigate(BookingViewName);
    TagManager.dataLayer({
      dataLayer: {
        event: event.GA4ConfirmBooking,
        current_page: `${selectedBooking.id ? 'Edit' : 'Made'} a reservation`,
        button_label: `${localize('CANCEL')}`,
        step: `4`,
        reservation_type: `${selectedBooking.id ? 'Edit' : 'Made'} a reservation`
      }
    })
  }

  const tryAgain = () => {
    clearBookingError();
    createBooking(bookingRequest);
  };

  const bookingItem = () => {
    const currentBooking = booking
      ? currentUserBookings.find((item) => item.id === booking.id)
      : null;

    if (bookingError) {
      return <StyledText>{bookingError}</StyledText>;
    } else if (selectedBooking) {
      return (
        <BookingItemView
          key={selectedBooking.id}
          booking={selectedBooking}
          spaceGroups={spaceGroups}
          spaceTags={spaceTags}
          isNew={false}
          confirmPress={handleButtonPress}
          cancelPress={handleCancelPress}
          confirmationView={true}
        />
      );
    } else {
      return <ActivityIndicator />;
    }
  };

  return (
    <Container>
        <ModalHeader
        leftAction={'back'}
        onPress={() => {
          navigation.navigate(ConditionsViewName);
        }}
        title={bookingError
            ? localize('APP_ERROR_TITLE')
            : localize('BOOKING_CONFIRMATION')}
      />
      <Divider />
      <ContentContainer>
        {bookingItem()}
        <BottomContainer>
          {bookingError ? (
            <ButtonContainer onPress={tryAgain}>
              <LeftContent>
                <StyledText>{localize('TRY_AGAIN')}</StyledText>
              </LeftContent>
            </ButtonContainer>
          ) : null}
        </BottomContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.View`
  height: 100vh;
  width: 100%;
  background-color: ${palette.background};
  align-items: center;
  padding: 0px 0px 48px 0px;
`;

const ContentContainer = styled.View`
  width: 100%;
  padding: 24px 0px 0px 0px;
`;

const LeftContent = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BottomContainer = styled.View`
  margin: 24px 0;
`;

const ButtonContainer = styled.TouchableOpacity`
  width: 100%;
  margin: 16px 0px 0px 0px;
  padding: 0px 24px 0px 24px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  border-color: ${palette.border};
  border-width: 1px;
  box-shadow: ${shadow};
  border-radius: 8px;
  justify-content: space-between;
`;

const styles = StyleSheet.create({
  title: {
    marginBottom: 20,
    marginHorizontal: 24,
    alignSelf: 'flex-start',
    justifySelf: 'center'
  },
});

export const BookingDetailsViewName = 'BookingDetailsView';

export default connect(mapStateToProps, dispatchProps)(BookingDetailsView);
