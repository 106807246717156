import {RootEpic} from 'StoreTypes';
import {from, of} from 'rxjs';
import {filter, switchMap, map, catchError} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {Space, SpaceGroup, SpaceTag} from 'SpaceFeature';

import {fetchSpaceGroupsAsync, fetchSpacesAsync, fetchSpaceTagsAsync} from './actions';
import {defer} from 'rxjs';

export const loadSpacesEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf(fetchSpacesAsync.request)),
    switchMap((action) =>
      defer(() => from(api.assistant.fetchSpaces(action.payload))).pipe(
        map((data) => data as Space[]),
        map(fetchSpacesAsync.success),
        catchError((error) => of(fetchSpacesAsync.failure(error))),
      ),
    ),
  );

export const fetchSpaceGroupsEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([fetchSpaceGroupsAsync.request])),
    switchMap((action) =>
      defer(() => from(api.assistant.fetchSpaceGroups())).pipe(
        map((data) => data as SpaceGroup[]),
        map(fetchSpaceGroupsAsync.success),
        catchError((error) => of(fetchSpaceGroupsAsync.failure(error.message))),
      ),
    ),
  );

  export const fetchSpaceTagsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
      filter(isActionOf([fetchSpaceTagsAsync.request])),
      switchMap((action) =>
        defer(() => from(api.assistant.fetchSpaceTags())).pipe(
          map((data) => data as SpaceTag[]),
          map(fetchSpaceTagsAsync.success),
          catchError((error) => of(fetchSpaceTagsAsync.failure(error))),
        ),
      ),
    );

