import React, {useContext, useEffect} from 'react';
import {Platform} from 'react-native';
import HomeView, {HomeViewName} from './routes/HomeView';
import LoginView, {LoginViewName} from './routes/LoginView';
import {
  AdminHomeView,
  AdminHomeViewName,
  AdminBookingsView,
  AdminBookingsViewName,
  AdminCalendarView,
  AdminCalendarViewName,
  AdminSettingsView,
  AdminSettingsViewName,
  AdminSpacesView,
  AdminSpacesViewName,
} from './routes/admin';
import BookingView, {BookingViewName} from './routes/BookingView';
import BookingSpaceView, {
  BookingSpaceViewName,
} from './routes/BookingSpaceView';
import ConditionsView, {ConditionsViewName} from './routes/ConditionsView';
import BookingDetailsView, {
  BookingDetailsViewName,
} from './routes/BookingDetailsView';
import BookingAssignSpacesView, {
  BookingAssignSpacesViewName,
} from './routes/booking/BookingAssignSpacesView';

import {connect} from 'react-redux';
import {RootState} from 'StoreTypes';
import {
  NavigationContainer,
  DefaultTheme,
  NavigationContainerRef,
} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import { logoutAsync } from '@features/login/actions';
import { AuthManager } from '@space/common';

import {ThemeUtil} from '@space/common';
import {ThemeContext} from 'styled-components/native';
import appConfig from './config';
import { localize } from './localization';
import {LocaleConfig} from 'react-native-calendars';
import { palette } from './features/preferences/style/themes';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import config from "./config";
import { useLocation } from 'react-router'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: config.gtmId
}

const Stack = createStackNavigator();

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.login.isAuthenticated,
  user: state.login.user
});

const dispatchProps = {
  logout: logoutAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const MainNavigationStack: React.FC<Props> = ({
  isAuthenticated,
  user,
  logout
}) => {
  const navigationRef = React.useRef<NavigationContainerRef>(null);
  const themeContext = useContext(ThemeContext);
  const authManager = AuthManager.getInstance();
  let location = useLocation();

  const configurePreferredLanguage = () => {
    if (isAuthenticated === true && user.preferredLanguage.name != Localization.locale.toLocaleLowerCase()) {
      i18n.locale = user.preferredLanguage.name.split('-')[0];
    }
  };

  useEffect(() => {
    if (user?.disabledAt || user?.deletedAt) {
      authManager.signOut().then(() => logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const NavigationTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: ThemeUtil.getThemeValue(palette.background)(themeContext.mode),
    },
  };

  const config = {
    screens: {
      LoginView: 'login',
      HomeView: 'home',
      CalendarView: 'calendar',
      BookingsView: 'bookings',
      SpacesView: 'spaces',
      SettingsView: 'settings',
      UsersView: 'users'
    },
  };

  const linking = {
    prefixes: [appConfig.url.base, 'nurunassistant://'],
    config,
  };

  configurePreferredLanguage();

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [location])
  

  return (
    <NavigationContainer
      linking={linking}
      theme={NavigationTheme}
      ref={navigationRef}>
      <Stack.Navigator
        screenOptions={{
          headerTitleAlign: 'center',
          headerStyle: {
            backgroundColor: '#621FF7',
          },
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
          headerShown: Platform.OS !== 'web',
        }}>
        {isAuthenticated === false ? (
          <Stack.Screen
            name={LoginViewName}
            component={LoginView}
            options={{title: localize('LOGIN')}}
          />
        ) : (
          <>
            <Stack.Screen
              name={HomeViewName}
              component={HomeView}
              options={{title: localize('HOME')}}
            />
            <Stack.Screen
              name={BookingViewName}
              component={BookingView}
              options={{title: localize('BOOKING')}}
            />
            <Stack.Screen
              name={BookingSpaceViewName}
              component={BookingSpaceView}
            />
            <Stack.Screen
              name={BookingAssignSpacesViewName}
              component={BookingAssignSpacesView}
            />
            <Stack.Screen
              name={ConditionsViewName}
              component={ConditionsView}
            />
            <Stack.Screen
              name={BookingDetailsViewName}
              component={BookingDetailsView}
            />
            <Stack.Screen
              name={AdminHomeViewName}
              component={AdminHomeView}
              options={{title: localize('ADMIN_MENU_HOME')}}
            />
            <Stack.Screen
              name={AdminCalendarViewName}
              component={AdminCalendarView}
              options={{title: localize('ADMIN_MENU_CALENDAR')}}
            />
            <Stack.Screen
              name={AdminBookingsViewName}
              component={AdminBookingsView}
              options={{title: localize('ADMIN_MENU_BOOKINGS')}}
            />
            <Stack.Screen
              name={AdminSpacesViewName}
              component={AdminSpacesView}
              options={{title: localize('ADMIN_MENU_SPACES')}}
            />
            <Stack.Screen
              name={AdminSettingsViewName}
              component={AdminSettingsView}
              options={{title: localize('ADMIN_MENU_SETTINGS')}}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default connect(mapStateToProps, dispatchProps)(MainNavigationStack);
