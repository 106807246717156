import React from 'react';
import { StyleSheet } from 'react-native';
import moment from 'moment';
import config from '../../config';
import styled from 'styled-components/native';
import { colors, palette } from '@features/preferences/style/themes';
import { StyledText } from '@space/common';
import { localize } from '../../localization';
import ForwardArrow from '../../img/forward_arrow.svg';
import BackArrow from '../../img/back_arrow.svg';
import { getDayText, capitalize } from "../../utils/dateutils";

type CalendarProps = {
  selectedDate: string;
  displayWeekView?: boolean;
  scrollOnRightClick?:() => void;
  scrollOnLeftClick?:() => void;
  scrollCount?: number;
  weeks: number;
};

const CalendarHeader: React.FC<CalendarProps> = ({ 
  selectedDate,
  displayWeekView,
  scrollOnRightClick,
  scrollOnLeftClick,
  scrollCount,
  weeks
}) => {

  return (
    <HeaderContainer displayWeekView={displayWeekView ?? false}>
      <TextContainer displayWeekView={displayWeekView ?? false}>
        <TodayTextContainer
          displayWeekView={displayWeekView ?? false}
          selected={selectedDate === moment().format(config.defaultDateFormat)}>
          <StyledText
            variant={displayWeekView ? 'body4' : 'caption'}
            fontWeight={displayWeekView ? '400' : '600'}
            fontSize={displayWeekView ? 16 : 14}
            letterSpacing={'0.01em'}
            fontColor={colors.black}>
            {getDayText(selectedDate)}
          </StyledText>
        </TodayTextContainer>
        <StyledText
          variant={'h3'}
          fontColor={colors.black}
          letterSpacing={'0.02em'}
          style={{textTransform: 'none'}}>
          {displayWeekView ? 
            capitalize(moment(selectedDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_DAY_FORMAT'))) :
            capitalize(moment(selectedDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_FULLDAY_FORMAT')))
          }
        </StyledText>
      </TextContainer>
      {displayWeekView && 
        <ArrowSvgContainer>
          <ArrowContent 
            style={[textStyles({}).leftArrowIcon, textStyles({}).disabled]}
            onPress={() => { scrollOnLeftClick()}}
            disabled={scrollCount === 0}
          >
            <BackArrow fill={palette.primary}/> 
          </ArrowContent>
          <ArrowContent 
            onPress={()=> { scrollOnRightClick()}}
            disabled={scrollCount === (weeks-1)}
          >
            <ForwardArrow fill={palette.primary}/>
          </ArrowContent>
        </ArrowSvgContainer>
      }
    </HeaderContainer>
  );
};

export default CalendarHeader;

type TextProps = {
  displayWeekView: boolean;
}

type DayTextProps = {
  selected: boolean;
  displayWeekView: boolean;
};

type HeaderProps = {
  displayWeekView: boolean;
};

type ArrowsProps = {
  disabled: boolean;
}

const HeaderContainer = styled.View<HeaderProps>`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 16px;
`;

const TextContainer = styled.View<TextProps>`
  flex-direction: ${(props) => (props.displayWeekView ? 'column' : 'row-reverse')};
  align-items: ${(props) => (props.displayWeekView ? '' : 'center')};
`;

const TodayTextContainer = styled.View<DayTextProps>`
  border-radius: ${(props) => (props.displayWeekView ? '0' : '8px')};
  background-color: ${(props) => (props.displayWeekView ? 'none' : palette.disable)};
  padding: ${(props) => (props.displayWeekView ? '0 0 5px 0' : '6px 8px')};
  margin-left: ${(props) => (props.displayWeekView ? '0' : '8px')};
  visibility: ${(props) => (!props.displayWeekView && !props.selected ? 'hidden' : 'visible')};
`;

const ArrowSvgContainer = styled.View`
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
`;

const ArrowContent = styled.TouchableOpacity<ArrowsProps>`
  fill: ${(props) => (props.disabled ? palette.disable : colors.black)};
`;

const textStyles = (props) =>
  StyleSheet.create({
    dayText: {
      paddingBottom: 1,
      paddingTop: 2,
      textAlign: 'center',
      width: 32,
      height: 18,
    },
    leftArrowIcon: {
      marginRight: 40,
    }
  });
