import React, { useContext, useState } from 'react';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import { SectionList, FlatList, StyleSheet, Platform, Dimensions } from 'react-native';
import {
  selectBooking,
  createBookingAsync,
  updateBookingAsync,
  setSelectedSpacesForBooking,
  clearError,
} from '@features/booking/actions';
import { hideModalView } from '@features/modal/actions';
import { ConditionsViewName } from './ConditionsView';
import ModalHeader from '@components/modal/ModalHeader';
import SpaceHelper from '../utils/SpaceHelper';
import moment from 'moment';
import { colors, palette } from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import { SpaceModel } from '@features/space';
import _ from 'lodash';
import { Space, SpaceGroup, SpaceTag } from 'SpaceFeature';
import CheckBox from '@components/CheckBox';
import { Button, Divider, ModalView, StyledText, Dialog } from '@space/common';
import CalendarHeader from '@components/Calendar/CalendarHeader';
import SpaceListItem from '@components/bookings/SpaceListItem';
import { useNavigation } from '@react-navigation/native';
import { BookingRequestBody } from 'BookingFeature';
import { HomeViewName } from './HomeView';
import { BookingAssignSpacesViewName } from './booking/BookingAssignSpacesView';
import BookingDetailsView from './BookingDetailsView';
import { updateDateWithOfficeHours } from '../utils';
import { localize, localizedName } from '../localization';
import 'moment-timezone';
import TagManager from 'react-gtm-module'
import { event } from '../utils/analysticsConstants'
import { ScrollView } from 'react-native-gesture-handler';

const mapStateToProps = (state: RootState) => ({
  organization: state.organization.organization,
  booking: state.booking.currentUserBookings,
  spaceList: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  spaceTags: state.space.spaceTags,
  spaceGroups: state.space.spaceGroups,
  selectedBooking: state.booking.selectedBooking,
  selectedDate: state.booking.selectedDate,
  selectedSpaceType: state.booking.selectedSpaceType,
  selectedSpaces: state.booking.selectedSpacesForBooking,
  user: state.login.user,
  createBookingError: state.booking.bookingError,
  lastBookingCreated: state.booking.userLastBooking,
  activeLanguages: state.organization.languages,
  office: state.organization.office,
  selected: state.booking.selectedBooking,
});

const dispatchProps = {
  hideModalView: hideModalView,
  selectSpaces: setSelectedSpacesForBooking,
  createBooking: createBookingAsync.request,
  updateBooking: updateBookingAsync.request,
  handleCloseErrorDialog: clearError,
  selectBooking: selectBooking,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const BookingSpaceView: React.FC<Props> = ({
  isReservationViewDisplayed,
  spaceList,
  availableSpacesCache,
  spaceGroups,
  spaceTags,
  selectedBooking,
  selectBooking,
  selectedDate,
  selectedSpaceType,
  selectedSpaces,
  selectSpaces,
  user,
  createBookingError,
  lastBookingCreated,
  route,
  handleCloseErrorDialog,
  organization,
  activeLanguages,
  office,
  selected
}) => {
  React.useEffect(() => {
    if (route.params?.term) {
    selectBooking(booking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params?.term]);

  const [booking, setBooking] = useState<BookingRequestBody>();
  const navigation = useNavigation();

  const setBookingPress = (): void => { 
    navigation.navigate(ConditionsViewName, {
      redirectViewName: BookingSpaceViewName,
    });
  }

  const handleSingleItemPress = (item: Space) => {
    const date = updateDateWithOfficeHours(
      office,
      selectedDate,
    );
    setBooking({
      id: selectedBooking ? selectedBooking.id : null,
      title: 'Booking ' + localizedName(item.name, activeLanguages),
      description: '',
      typeId: selectedSpaceType.id,
      organizer: user,
      users: [user],
      spaces: [item],
      spaceUserMapping: [{ userId: user.id, spaceId: item.id }],
      schedule: {
        startDate: date.startDate,
        endDate: date.endDate,
        rrule: '',
      },
    });
    setBookingPress();
  };

  const renderSpaceGroupSectionHeader = (section: any) => {
    const groupDetail = _.first(
      spaceGroups.filter((item: SpaceGroup) => item.id === section.section.title),
    ) as SpaceGroup;

    return (
      <StyledHeaderContainer>
        <StyledHeaderText>
          <StyledText variant={'h5'} color={'primary'}>
            {groupDetail.name}
          </StyledText>
          <StyledText variant={'body4'} color={'tertiary'}>
            {localize('BOOKING_SPACE_GROUP_HEADER',
              { available: section.section.data.length, of: groupDetail.spaces.length }
            )}
          </StyledText>
        </StyledHeaderText>
        <Divider />
        {createBookingError && (
          <Dialog
            isOpen={createBookingError !== null}
            title={localize('APP_ERROR_TITLE')}
            body={createBookingError}
            onClose={handleCloseErrorDialog}
            closeIcon={true}
          />
        )}
      </StyledHeaderContainer>
    );
  };

  const renderSpaceGroupSectionFooter = (section: any) => {
    return <StyledFooterContainer />;
  };

  const renderSpaceGroupItem = ({ item }) => {
    const tags = spaceTags.filter((tag: SpaceTag) => item.tagIdsList.includes(tag.id)).map((tag: SpaceTag) => tag.name).join(', ');

    const onPress = () => {
      if (selectedSpaces.includes(item)) {
        const leftSpaces = selectedSpaces.filter(
          (space: Space) => space.id !== item.id,
        );
        selectSpaces(leftSpaces);
      } else {
        const newSpaces = [item].concat(selectedSpaces);
        selectSpaces(newSpaces);
      }
    };

    return (
      <StyledItemContainer>
        <StyledTouchableOpacity onPress={onPress}>
          <LeftContainer>
            <StyledText variant={'h5'} color={'primary'}>
              {item.name}
            </StyledText>
            <StyledText variant={'body4'} color={'tertiary'}>
              {tags}
            </StyledText>
          </LeftContainer>
          <RightContainer>
            <CheckBox
              ticked={selectedSpaces.includes(item)}
              onPress={onPress}
            />
          </RightContainer>
        </StyledTouchableOpacity>
      </StyledItemContainer>
    );
  };

  const renderSingleSpaceList = () => {
    const availableSpaces = SpaceHelper.availableSpacesForType(
      spaceList,
      availableSpacesCache,
      moment(selectedDate),
      selectedSpaceType,
      user
    ).sort((a, b) => localizedName(a.name, activeLanguages).localeCompare(localizedName(b.name, activeLanguages), undefined, {
      numeric: true,
      sensitivity: 'base'
    }))

    return (
      <SpaceList>
        <ScrollView>
          {availableSpaces.map((item, index) => (
            <SpaceListItem 
            plan={SpaceHelper.getMapImageUrl(item, activeLanguages)} 
            space={item} spaceTags={spaceTags} 
            booking={booking} 
            onPress={() => {
              handleSingleItemPress(item);
              TagManager.dataLayer({
                dataLayer: {
                  event: event.GA4SelectSpace,
                  current_page: title,
                  reservation_type: `${selected ? 'Edit' : 'Made'} a reservation`,
                  button_label: localize('NEXT_STEP_GA'),
                }
              })
            }} />
          ))}
        </ScrollView>
      </SpaceList>
    );
  };

  const renderSpaceGroupsSectionList = () => {
    const groups = SpaceHelper.availableSpaceGroupsForType(
      spaceList,
      spaceGroups,
      moment(selectedDate),
      selectedSpaceType,
    ).map((group) => {
      return { title: group.id, data: group.spaces };
    });

    return (
      <StyledSectionList>
        <SectionList
          sections={groups}
          renderItem={renderSpaceGroupItem}
          renderSectionHeader={renderSpaceGroupSectionHeader}
          renderSectionFooter={renderSpaceGroupSectionFooter}
          keyExtractor={(item, index) => item.id + index}
        />
      </StyledSectionList>
    );
  };

  const reNamedSelectedType = selectedSpaceType?.name.map((selectedType) => {
    const name = selectedType.name;
    const newName = name.substring(0, name.indexOf("("));
    return {...selectedType, name: newName}
  })

  const title = localize('BOOKING_SPACE_SELECTION_TITLE',
    { 
      spaces: localizedName(reNamedSelectedType, activeLanguages).toLowerCase(),
      article: localizedName(reNamedSelectedType, activeLanguages) === 'Salle' ?
      localize('ARTICLE_F') : localize('ARTICLE_M')
    });

  return (
    <StyledBackground>
      <HeaderContainer>
        <ModalHeader
          leftAction={'back'}
          title={title}
          onPress={() => {
            selectSpaces([]);
            navigation.goBack();
          }}
        />
      </HeaderContainer>
      <StyledContent>
        <CalendarHeaderContainer>
          <CalendarHeader displayWeekView={false} selectedDate={selectedDate}/>
        </CalendarHeaderContainer>

        {selectedSpaceType.model === SpaceModel.POD
          ? renderSpaceGroupsSectionList()
          : renderSingleSpaceList()}

        {selectedSpaceType.model === SpaceModel.POD && !_.isEmpty(selectedSpaces) ? (
          <StyledFooter>
            <Button
              fontColor={colors.grey5}
              disabled={_.isEmpty(selectedSpaces)}
              title={localize('BOOKING_SPACE_ASSIGN_SPACE')}
              onPress={() => {
                navigation.navigate(BookingAssignSpacesViewName);
              }}
            />
          </StyledFooter>
        ) : null}
      </StyledContent>
      <ModalView
        isVisible={lastBookingCreated != null && isReservationViewDisplayed}>
        <BookingDetailsView
          onClosePress={() => {
            navigation.navigate(HomeViewName);
          }}
        />
      </ModalView>
    </StyledBackground>
  );
};

const SpaceList = styled.View`
  width: 100%;
`;

const CalendarHeaderContainer = styled.View`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

const StyledBackground = styled.View<any>`
  background-color: ${palette.background};
  width: ${Platform.OS === 'web' ? '100vw' : `${Dimensions.get('screen').width}px`};
  height: ${Platform.OS === 'web' ? '100vh' : `${Dimensions.get('screen').height}px`};
  align-items: center;
  overflow: hidden;
`;

const HeaderContainer = styled.View`
  width: 100%;
  justify-content: flex-start;
`;

const StyledSectionList = styled.View`
  width: 100%;
  margin-bottom: 16px;
  padding: 0px 25px;
`;

const StyledItemContainer = styled.View`
  width: 100%;
  height: 70px;
  padding: 8px 16px;
  align-items: flex-start;
  justify-content: center;
  border-right-color: ${palette.border};
  border-right-style: solid;
  border-right-width: 1px;
  border-left-color: ${palette.border};
  border-left-style: solid;
  border-left-width: 1px;
`;

const StyledTouchableOpacity = styled.TouchableOpacity`
  width: 100%;
`;

const StyledHeaderContainer = styled.View`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-right-color: ${palette.border};
  border-right-style: solid;
  border-right-width: 1px;
  border-left-color: ${palette.border};
  border-left-style: solid;
  border-left-width: 1px;
  border-top-color: ${palette.border};
  border-top-style: solid;
  border-top-width: 1px;
`;

const StyledHeaderText = styled.View`
  width: 100%;
  align-items: flex-start;
  padding: 8px 16px;
  justify-content: center;
`;

const StyledFooterContainer = styled.View`
  width: 100%;
  height: 5px;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 16px;
  border-right-color: ${palette.border};
  border-right-style: solid;
  border-right-width: 1px;
  border-left-color: ${palette.border};
  border-left-style: solid;
  border-left-width: 1px;
  border-bottom-color: ${palette.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const StyledContent = styled.ScrollView`
  width: 100%;
`;

const LeftContainer = styled.View`
  padding-right: 30px;
`;

const RightContainer = styled.View`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const StyledFooter = styled.View`
  width: 100%;
  flex: 1 1 auto;
  justify-content: center;
  align-items: stretch;
  position: sticky;
  bottom: 0;
  background: white;
  margin: 0 auto;
  padding: 1rem;
  border-top-color: ${palette.border};
  border-top-style: solid;
  border-top-width: 1px;
`;

const Styles = StyleSheet.create({
  Button: {
      flex: 1,
      marginTop: '40px',
      marginRight: '24px',
      marginLeft: '24px',
  },
});

export const BookingSpaceViewName = 'BookingSpaceView';

export default connect(mapStateToProps, dispatchProps)(BookingSpaceView);
