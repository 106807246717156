import React from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import AvatarList from '@components/AvatarList';
import { StyledText, ThemeUtil, Divider } from '@space/common';
import { showUsersListView } from '@features/home/actions';
import styled, { useTheme } from 'styled-components/native';
import Close from '../../img/close.svg';
import { User } from 'UserFeature';
import { localize } from '../../localization';

const mapStateToProps = (state: RootStateOrAny) => ({
  selectedDate: state.booking.selectedDate,
});

const dispatchProps = {
  showUsersList: showUsersListView,
};

type UserAvailabilityListViewProps = {
  users: User[];
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  UserAvailabilityListViewProps;

const UserAvailabilityListView: React.FC<Props> = ({
  users,
  selectedDate,
  showUsersList,
}) => {
  const theme = useTheme();
  const styleName = theme.mode;

  return (
    <StyledContainer>
      <StyledHeader>
        <TitleContainer>
          <StyledText variant={'h3'} fontColor={theme.color.grey4}>
            {localize('CARROUSEL_TITLE')}
          </StyledText>
        </TitleContainer>
        <ActionButton onPress={() => showUsersList(false)}>
          <Close
            width={24}
            height={24}
            fill={ThemeUtil.getInvertedThemeValue(theme.palette.common)(styleName)}
          />
        </ActionButton>
      </StyledHeader>
      <Divider style={{background: '#e0e0e0', opacity: 1}}/>
      <AvatarList list={users} selectedDate={selectedDate}/>
    </StyledContainer>
  );
};

const StyledContainer = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0 16px;
`;

const StyledHeader = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 25px;
  margin-bottom: 16px;
`;

const TitleContainer = styled.TouchableOpacity`
  width: 240px;
  margin-left: 8px;
`;

const ActionButton = styled.TouchableOpacity`
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
`;

export default connect(
  mapStateToProps,
  dispatchProps,
)(UserAvailabilityListView);
