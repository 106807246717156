import React from 'react';
import {colors, palette} from '@features/preferences/style/themes';
import {StyledText, ThemeUtil} from '@space/common';
import styled, {useTheme} from 'styled-components/native';
import theme from 'styled-theming';
import { localize } from '../../localization';

type SpaceStatsProps = {
  availability: number;
  totalAvailableSpaces: number;
};

type StyleProps = {
  availability: number;
  styleName: string;
};

const fontColor = theme('mode', {
  light: colors.white,
  dark: colors.grey5,
});

const backgroundColor = theme('mode', {
  light: colors.grey5,
  dark: colors.white,
});

const renderNoAvailability = (styleName: string) => (
  <StyledContent>
    <StyledText
      variant={'caption'}
      fontWeight={'600'}
      fontColor={ThemeUtil.getThemeValue(fontColor)(styleName)}>
      {localize('NA')}
    </StyledText>
  </StyledContent>
);

const renderAvailability = (availability: number, styleName: string, totalAvailableSpaces: number) => (
  <StyledContent>
    <StyledText
      variant={'caption'}
      fontWeight={'600'}
      fontColor={ThemeUtil.getThemeValue(fontColor)(styleName)}>
      {`${availability}/${totalAvailableSpaces}`}
    </StyledText>
  </StyledContent>
);

/**
 * A small profile card
 */
const SpaceAvailabilityItem: React.FC<SpaceStatsProps> = ({availability, totalAvailableSpaces}) => {
  const styleName = useTheme().mode;
  const display =
    availability > 0
      ? renderAvailability(availability, styleName, totalAvailableSpaces)
      : renderNoAvailability(styleName);
  return (
    <StyledContainer availability={availability} styleName={styleName}>
      {display}
    </StyledContainer>
  );
};

const StyledContainer = styled.View<StyleProps>`
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${colors.black};
`;

const StyledContent = styled.View`
  align-self: center;
`;

export default SpaceAvailabilityItem;
