import { RootEpic } from 'StoreTypes';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { defer } from 'rxjs';
import { 
  loadUsersAsync,
  updateUserAsync,
  updateUserNoImageAsync,
} from './actions';
import { User } from 'UserFeature';
import { localize } from '../../localization';

export const loadUsersEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf([loadUsersAsync.request])),
    switchMap((_action) =>
      defer(() => from(api.assistant.fetchUsers())).pipe(
        map((data) => data as User[]),
        map(loadUsersAsync.success),
        catchError((error) => of(loadUsersAsync.failure(error.message))),
      ),
    ),
  );

export const updateUserEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateUserAsync.request)),
    switchMap((action) => {
      return from(
        api.assistant.updateUser(state$.value.login.user.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateUserAsync.success(updateResponse);
        }),
        catchError((err) => { 
          let errorMessage;
          switch (err.statusCode) {
            case 413:
              errorMessage = localize('IMAGE_LIMIT');
              break;
            default:
              errorMessage = localize('ERROR_DEFAULT');
          }
          return of(updateUserAsync.failure(errorMessage)); 
        } ),
      );
    }),
  );

  export const updateUserNoImageEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(updateUserNoImageAsync.request)),
    switchMap((action) => {
      return from(
        api.assistant.updateUserNoImage(state$.value.login.user.id, action.payload)
      ).pipe(
        map((updateResponse) => {
          return updateUserNoImageAsync.success(updateResponse);
        }),
        catchError((err) => of(updateUserNoImageAsync.failure(err))),
      );
    }),
  );