import React, { useMemo } from 'react';
import { View } from 'react-native';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import SpaceStats from './SpaceStats';
import { StyledText } from '@space/common';
import { SpaceHelper } from '../../utils/SpaceHelper';
import styled from 'styled-components/native';
import { SpaceModel } from '@features/space';
import { localize } from '../../localization';
import _ from 'lodash';

const mapStateToProps = (state: RootState) => ({
  spacesList: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  spaceGroups: state.space.spaceGroups,
  selectedDate: state.booking.selectedDate,
  office: state.organization.office,
  officeOpenCache: state.organization.officeOpenCache,
});

type Props = ReturnType<typeof mapStateToProps>;

const SpaceStatsView: React.FC<Props> = ({
  spacesList,
  availableSpacesCache,
  spaceGroups,
  selectedDate,
  office,
  officeOpenCache,
}) => {

  // Stats
  const seat = SpaceHelper.cacheForOccupancy(selectedDate, availableSpacesCache, SpaceModel.SEAT);

  const room = SpaceHelper.cacheForOccupancy(selectedDate, availableSpacesCache, SpaceModel.ROOM);

  const pod = SpaceHelper.cacheForOccupancy(selectedDate, availableSpacesCache, SpaceModel.POD);

  const officeOpen = useMemo(() => {
    return SpaceHelper.isOfficeOpened(selectedDate, officeOpenCache, spacesList, availableSpacesCache);
  }, [officeOpenCache, spacesList, selectedDate]);

  const seatAvailability = useMemo(() => {
    return !_.isEmpty(officeOpen) ? seat?.availability : 0;
  }, [officeOpen, availableSpacesCache]);

  const seatTotalAvailableSpaces = useMemo(() => {
    const total = (seat?.availability != undefined && seat?.booked != undefined) ? seat.availability + seat.booked : 0;
    return !_.isEmpty(officeOpen) ? total : 0;
  }, [officeOpen, availableSpacesCache]);

  const podAvailability = useMemo(() => {
    return !_.isEmpty(officeOpen) ? pod?.availability : 0;
  }, [officeOpen, availableSpacesCache]);

  const podTotalAvailableSpaces = useMemo(() => {
    return !_.isEmpty(officeOpen) ? pod?.availability + pod?.booked : 0;
  }, [officeOpen, availableSpacesCache]);

  const roomAvailability = useMemo(() => {
    return !_.isEmpty(officeOpen) ? room?.availability : 0;
  }, [officeOpenCache, availableSpacesCache]);

  const roomTotalAvailableSpaces = useMemo(() => {
    const total = (room?.availability != undefined && room?.booked != undefined) ? room?.availability + room?.booked : 0;
    return !_.isEmpty(officeOpen) ? total : 0;
  }, [officeOpenCache, availableSpacesCache]);


  return (
    <RootContainer>
      <HeaderContainer>
        <StyledText variant={'h3'} color={'secondary'} letterSpacing={'0.01em'}>
          {localize('AVAILABILITIES')}
        </StyledText>
      </HeaderContainer>
      <Container>
        <SpaceStats
          size={'big'}
          percent={seat?.occupancy ?? 0}
          availability={seatAvailability}
          totalAvailableSpaces={seatTotalAvailableSpaces}
          spaceName={localize('SEATS')}
          icon={'img/seat.svg'}
        />
        <RightContainer>
            <>
              <SpaceStats
                size={'small'}
                percent={pod?.occupancy ?? 0}
                availability={podAvailability}
                totalAvailableSpaces={podTotalAvailableSpaces}
                spaceName={localize('PODS')}
                icon={'img/pod.svg'}
              />
              <View style={{ height: 24 }} />
            </>
          <SpaceStats
            size={'small'}
            percent={room?.occupancy ?? 0}
            availability={roomAvailability}
            totalAvailableSpaces={roomTotalAvailableSpaces}
            spaceName={localize('ROOMS')}
            icon={'img/room.svg'}
          />
        </RightContainer>
      </Container>
    </RootContainer>
  );
};

const HeaderContainer = styled.View`
  margin: 24px 0 0 16px; 
`;

const RootContainer = styled.View`
  width: 100%;
  justify-content: center;
  align-content: center;
`;

const Container = styled.View`
  width: 100%;
  flex-direction: row;
  padding: 24px 0 32px 24px;
  align-content: center;
  justify-content: space-between;
`;

const RightContainer = styled.View`
  height: 100%;
  flex-direction: column;
  padding: 0 29px 0 0;
  justify-content: space-between;
`;

export default connect(mapStateToProps)(SpaceStatsView);
