import React, {useEffect, useState} from 'react';
import {RootState} from 'StoreTypes';
import {connect, useDispatch} from 'react-redux';
import {ActivityIndicator} from 'react-native';
import _ from 'lodash';

import {setConditions} from '@features/conditions/actions';
import styled from 'styled-components/native';
import { Divider, StyledText } from '@space/common';
import {palette, colors, shadow} from '@features/preferences/style/themes';
import ModalHeader from '@components/modal/ModalHeader';
import {BookingDetailsViewName} from './BookingDetailsView';
import {BookingSpaceViewName} from './BookingSpaceView';
import { localize } from '../localization';
import Close from '../img/close.svg';
import Check from '../img/checked.svg';
import config from '../config';
import { handleForwardStep, handleBackwardStep } from '@features/booking/actions';
import TagManager from 'react-gtm-module'
import { event } from '../utils/analysticsConstants'

const mapStateToProps = (state: RootState) => ({
  conditions: state.conditions.conditions,
  stepCounter: state.booking.stepCounter,
  selected: state.booking.selectedBooking,
});

const dispatchProps = {
  setConditions: setConditions,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const getButton = (text: string, type: string, onPress?: () => void) => {
  return (
    <ButtonContainer onPress={onPress} type={type}>
        <StyledText 
          variant={'button'}
          fontColor={type === 'YES' ? colors.white : palette.primary}>
          {text}
        </StyledText>
    </ButtonContainer>
  );
};

const ConditionsView: React.FC<Props> = ({
  setConditions,
  conditions,
  navigation,
  route,
  stepCounter,
  selected
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(stepCounter === 4 ? 1 : 0);

  const navigateBackToName =
    route.params?.redirectViewName ?? BookingSpaceViewName;

  const fetchConditions = () => {

    const bookingSteps = ['BOOKING_CONDITION_2', 'BOOKING_CONDITION_3', 'BOOKING_CONDITION_4']

    const content: any[] = [
      {
        title: 'SANITARY_RULES_TITLE',
        subtitle: 'CONFIRM_SUBTITLE',
        steps: ['SANITARY_RULE_1', 'SANITARY_RULE_2', 'SANITARY_RULE_3'],
      },
      //In case of COVID-19 getting worse then we have to bring it back to website
      // {
      //   title: 'BOOKING_CONDITION_TITLE',
      //   subtitle: 'ACCEPT_SUBTITLE',
      //   steps: bookingSteps,
      // },
    ];

    setConditions(content);
  };

  const conditionsItem = (texts: string[]) => {
    return texts.map((text) => {
      return (
        <ConditionsItemContainer key={text}>
          <BulletPoint>
            <StyledText variant={'body2'}>{'•'}</StyledText>
          </BulletPoint>
          <StyledText variant={'body1'} lineHeight={'20px'}>{localize([text])}</StyledText>
        </ConditionsItemContainer>
      );
    });
  };

  useEffect(() => {
    fetchConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (_.isEmpty(conditions)) {
    return <ActivityIndicator />;
  } else {
    const footer = step < conditions.length;
    return (
      <Container>
        <HeaderContainer>
          <ModalHeader
            leftAction={'back'}
            title={localize([conditions[step].title])}
            onPress={() => {
              if (step > 0) {
                setStep(step - 1);
              } else {
                navigation?.navigate(navigateBackToName, {term: false});
              }
            }}
          />
        </HeaderContainer>
        <Divider />
        <ContentContainer>
          <TextContainer>
            <ConditionsTitleContainer>
              <StyledText variant={'h3'} lineHeight={'100%'} letterSpacing={'0.01em'}>
                {localize([conditions[step].subtitle])}
              </StyledText>
            </ConditionsTitleContainer>
            <Divider variant={'fullWidth'}/>
            <ConditionContainer>
              {conditionsItem(conditions[step].steps)}
            </ConditionContainer>
          </TextContainer>
          <BottomContentContainer>
            {footer
              ? getButton(localize('NO'), 'NO', () => {
                if (step > 0) {
                  setStep(step - 1);
                  dispatch(handleBackwardStep());
                  TagManager.dataLayer({ 
                    dataLayer: {
                      event: `${event.GA4Button}`,
                      current_page: `${event.GA4Button}`,
                      button_title: `${step == 2 ? localize('SANITARY_RULES_TITLE') : localize('BOOKING_CONDITION_TITLE') }`, 
                      button_label: `${localize('NO')}`,
                      step: `${step+2}`,
                      reservation_type: `${selected ? 'Edit' : 'Made'} a reservation`
                    }
                  })
                } else {
                  navigation?.navigate(navigateBackToName, {term: false});
                  dispatch(handleBackwardStep());
                  TagManager.dataLayer({
                    dataLayer: {
                      event: `${event.GA4Button}`,
                      current_page: `${event.GA4Button}`,
                      button_title: `${step == 2 ? localize('SANITARY_RULES_TITLE') : localize('BOOKING_CONDITION_TITLE') }`,
                      button_label: `${localize('NO')}`,
                      step: '3',
                      reservation_type: `${selected ? 'Edit' : 'Made'} a reservation`
                    }
                  })
                }})
              : null}
            {footer
              ? getButton(localize('YES'), 'YES', () => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: `${event.GA4Button}`,
                      current_page: `${event.GA4Button}`,
                      button_title: `${step == 2 ? localize('SANITARY_RULES_TITLE') : localize('BOOKING_CONDITION_TITLE') }`,
                      button_label: `${localize('YES')}`,
                      step: `${step+1}`,
                      reservation_type: `${selected ? 'Edit' : 'Made'} a reservation`
                    }
                  });          
                  if (step + 1 < conditions.length) {
                    setStep(step + 1);
                    dispatch(handleForwardStep());
                  } else {
                    navigation?.navigate(navigateBackToName, {term: true});
                    navigation.navigate(BookingDetailsViewName);
                    dispatch(handleForwardStep());
                  }
                })
              : null}
          </BottomContentContainer>
        </ContentContainer>
      </Container>
    );
  }
};

type ButtonProps = {
  type: "YES" | "NO";
};

const Container = styled.View`
  background-color: ${palette.background};
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 0px 0px 48px 0px;
`;

const HeaderContainer = styled.View`
  width: 100%;
  justify-content: flex-start;
`;

const ContentContainer = styled.View`
  max-width: 343px;
  width: 100%;
  box-shadow: ${shadow};
  border-radius: 16px;
  background-color: ${colors.white};
  margin-top: 24px;
`;

const TextContainer = styled.View`
  width: 100%;
  padding: 24px 16px 48px 16px;
`;

const ConditionsTitleContainer = styled.View`
  flex-direction: row;
  height: 24px;
  align-items: center;
  margin: 0px 0px 24px 0px;
`;

const ConditionContainer = styled.View`
  margin: 24px 0 0 6px;
`;

const ConditionsItemContainer = styled.View`
  flex-direction: row;
  margin: 0px 0px 16px 0px;
`;

const BulletPoint = styled.View`
  width: 24px;
`;

const BottomContentContainer = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 16px;
`;

const ButtonContainer = styled.TouchableOpacity<ButtonProps>`
  max-width: 148px;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: ${({type}) => (type === 'YES' ? palette.primary : colors.white)};;
  border-color: ${({type}) => (type === 'YES' ? '' : palette.primary)};
  border-width: ${({type}) => (type === 'YES' ? '0px' : '1px')};
  border-radius: 32px;
`;

export const ConditionsViewName = 'ConditionsView';

export default connect(mapStateToProps, dispatchProps)(ConditionsView);
