import {createAction, createAsyncAction} from 'typesafe-actions';
import {User, UpdateUserQuery, UpdateUserNoImageQuery} from 'UserFeature';

export const loadUsersAsync = createAsyncAction(
  'LOAD_USERS_REQUEST',
  'LOAD_USERS_SUCCESS',
  'LOAD_USERS_FAILURE',
)<void, User[], string>();

export const setUserSearchTextForMapping = createAction(
  'SET_USER_SEARCH_TEXT',
  (value: string) => value,
)();

export const setSpaceIdSelectedForMapping = createAction(
  'SET_SPACE_ID_SELECTED_FOR_MAPPING',
  (value: string) => value,
)();

export const updateUserAsync = createAsyncAction(
  'UPDATE_USER_REQUEST',
  'UPDATE_USER_SUCCESS',
  'UPDATE_USER_FAILURE',
)<UpdateUserQuery, User, string>();

export const updateUserNoImageAsync = createAsyncAction(
  'UPDATE_USER_NOIMAGE_REQUEST',
  'UPDATE_USER_NOIMAGE_SUCCESS',
  'UPDATE_USER_NO_IMAGE_FAILURE',
)<UpdateUserNoImageQuery, User, string>();


export const clearError = createAction('CLEAR_ERROR', () => { })();