import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { StyledText, ThemeUtil } from '@space/common';
import SpaceAvailabilityItem from './SpaceAvailabilityItem';
import Pod from '../../img/pod.svg';
import Seat from '../../img/seat.svg';
import Room from '../../img/room.svg';
import styled, { useTheme } from 'styled-components/native';
import _ from 'lodash';
import theme from 'styled-theming';
import { colors, palette } from '@features/preferences/style/themes';
import { DefaultTheme } from 'styled-components/native';
import { localize } from '../../localization';

type SpaceStatsProps = {
  size: string;
  spaceName: string;
  icon: string;
  availability: number;
  totalAvailableSpaces: number;
  percent: number;
};

const renderIcon = (src: string, color: any) => {
  const bigStatsProps = {
    fill: color,
    width: 40,
    height: 40,
  }

  const otherProps = {
    fill: color,
    width: 24,
    height: 24,
  };

  const iconName = _.head(src.split('/').pop()?.split('.'));

  switch (iconName) {
    case 'pod':
      return <Pod {...otherProps}/>;
    case 'seat':
      return <Seat {...bigStatsProps} />;
    case 'room':
      return <Room {...otherProps} />;
    default:
      return (
        <Image
          style={styles.image}
          source={{
            uri: src ?? 'img/logo.svg',
          }}
          accessibilityLabel={'TODO'}
        />
      );
  }
};

const renderNoAvailability = () => (
  <AvailabilityContainer>
    <StyledText variant={'caption'} fontSize={14} fontWeight={'600'} fontColor={colors.white}>
      {localize("NA")}
    </StyledText>
  </AvailabilityContainer>
);

const renderAvailability = (availability: number, totalAvailableSpaces: number) => (
  <AvailabilityContainer>
    <StyledText variant={'caption'} fontSize={14} fontWeight={'600'} fontColor={colors.white}>
      {`${availability} / ${totalAvailableSpaces}`}
    </StyledText>
  </AvailabilityContainer>
);

const pathColorFromPercent = (percent: number, mode: any) => {
  if (percent == 100) {
    return ThemeUtil.getThemeValue(palette.error)(mode);
  } else if (percent >= 75) {
    return ThemeUtil.getThemeValue(palette.warning)(mode);
  } else {
    return ThemeUtil.getThemeValue(palette.primary)(mode);
  }
};

const background = theme('mode', {
  light: colors.white,
  dark: colors.black,
});

const renderBigStats = (
  spaceName: string,
  icon: string,
  availability: number,
  totalAvailableSpaces: number,
  percent: number,
  theme: DefaultTheme,
) => {
  const mode = theme.mode;

  return (
    <>
      <CircularProgressbarWithChildren
        value={percent}
        background
        styles={buildStyles({
          strokeLinecap: 'round',
          backgroundColor: ThemeUtil.getThemeValue(background)(mode),
          pathColor: pathColorFromPercent(percent, mode),
          trailColor: ThemeUtil.getThemeValue(palette.disable)(mode),
        })}>
        <StyledContent>
          {renderIcon(icon, availability > 0 ? 
            ThemeUtil.getInvertedThemeValue(theme.palette.common)(mode) :
            ThemeUtil.getThemeValue(theme.palette.disable)(mode))
          }
          {availability > 0
            ? renderAvailability(availability, totalAvailableSpaces)
            : renderNoAvailability()}
          
        </StyledContent>
      </CircularProgressbarWithChildren>
      <TextContainer>
        <StyledText variant={'caption'} color={'primary'} fontWeight={'600'} lineHeight={'18px'}>
          {spaceName}
        </StyledText>
      </TextContainer>
    </>
  );
}

const renderSmallStats = (
  spaceName: string,
  icon: string,
  availability: number,
  totalAvailableSpaces: number,
  percent: number,
  theme: DefaultTheme,
) => {
  const mode = theme.mode;
  
  return (
    <>
      <CircularProgressbarWithChildren
        value={percent}
        background
        styles={buildStyles({
          strokeLinecap: 'round',
          backgroundColor: ThemeUtil.getThemeValue(background)(mode),
          pathColor: pathColorFromPercent(percent, mode),
          trailColor: ThemeUtil.getThemeValue(palette.disable)(mode),
        })}>
        <StyledContent>
          {renderIcon(icon, availability > 0 ? 
            ThemeUtil.getInvertedThemeValue(theme.palette.common)(mode) :
            ThemeUtil.getThemeValue(palette.disable)(mode))
          }
          <Badge>
            <SpaceAvailabilityItem availability={availability} totalAvailableSpaces={totalAvailableSpaces}/>
          </Badge>
        </StyledContent>
      </CircularProgressbarWithChildren>
      <TextContainer>
          <StyledText variant={'caption'} color={'primary'} fontWeight={'600'} lineHeight={'18px'}>
            {spaceName}
          </StyledText>
        </TextContainer>
      </>
  );
}

const statsSize = (size: string) => {
  switch (size) {
    case 'small':
      return 72;
    case 'big':
    default:
      return 192;
  }
};

/**
 * A small profile card
 */
const SpaceStats: React.FC<SpaceStatsProps> = ({
  size = 'big',
  spaceName,
  icon,
  availability,
  totalAvailableSpaces,
  percent,
}) => {
  const theme = useTheme();

  const viewSize = statsSize(size);
  const stats =
    size === 'small'
      ? renderSmallStats(spaceName, icon, availability, totalAvailableSpaces, percent, theme)
      : renderBigStats(spaceName, icon, availability, totalAvailableSpaces, percent, theme);

  return (
    <View
      style={[
        {
          width: viewSize,
          height: viewSize,
        },
      ]}>
      {stats}
    </View>
  );
};

const StyledContent = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
`;

const AvailabilityContainer = styled.View`
  max-width: 72px;
  width: 100%;
  height: 32px;
  border: 1px solid ${colors.black};
  background: ${colors.black};
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

const TextContainer = styled.View`
  margin-top: 8px;
  align-items: center;
  width: 100%;
`;

const styles = StyleSheet.create({
  image: {
    width: 13,
    height: 13,
  },
  bigImage: {
    width: 48,
    height: 48,
    borderRadius: 15,
    margin: 8,
  }
});

const Badge = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
`;

export default SpaceStats;
