import {createAction, createAsyncAction} from 'typesafe-actions';
import {AuthData, AuthToken, AuthResponse} from 'LoginFeature';
import { User } from 'UserFeature';

export const authenticateAsync = createAsyncAction(
  'AUTHENTICATION_REQUEST',
  'AUTHENTICATION_SUCCESS',
  'AUTHENTICATION_FAILURE',
)<AuthData, AuthResponse, string>();

export const loadUserAsync = createAsyncAction(
  'LOAD_USER_REQUEST',
  'LOAD_USER_SUCCESS',
  'LOAD_USER_FAILURE',
)<void, User, string>();

export const logoutAsync = createAsyncAction(
  'LOGOUT_REQUEST',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',
)<void, void, string>();

export const authToken = createAsyncAction(
  'AUTHTOKEN_UPDATE_REQUEST',
  'AUTHTOKEN_UPDATE_SUCCESS',
  'AUTHTOKEN_UPDATE_FAILURE',
)<AuthToken, undefined>();

export const refreshTokenAsync = createAsyncAction(
  'REFRESH_TOKEN_REQUEST',
  'REFRESH_TOKEN_SUCCESS',
  'REFRESH_TOKEN_FAILURE',
)<undefined, AuthToken, string>();

export const clearError = createAction('CLEAR_ERROR', () => { })();
