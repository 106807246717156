import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';
import {AuthToken} from 'LoginFeature';
import {authenticateAsync, loadUserAsync, logoutAsync, refreshTokenAsync, clearError} from './actions';
import {User} from 'UserFeature';

const reducer = combineReducers({
  isLoading: createReducer(false as boolean)
    .handleAction([authenticateAsync.request], (_state, _action) => true)
    .handleAction(
      [authenticateAsync.success, authenticateAsync.failure],
      (_state, _action) => false,
    ),

  authtoken: createReducer({} as AuthToken)
    .handleAction(
      authenticateAsync.success,
      (_state, action) =>
        ({
          token: action.payload.accessToken,
        } as AuthToken),
    )
    .handleAction(refreshTokenAsync.success, (state, action) => ({
      ...state,
      token: action.payload.token,
    }))
    .handleAction(logoutAsync.success, (_state, _action) => ({} as AuthToken)),

  user: createReducer({} as User)
    .handleAction(
      [authenticateAsync.success],
      (_state, action) => action.payload.user,
    )
    .handleAction(
      [loadUserAsync.success],
      (_state, action) => action.payload,
    )
    .handleAction([logoutAsync.success], (_state) => ({} as User)),

  error: createReducer('')
    .handleAction(
      [authenticateAsync.failure],
      (_state, action) => action.payload,
    ).handleAction(
      [loadUserAsync.failure],
      (_state, action) => action.payload,
    )
    .handleAction([authenticateAsync.request], (_state, _action) => '')
    .handleAction(clearError, (state, action) => null),

  isAuthenticated: createReducer(false)
    .handleAction([authenticateAsync.success], (_state, _action) => true)
    .handleAction([authenticateAsync.failure], (_state, _action) => false)
    .handleAction([logoutAsync.success], (_state, _action) => false),
});

export default reducer;
