export const event = {
  EventName: 'eventGA',
  WelcomeOrganization: 'Welcome - Publicis Organization',
  WelcomeOffice: 'Welcome - Office City',
  Click: 'Click',
  Navigation: 'Navigation',
  Profile: 'Profile',
  DashboardBook: 'Dashboard - Book a Space',
  DashboardOffice: 'Dashboard - Office',
  DashboardReservations: 'Dashboard - My reservations',
  CancelReservation: 'Delete a reservation',
  Error: 'Error',

  //GA4 events :
  GA4Navigation: 'navigation_click',
  GA4Profile: 'profile_click',
  GA4BookSpace: 'book_space',
  GA4SelectDate: 'select_date',
  GA4Employee: 'employee_present_click',
  GA4EditBooking: 'modify_reservation',
  GA4DeleteBooking: 'delete_reservation',
  GA4Button: 'button_click',
  GA4Map: 'map_click',
  GA4SelectSpace: 'select_desk',
  GA4ConfirmBooking: 'confirm_reservation',
  // GA4 page event
  GA4PageHome: 'home',
  GA4PageBooking: 'home',
}