import {RootEpic} from 'StoreTypes';
import {from, of} from 'rxjs';
import {filter, switchMap, map, catchError} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {loadHomeMessageAsync} from './actions';

export const homeMessageEpic: RootEpic = (action$, state$, {api}) =>
  action$.pipe(
    filter(isActionOf([loadHomeMessageAsync.request])),
    switchMap((action) => {
      return from(
        api.assistant.fetchMessage(action.payload, 'HOME')
      ).pipe(
        map((messageResponse) => {
          return loadHomeMessageAsync.success(messageResponse);
        }),
        catchError((err) => of(loadHomeMessageAsync.failure(err))),
      );
    }),
  );
