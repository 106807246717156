import moment from 'moment';
import { RRuleSet, rrulestr } from 'rrule';
import config from '../config';
import _head from 'lodash/head';
import { localize } from '../localization';


export const timesfromRrule = (value: string) => {
  const rruleSet = rrulestr(value);
  const rrule = rruleSet instanceof RRuleSet && rruleSet.rrules().length > 0 ? _head(rruleSet.rrules()) : rruleSet;
  const hours = rrule?.options.byhour;
  const minutes = rrule?.options.byminute;
  return [hours, minutes];
}

export const excludedDates = (value: string) => {
  const rruleSet = rrulestr(value);
  if (rruleSet instanceof RRuleSet) {
    return rruleSet.exdates().map((date) => moment.utc(date).format(config.exceptionDateFormat) + 'Z');
  }
  return [];
}

export const getDayText = (selectedDate: string): string => {
  if (selectedDate === moment().format(config.defaultDateFormat)) {
    return localize('TODAY')
  } else if (selectedDate === moment().add(1, 'd').format(config.defaultDateFormat)){
    return localize('TOMORROW')
  } else {
    return moment(selectedDate).locale(localize('LOCALE_LANGUAGE')).format(config.dayNameFormat).replace(/\b[a-z]/, match => match.toUpperCase())
  }
}

export const capitalize = (date: string): string => {
  return date.charAt(0).toUpperCase() + date.slice(1);
}