import { Language } from 'OrganizationFeature';
import {combineReducers} from 'redux';
import config from '../../config';
import {createReducer} from 'typesafe-actions';
import {User} from 'UserFeature';
import {
  loadUsersAsync,
  setSpaceIdSelectedForMapping,
  setUserSearchTextForMapping,
  updateUserAsync,
  updateUserNoImageAsync,
  clearError
} from './actions';
import { Booking } from 'BookingFeature';

const reducer = combineReducers({
  isLoadingUsers: createReducer<boolean>(false)
    .handleAction([loadUsersAsync.request], (_state, _action) => true)
    .handleAction(
      [loadUsersAsync.success, loadUsersAsync.failure],
      (_state, _action) => false,
    ),

  usersError: createReducer('')
    .handleAction([loadUsersAsync.failure], (_state, action) => action.payload)
    .handleAction([loadUsersAsync.request], (_state, _action) => ''),

  users: createReducer([] as User[])
    .handleAction(
      loadUsersAsync.success,
      (_state, action) => {
        action.payload.map((user: User)=>{
          const bookings = user.bookings.filter((booking : Booking) => booking.office.hostId === config.officeId );
          user.bookings = bookings;
          return user;
        })
        return action.payload;
      },
    ).handleAction(updateUserAsync.success, (state, action) => {
      const other = state.filter((item) => item.id !== action.payload.id);
      return [...other, action.payload];}
  ).handleAction(updateUserNoImageAsync.success, (state, action) => {
    const other = state.filter((item) => item.id !== action.payload.id);
    return [...other, action.payload];}
),

  userSearchTextForMapping: createReducer<string | null>(null).handleAction(
    setUserSearchTextForMapping,
    (state, action) => action.payload,
  ),

  spaceIdSelectedForMapping: createReducer<string | null>(null).handleAction(
    setSpaceIdSelectedForMapping,
    (state, action) => action.payload,
  ),

  updateUserError: createReducer<string | null>(null)
  .handleAction([updateUserAsync.failure], (state, action) => action.payload)
  .handleAction([updateUserNoImageAsync.failure], (state, action) => action.payload)
  .handleAction([updateUserAsync.request], (_state, _action) => '')
  .handleAction([updateUserNoImageAsync.request], (_state, _action) => '')
  .handleAction(clearError, (state, action) => null),
});

export default reducer;
