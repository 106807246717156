import {RootState} from 'StoreTypes';
import {connect} from 'react-redux';
import React from 'react';
import {
  Image,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Button,
} from 'react-native';
import menuData from './menu_data.json';
import {useNavigation} from '@react-navigation/native';
import { localize } from '../../localization';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Menu: React.FC<Props> = () => {
  const navigation = useNavigation();

  const onMenuItemPressed = (menuNavigation: string) => {
    navigation.navigate(menuNavigation);
  };

  const navigateHome = () => {
    navigation.navigate('HomeView');
  };

  return (
    <View>
      <FlatList
        data={menuData}
        renderItem={({item}) => (
          <TouchableOpacity
            style={item.id === 'home' ? styles.selected : styles.listItem}
            onPress={() => onMenuItemPressed(item.navigation)}>
            <Image style={styles.image} source={{uri: item.icon}} />
            <Text style={styles.title}>
              {localize(item.title)}
            </Text>
          </TouchableOpacity>
        )}
      />
      <Button title={localize('HOME')} onPress={navigateHome} />
    </View>
  );
};

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    backgroundColor: '#272E30',
    width: 245,
    height: 48,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 8,
    marginBottom: 8,
    alignItems: 'center',
  },
  selected: {
    flexDirection: 'row',
    backgroundColor: '#131617',
    borderColor: '#FFFFFF',
    borderWidth: 1,
    width: 245,
    height: 48,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 8,
    marginBottom: 8,
    alignItems: 'center',
  },
  image: {
    width: 24,
    height: 24,
  },
  title: {
    color: '#FFFFFF',
    marginStart: 12,
  },
});

export default connect(mapStateToProps, dispatchProps)(Menu);
