import {createReducer} from 'typesafe-actions';
import {applyTheme, setLanguage} from './actions';
import {ThemeStyle} from './';
import {combineReducers} from 'redux';

const reducer = combineReducers({
  theme: createReducer(ThemeStyle.LIGHT).handleAction(
    applyTheme,
    (state, action) => action.payload,
  ),

  language: createReducer<string | null>(null).handleAction(
    setLanguage,
    (state, action) => action.payload,
  ),
});

export default reducer;
