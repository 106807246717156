import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Platform, Dimensions } from 'react-native';
import BookingsList from '@components/bookings/BookingsList';

import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import { logoutAsync, loadUserAsync } from '@features/login/actions';
import { loadBookingAsync, setSelectedDate, resetBookingDeleted, clearLastBooking } from '@features/booking/actions';
import moment from 'moment';
import config from '../config';
import WelcomeView from '@components/WelcomeView';
import { BookingViewName } from './BookingView';
import CalendarsScreen from '@components/Calendar/Calendar';
import { colors, palette, shadow } from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import AvatarCarousel from '@components/AvatarCarousel';
import { showUsersListView } from '@features/home/actions';
import { User } from 'UserFeature';
import {
  fetchSpaceGroupsAsync,
  fetchSpacesAsync,
  fetchSpaceTagsAsync,
} from '@features/space/actions';
import SpaceStatsView from '@components/spaceStats/SpaceStatsView';
import { Divider, ModalView, StyledText, Dialog } from '@space/common';
import { isEmpty } from 'lodash';
import { Booking } from 'BookingFeature';
import UserAvailabilityListView from './user/UserAvailabilityListView';
import { localize } from '../localization';
import { SpaceHelper } from '../utils/SpaceHelper';
import Avatar from '@components/Avatar';
import Modal from 'modal-enhanced-react-native-web';
import { ThemeStyle } from '@features/preferences';
import UserAccount from '@components/user/UserAccount';
import { excludedDates } from '../utils/dateutils';
import { loadUsersAsync } from '@features/user/actions';
import { event } from '../utils/analysticsConstants'
import TagManager from 'react-gtm-module'
import { fetchOrganizationAsync } from '@features/organization/actions';

const mapStateToProps = (state: RootState) => ({
  theme: state.preferences.theme,
  booking: state.booking.currentUserBookings,
  user: state.login.user,
  userlistViewDisplayed: state.home.userListIsDisplayed,
  spaceGroups: state.space.spaceGroups,
  createBookingError: state.booking.bookingError,
  bookings: state.booking.bookings,
  newBookingCreated: state.booking.userLastBooking,
  bookingDeleted: state.booking.bookingDeleted,
  selectedDate: state.booking.selectedDate,
  organization: state.organization.organization,
  spacesList: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  allUsers: state.user.users,
  officeOpenCache: state.organization.officeOpenCache
});

const dispatchProps = {
  logout: logoutAsync.request,
  setSelectedDate: setSelectedDate,
  showUsersList: showUsersListView,
  fetchUser: loadUserAsync.request,
  fetchSpaceGroups: fetchSpaceGroupsAsync.request,
  fetchBookings: loadBookingAsync.request,
  fetchSpaces: fetchSpacesAsync.request,
  fetchSpaceTags: fetchSpaceTagsAsync.request,
  resetBookingDeleted: resetBookingDeleted,
  fetchUsers: loadUsersAsync.request,
  fetchOrganization: fetchOrganizationAsync.request
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const HomeView: React.FC<Props> = ({
  theme,
  booking,
  user,
  navigation,
  selectedDate,
  isBookingViewDisplayed,
  userlistViewDisplayed,
  showUsersList,
  fetchUser,
  fetchSpaceGroups,
  fetchBookings,
  fetchSpaceTags,
  fetchSpaces,
  fetchOrganization,
  newBookingCreated,
  resetBookingDeleted,
  organization,
  spacesList,
  availableSpacesCache,
  allUsers,
  fetchUsers,
  officeOpenCache,
  setSelectedDate
}) => {

  const [showUserAccount, setShowUserAccount] = useState(false);
  const [maxReservationAlert, setMaxReservationAlert] = useState(false);

  const onBookPressed = () => {
    clearLastBooking();
    if (booking.length >= SpaceHelper.userMaxReservation(user)) {
      setMaxReservationAlert(true);
    } else {
      resetBookingDeleted();
      navigation.navigate(BookingViewName);
    }
  };

  useEffect(() => {
    if(newBookingCreated != null) {
      fetchOrganization();
      fetchUser();
      fetchUsers();
      fetchSpaceGroups();
  
      const startDate = moment().utc().format(config.defaultDateFormat);
      const endDate = moment().utc().add(3, 'w').format(config.defaultDateFormat);
  
      fetchBookings({ startDate, endDate });
      fetchSpaces({ startDate, endDate });
      fetchSpaceTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBookingCreated]);

  useEffect(() => {
    if(!isEmpty(booking) || showUserAccount) {
      fetchUser();
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUserAccount, booking]);

  /// Fetch users once on page load.
  useEffect(() => {
      fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const users = allUsers.filter((user: User) => 
    user.bookings?.some((booking: Booking) => 
      selectedDate === moment(booking.schedule.startDate).format(config.defaultDateFormat) && booking.office?.hostId == config.officeId
    )
  );

  const organizationExcludedDates = useMemo(() => {
    if (!isEmpty(organization.rrule)) {
      return excludedDates(organization.rrule);
    }
    return [];
  }, [organization]);

  const isbookingAvailable = useMemo(() => {
    return !isEmpty(SpaceHelper.isOfficeOpened(selectedDate, officeOpenCache, spacesList, availableSpacesCache));
  }, [selectedDate, officeOpenCache, spacesList, availableSpacesCache]);
  
  const literalSelectedDate = moment(selectedDate).format(config.exceptionDateFormat) + 'Z';

  return (
    <HomeContainer showUserAccount={showUserAccount}>
      <RootContainer>
        <AvatarContainer>
          <Avatar
            name={user.firstName}
            lastName={user.lastName}
            src={user.avatar}
            onPress={() => {
              setShowUserAccount(true);
              TagManager.dataLayer({
                dataLayer: {
                  event: event.GA4Navigation,
                  current_page: event.GA4PageHome,
                  button_label: localize('ACCOUNT')
                }
              })
            }}/>
        </AvatarContainer>
        <Container>
          <WelcomeView />
          <MainContainer>
            <CalendarContainer>
              <CalendarsScreen
                displayWeekView={true}
                onDateSelect={setSelectedDate}
              />
            </CalendarContainer>
            <Divider variant={'middle'} />
            <AvatarCarouselContainer>
              <StyledText variant={'h3'} fontColor={colors.grey4} style={styles.avatarTitle} letterSpacing={'0.01em'}>
                {localize('CARROUSEL_TITLE')}
              </StyledText>
              <AvatarCarousel
                users={users}
                onPress={(_usersItem) => {
                  showUsersList(true);
                }}
                reloadData={isBookingViewDisplayed}
                isFromDashboard={true}
              />
            </AvatarCarouselContainer>
            <Divider variant={'middle'}/>
            <StyledAvailabilities>
              <SpaceStatsView />
            </StyledAvailabilities>
          </MainContainer>
          <Divider variant={'fullWidth'}/>
          <BookingsList />
          <ModalView isVisible={userlistViewDisplayed}>
            <UserListContainer>
              <UserAvailabilityListView users={users}/>
            </UserListContainer>
          </ModalView>
        </Container>
      </RootContainer>
      <Modal
        style={{justifyContent: 'flex-start', margin: 0}}
        isVisible={showUserAccount}
        backdropColor={theme === ThemeStyle.DARK ? colors.backgroundDark : colors.backgroundLight}
        backdropOpacity={1}
        propagateSwipe
        animationIn="slideInRight"
        animationOut="slideOutRight">
          <UserAccount setShowUserAccount={setShowUserAccount}/>
      </Modal>
      <Dialog
        isOpen={maxReservationAlert}
        title={localize("OUPS")}
        body={localize("MAX_RESERVATION")}
        onClose={() => setMaxReservationAlert(false)}
        cancelTitle={localize('APP_BUTTON_OK')}
      />
      <BookingButtonContainer>
        {isbookingAvailable ?
          <BookingButton
            onPress={() => {
              onBookPressed();
              TagManager.dataLayer({
                dataLayer: {
                  event: event.GA4BookSpace,
                  current_page: event.GA4PageHome,
                  date_selected:`${moment(selectedDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_ONLYDAY_FORMAT'))}`,
                  button_label:`${localize('BOOKING')+' - '+ organization.currentOffice.location}`,
                }
              })
              }}>
            <StyledText variant={'button'} fontWeight={'600'} fontSize={18} fontColor={colors.white}>
              {localize('BOOKING')}
            </StyledText>
          </BookingButton> :
          <>
            <StyledText 
              variant={'h3'} 
              fontColor={colors.black}
              lineHeight={'24px'}
              letterSpacing={'0.02em'}
              style={styles.buttonHeaderText}>
              {localize('NO_AVAILABLE_SPACE')}
            </StyledText>
            <StyledText 
              variant={'body1'} 
              fontColor={colors.grey9}
              lineHeight={'24px'}
              letterSpacing={'0.02em'}
              style={styles.buttonText}>
              {localize('SELECT_ANOTHER_DAY')}
            </StyledText>
            <DisabledButton
              disabled={true}
            >
              <StyledText 
                variant={'button'} 
                fontWeight={'600'} 
                fontSize={18}
                lineHeight={'24px'}
                letterSpacing={'0.01em'}
                fontColor={colors.white}>
                {localize('BOOKING')}
              </StyledText>
            </DisabledButton>
          </>
        }
      </BookingButtonContainer>
    </HomeContainer>
  );
};

type ContainerProps = {
  showUserAccount: boolean;
};

const HomeContainer = styled.SafeAreaView<ContainerProps>`
    overflow-y: ${(props) => (props.showUserAccount ? 'hidden' : '')};
    position: ${(props) => (props.showUserAccount ? 'fixed' : '')};
    flex: 1 1 auto;
`;

const RootContainer = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
`;

const MainContainer = styled.View`
  max-width: 343px;
  align-items: center;
  justify-content: center;
  background-color: ${palette.common};
  box-shadow: ${shadow};
  border-radius: 16px;
  margin-bottom: 48px;
`;

const StyledAvailabilities = styled.View<any>`
  max-width: 343px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 32px 0px;
`;

const Container = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const AvatarContainer = styled.View`
  width: 100%;
  align-items: end;
  padding: 16px;
`;

const AvatarCarouselContainer = styled.View`
  width: 100%;
  padding: 24px 16px 24px 16px;
`;

const CalendarContainer = styled.View`
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const UserListContainer =styled.View`
  max-width: 343px;
  border-radius: 16px;
  box-shadow: ${shadow};
  background-color: ${palette.common};
`;

const BookingButtonContainer = styled.View`
  flex: 1;
  width: ${Platform.OS === 'web' ? '100vw' : `${Dimensions.get('screen').width}px`};
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  z-index: 1;
  background: white;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
`;

const BookingButton = styled.TouchableOpacity`
  max-width: 343px;
  width: 100%;
  background: ${palette.primary};
  border-radius: 32px;
  box-shadow: ${shadow};
  height: 56px;
  justify-content: center;
  align-items: center;
`;

const DisabledButton = styled.TouchableOpacity`
  max-width: 343px;
  width: 100%;
  background: ${palette.disable};
  border-radius: 32px;
  box-shadow: ${shadow};
  height: 56px;
  justify-content: center;
  align-items: center;
`;

const styles = StyleSheet.create({
  avatarTitle: {
    paddingBottom: '17px'
  },
  buttonHeaderText: {
    paddingTop: '9px',
    lineHeight: '24px',
    letterSpacing: '0.02em'
  },
  buttonText: {
    paddingBottom: '24px',
    lineHeight: '24px',
    letterSpacing: '0.02em'
  }
});

export const HomeViewName = 'HomeView';

export default React.memo(connect(mapStateToProps, dispatchProps)(HomeView));
