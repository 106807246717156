import React from 'react';
import {StyledText, CircularIcon, Dialog} from '@space/common';
import styled, { useTheme } from 'styled-components/native';
import { colors, palette, shadow} from '@features/preferences/style/themes';
import {SpaceModel} from '@features/space';
import Pod from '../img/pod.svg';
import Room from '../img/room.svg';
import Seat from '../img/seat.svg';
import Forward from '../img/next.svg';
import { Booking } from 'BookingFeature';
import { localize } from '../localization';
import { ThemeUtil } from '@space/common';
import { BookingSpaceViewName } from '../routes/BookingSpaceView';
import {useDispatch} from 'react-redux';
import { handleForwardStep, handleResetStep } from '@features/booking/actions';
import { event } from '../utils/analysticsConstants'
import moment from 'moment';
import TagManager from 'react-gtm-module'

type AvailabilityListItemProps = {
  description: string;
  model: SpaceModel;
  rightIcon?: string;
  disabled?: boolean;
  userBookings?: Booking[];
  navigation?: any;
  multipleBookingAlert?: Boolean;
  setMultipleBookingAlert?: any;
  selected: Booking;
};

type StyledContainerProps = {
  disabled: boolean;
};

const svgType = (model: SpaceModel) => {
  switch (model) {
    case SpaceModel.POD:
      return Pod;
    case SpaceModel.ROOM:
      return Room;
    case SpaceModel.SEAT:
      return Seat;
  }
};

const AvailabilityListItem: React.FC<AvailabilityListItemProps> = ({
  description,
  model,
  rightIcon,
  disabled = false,
  userBookings,
  navigation,
  multipleBookingAlert,
  setMultipleBookingAlert,
  selected,
}) => {

  const dispatch = useDispatch();
  const styleName = useTheme().mode;
  const SpaceName = svgType(model)

  return (
    <StyledContainer disabled={disabled}>
      <LeftContent>
        <SpaceName style={{marginRight: '8px', width: '32px', height: '32px', fill: colors.black}} />
        <StyledText 
          variant={'h3'}
          lineHeight={'100%'}
          letterSpacing={'0.01em'}
          color={'primary'}>
            {description}
        </StyledText>
      </LeftContent>
      <CircularIcon
        svg={Forward}
        size={'xlarge'}
        accessibilityLabel={'TODO'}
        variant={'fill'}
      />
      {userBookings &&
        <Dialog
        isOpen={multipleBookingAlert !== false && !selected}
        title={localize('ATTENTION')}
        onClose={() => {
          navigation.navigate(BookingSpaceViewName);
          setMultipleBookingAlert(false);
          dispatch(handleForwardStep());
          TagManager.dataLayer({
            dataLayer: {
              event: event.GA4Button,
              current_page: `${selected && selected.id ? 'Edit' : 'Made'} a reservation`,
              button_title: `${localize('ATTENTION')}`,
              button_label:  localize('YES'),
              step: '1'
            }
          })
        }}
        body={localize('MULTIPLE_BOOKING_ALERT')}
        actionTitle={localize('NO')}
        cancelTitle={localize('YES')}
        cancelVariant={'primary'}
        cancelInverted={false}
        cancelFontColor={ThemeUtil.getThemeValue(palette.common)(styleName)}
        onbuttonAction={() => {
          navigation.goBack();
          setMultipleBookingAlert(false);
          dispatch(handleResetStep());
          TagManager.dataLayer({
            dataLayer: {
              event: event.GA4Button,
              current_page: `${selected && selected.id ? 'Edit' : 'Made'} a reservation`,
              button_title: `${localize('ATTENTION')}`,
              button_label:  localize('NO'),
              step: '1'
            }
          })
        }}/>
      }
    </StyledContainer>
  );
};

const StyledContainer = styled.View<StyledContainerProps>`
  background-color: ${palette.common};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  border-radius: 16px;
  box-shadow: ${shadow};
  width: 100%;
  flex-direction: row;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 24px 8px 24px 16px;
`;

const LeftContent = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default AvailabilityListItem;

