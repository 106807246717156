import {combineReducers} from "redux";
import {createReducer} from "typesafe-actions";
import {hideModalView, showModalView} from "./actions";

const reducer = combineReducers({
    isVisible: createReducer<boolean>(false)
        .handleAction(
            [showModalView, hideModalView],
            (state, action) => action.payload
        )
});

export type AppState = ReturnType<typeof reducer>;

export default reducer;