import React, { useEffect, useMemo, useState } from "react";
import { ScrollView, Dimensions, StyleSheet } from "react-native";
import {
  Calendar as CalendarReact,
  CalendarList,
  DateObject,
  LocaleConfig
} from "react-native-calendars";
import moment from "moment";

import { fetchSpacesAsync } from "../../features/space/actions";
import { setSelectedDate } from "../../features/booking/actions";

import { connect } from "react-redux";
import { RootState } from "StoreTypes";
import config from "../../config";
import DayView from "./DayView";
import HorizontalCalendar from "./HorizontalCalendar";
import SpaceHelper from "../../utils/SpaceHelper";
import styled from "styled-components/native";
import { colors, shadow } from "../../features/preferences/style/themes";
import _ from "lodash";
import CalendarHeader from "./CalendarHeader";
import { fetchOrganizationAsync } from "@features/organization/actions";
import { localize } from '../../localization';
import { StyledText, Divider, weeksForOfficeInAdvanceBookingLimit } from "@space/common";
import { Occupancy } from "SpaceFeature";

const mapStateToProps = (state: RootState) => ({
  isLoading: state.space.isLoading,
  error: state.space.error,
  spacesList: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  selectedDate: state.booking.selectedDate,
  office: state.organization.office,
  officeOpenCache: state.organization.officeOpenCache,
  selected: state.booking.selectedBooking
});

const dispatchProps = {
  fetchOrganization: fetchOrganizationAsync.request,
  fetchSpaces: fetchSpacesAsync.request,
  selectDate: setSelectedDate,
};

type CalendarProps = {
  displayWeekView: boolean;
  onDateSelect: (date: string) => void;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  CalendarProps;

const CalendarsScreen: React.FC<Props> = ({
  spacesList,
  availableSpacesCache,
  selectedDate,
  fetchSpaces,
  office,
  officeOpenCache,
  displayWeekView,
  onDateSelect,
  fetchOrganization,
  selected
}) => {
  useEffect(() => {
    fetchOrganization();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentDate, setCurrentDate] = useState<string>(selectedDate);

  const startDateValue = () => {
    if (moment().isoWeekday() === 7) {
      return moment().locale(localize('LOCALE_LANGUAGE'));
    } else {
      return moment().locale(localize('LOCALE_LANGUAGE')).subtract(1, "week").endOf("week");
    }
  };

  const officeBookingWeeks = useMemo(() => {
    return office.inAdvanceBookingLimit ? weeksForOfficeInAdvanceBookingLimit(office.inAdvanceBookingLimit) : config.weeksToDisplay;
  }, [officeOpenCache]);

  const occupancies: Map<string, Occupancy> = useMemo(() => {
    const result = new Map<string, any>();

    _.range(officeBookingWeeks).forEach((week) => {
      const weekStartDate = startDateValue().add(week, 'weeks');
      _.range(7).forEach((day) => {
        const date = moment(weekStartDate)
          .add(day, 'day')
          .format(config.defaultDateFormat);

        const occupancy = SpaceHelper.cacheForOccupancy(date, availableSpacesCache);
        if (occupancy != null && officeOpenCache.get(date)) {
          result.set(date, occupancy);
        }
      });
    });
    
    return result;
  }, [officeOpenCache, spacesList]);

  const occupancyForDate = (date: string) => {
    const isofficeOpen = officeOpenCache.size > 0 ? officeOpenCache.get(date) : false;
    if (!isofficeOpen) {
      return null;
    }
    return occupancies.get(date);
  };

  const didSelectDate = (day: DateObject) => {
    const date = day.dateString;
    onDateSelect(date);
    setCurrentDate(date);
  };

  const horizontalCalendarDidSelectDate = (date: string) => {
    onDateSelect(date);
    setCurrentDate(date);
  }

  const previousScroll = useMemo(() => {
    const current = moment(currentDate);
    return moment().utc().month() != current.month() ? 1 : 0;
  }, [currentDate]);

  const futureScroll = useMemo(() => {
    const current = moment(currentDate);
    const endInAdvance = moment().add(officeBookingWeeks, 'week').utc();
    return endInAdvance.month() != current.month() ? 1 : 0;
  }, [currentDate]);

  const renderCalendarWithSelectableDate = () => {
    LocaleConfig.defaultLocale = localize('LOCALE_LANGUAGE')

    document.querySelectorAll("[role='slider']").forEach((el) => {
    el.removeAttribute("tabindex")});

    return (
      <CalendarList
        monthFormat={'MMM'}
        calendarWidth={_.min([Dimensions.get("window").width, 375])}
        calendarHeight={300}
        pastScrollRange={previousScroll}
        current={currentDate}
        futureScrollRange={futureScroll}
        style={styles.calendar}
        onDayPress={didSelectDate}
        markedDates={{
          [currentDate]: { selected: true, marked: true },
        }}
        dayComponent={(props) => {
          return (
          <DayView
            {...props}
            occupation={
              occupancyForDate(props.date.dateString)?.occupancy ?? null
            }
            isBookingSelected={selected}
          />
          )}}
        hideDayNames={true}
        theme={{
          calendarBackground: "",
          "stylesheet.day.basic": {
            base: {
              width: 32,
              height: 32,
            },
          },
          monthTextColor: colors.grey3,
          "stylesheet.calendar.main": {
            week: {
              marginTop: 0,
              marginBottom: 0,
              flexDirection: "row",
              justifyContent: "space-around",
            },
          },
          "stylesheet.calendar-list.main": {
            calendar: {
              paddingLeft: 24,
              paddingRight: 24,
              height: 'auto'
            },
          },
          "stylesheet.calendar.header": {
            header: {
              alignItems: "flex-start",
              paddingLeft: 11,
              height: 30,
            },
            monthText: {
              fontSize: 12,
              fontFamily: 'Outfit_600SemiBold',
              color: colors.black,
              margin: 10,
            },
          },
        }}
        onMonthChange={(date) => {
          if (moment(date).isAfter(moment().startOf("day"))) {
            //TODO change state of range with setRange()

            fetchSpaces({
              startDate: moment(date.dateString)
                .subtract(1, "months")
                .format(config.defaultDateFormat),
              endDate: moment(date.dateString)
                .add(1, "months")
                .format(config.defaultDateFormat),
            });
          }
        }}
      />
    );
  };

  var daysView: any[] = _.range(7).map((day) => {
    const date = startDateValue().day(day);

    return (
      <>
      <StyledText
        accessible={false}
        accessibilityLabel={date.locale(localize('LOCALE_LANGUAGE')).format("dddd")}
        key={day}
        variant={"body1"}
        fontSize={14}
        fontWeight={"600"}
        color={_.includes([6, 7], date.isoWeekday()) ? "hint" : "primary"} //TODO : Fix color: ;
        style={
          textStyles({ disabled: _.includes([6, 7], date.isoWeekday()) })
            .dayText
        }
      >
        {date.locale(localize('LOCALE_LANGUAGE')).format(config.dayNameFormat).slice(0,3).replace(/\b[a-z]/, match => match.toUpperCase())}
      </StyledText>
      </>
    );
  });

  if(!office.rrule){ 
    return <></>
  }

  return (
    <>
      {!displayWeekView &&
        <StyledText
            variant={"h2"}
            fontSize={24}
            fontWeight={"600"}
            lineHeight={'20px'}
            letterSpacing={'0.01em'}
            style={styles.calendarTitle}
          >
          {localize('DATE')}
        </StyledText>
      }
      <CalendarContainer displayWeekView={displayWeekView}>
        {!displayWeekView && <CalendarHeader displayWeekView={displayWeekView} selectedDate={currentDate}/>}   
        {!displayWeekView && <><Divider variant={'middle'}/><DaysContainer>{daysView}</DaysContainer><Divider variant={'middle'}/></>}
        {displayWeekView ? (
          <HorizontalCalendar
            startDate={moment().startOf('week').format(config.defaultDateFormat)}
            weeks={officeBookingWeeks}
            occupationForDate={(date) =>
              occupancyForDate(date)?.occupancy ?? null
            }
            onDateSelect={horizontalCalendarDidSelectDate}
            daysView={daysView}                       
          />
        ) : (
          <ScrollView showsVerticalScrollIndicator={false}>
            {renderCalendarWithSelectableDate()}
          </ScrollView>
        )}
      </CalendarContainer>
    </>
  );
};

export default connect(mapStateToProps, dispatchProps)(CalendarsScreen);

type DayTextProps = {
  selected: boolean;
};

type HeaderProps = {
  displayWeekView: boolean;
};

const CalendarContainer = styled.View<HeaderProps>`
  align-items: center;
  max-width: ${(props) => !props.displayWeekView ? '343px' : ''};
  max-height: ${(props) => !props.displayWeekView ? '368px' : ''};
  background-color: ${(props) => !props.displayWeekView ? colors.white : ''};
  border-radius: ${(props) => !props.displayWeekView ? "16px" : ''};
  box-shadow: ${(props) => props.displayWeekView ? '0px 0px 0px' : shadow};
`;

const DaysContainer = styled.View`
  flex-direction: row;
  width: 100%;
  max-width: 343px;
  justify-content: space-around;
  padding: 8px 12px;
  align-items: stretch;
`;

const styles = StyleSheet.create({
  calendar: {
    maxWidth: 375,
    height: 305,
    paddingBottom: 4,
  },
  calendarTitle: {
    marginTop: 24,
    marginBottom: 16
  }
});

const textStyles = (props) =>
  StyleSheet.create({
    todayText: {
      textAlign: "center",
      paddingVertical: 2,
      paddingHorizontal: props.selected ? 8 : 0,
      borderRadius: 4,
      height: 16,
      width: props.selected ? "auto" : 0,
      color: colors.white,
    },
    dateText: {
      marginLeft: 8,
      letterSpacing: 3.2,
    },
    dayText: {
      paddingBottom: 1,
      paddingTop: 2,
      textAlign: "center",
      width: 32,
      height: 18,
    }
  });
