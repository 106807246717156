import {createAction} from "typesafe-actions";

export const showModalView = createAction(
    "SHOW_MODAL_VIEW",
    () => true
)();

export const hideModalView = createAction(
    "HIDE_MODAL_VIEW",
    () => false
)();
