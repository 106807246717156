import moment from 'moment';
import _ from 'lodash';
import { timesfromRrule } from './dateutils';
import { Office } from 'OrganizationFeature';
import { SpaceHelper } from './SpaceHelper';

export const updateDateWithOfficeHours = (
  office: Office,
  selectedDate: string,
) => {
  //No matter the local timezone the date is for tthe office timezone 
  let date = moment(selectedDate, 'YYYY-MM-DD', SpaceHelper.getRruleCurrentOffice(office).options.tzid);

  const time = timesfromRrule(office.rrule);
  const [hours, minutes] = time;
  const [startHour, endHour] = hours;
  const [startMinutes, endMinutes] = minutes;

  const startDate = date.clone().hour(startHour).minute(startMinutes).utc().toISOString();
  const endDate = date.clone().hour(endHour).minute(endMinutes).utc().toISOString();

  return { startDate: startDate, endDate: endDate };
};


