import config from '../config';
import { ApiClient } from '@space/common';
import { store as reduxStore } from '../App';
import { authenticateAsync } from '@features/login/actions';
import { AuthResponse } from 'LoginFeature';

const Api = ApiClient(
  {
    baseUrl: config.url.base,
    server: config.url.server,
    msClientId: config.msClientId,
    redirectUrl: config.url.redirect
  },
  (response?: AuthResponse) => {
    if(response) {
      reduxStore.store.dispatch(authenticateAsync.success(response));
    } else {
      reduxStore.store.dispatch(authenticateAsync.failure(""));
    }
  }
);

export default Api;