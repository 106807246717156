import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Language, Office, Organization } from 'OrganizationFeature';
import {
  fetchOrganizationAsync,
} from './actions';
import config from '../../config';
import { weeksForOfficeInAdvanceBookingLimit, openDates } from "@space/common";
import SpaceHelper from '../../utils/SpaceHelper';
import moment from 'moment';

const reducer = combineReducers({
  isLoadingOrganization: createReducer(false as boolean)
    .handleAction([fetchOrganizationAsync.request], (state, action) => true)
    .handleAction(
      [fetchOrganizationAsync.success, fetchOrganizationAsync.failure],
      (state, action) => false,
    ),

  organization: createReducer({} as Organization).handleAction(
    fetchOrganizationAsync.success,
    (_state, action) => {
      const office = action.payload.offices.find((office: Office)=>office.hostId === config.officeId);
      return {...action.payload, rrule: office.rrule, inAdvanceBookingLimit: office.inAdvanceBookingLimit, currentOffice: office};
    },
  ),

  office: createReducer<Office>({} as Office)
  .handleAction([fetchOrganizationAsync.success],
  (_state, action) => {
    return action.payload.offices.find((office: Office)=> office.hostId === config.officeId);
  }),

  officeOpenCache: createReducer<Map<string, boolean>>(new Map<string, boolean>())
  .handleAction([fetchOrganizationAsync.success],
  (_state, action) => {
    const office = action.payload.offices.find((office: Office)=> office.hostId === config.officeId);

    const officeBookingWeeks = weeksForOfficeInAdvanceBookingLimit(office.inAdvanceBookingLimit);
    var finalDates = new Map<string, boolean>();
    const dates = openDates(office.rrule, officeBookingWeeks);
    dates.forEach((value, key) => {
      if (value) finalDates.set(key, SpaceHelper.isOfficeOpen(office, moment(key))) ;
      else finalDates.set(key, value);
    });
    return finalDates;
  }),

  languages: createReducer<Language[]>([])
  .handleAction(fetchOrganizationAsync.success,
  (_state, action) => {
    return action.payload.languages.filter((language: Language) => language.active);
  }),

  organizationError: createReducer('')
    .handleAction([fetchOrganizationAsync.request], (state, action) => ''),
  });

export default reducer;
