import React, {useContext} from 'react';
import {View, Text} from 'react-native';
import {baseStyles} from '../../styles';
import Menu from '../../admin/component/Menu';
import { localize } from '../../localization';

const AdminHomeView: React.FC = () => {

  return (
    <View style={baseStyles.container}>
      <Text style={baseStyles.title}>{localize('ADMIN_MENU_HOME')}</Text>
      <Menu />
    </View>
  );
};

export const AdminHomeViewName = 'AdminHomeView';

export default AdminHomeView;
