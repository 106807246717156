import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import booking from '@features/booking/reducer';
import conditions from '@features/conditions/reducer';
import home from '@features/home/reducer';
import login from '@features/login/reducer';
import modal from '@features/modal/reducer';
import preferences from '@features/preferences/reducer';
import space from '@features/space/reducer';
import user from '@features/user/reducer';
import organization from '@features/organization/reducer';

const rootReducer = (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    booking,
    conditions,
    home,
    login,
    modal,
    preferences,
    space,
    user,
    organization,
  });

export default rootReducer;
