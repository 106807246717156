import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {RootState} from 'StoreTypes';
import styled, {useTheme} from 'styled-components/native';
import Back from '../../img/backward.svg';
import Close from '../../img/close.svg';
import { StyledText, ThemeUtil } from '@space/common';
import { colors, palette } from '@features/preferences/style/themes';
import { localize } from '../../localization';
import ProgressBar from '@ramonak/react-progress-bar';
import { View } from 'react-native';
import { handleBackwardStep } from '@features/booking/actions';

const mapStateToProps = (state: RootState) => ({
  selectedDate: state.booking.selectedDate,
  stepCounter: state.booking.stepCounter
});

const dispatchProps = {};

export type ModalHeaderAction = 'none' | 'back' | 'close';

type ModalProps = {
  /**
   * The Left action button (back/close) or none
   */
  leftAction: ModalHeaderAction;
  title: string;
  onPress?: () => void;
  stepCounter: number;
};

const renderIcon = (leftAction: ModalHeaderAction, styleName: string) => {
  const theme = useTheme();
  const props = {
    fill: ThemeUtil.getInvertedThemeValue(theme.palette.common)(styleName),
    width: 24,
    height: 24,
  };

  switch (leftAction) {
    case 'back':
      return <Back {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'none':
      return null;
  }
};

const ModalHeader: React.FC<ModalProps> = ({leftAction, title, onPress, stepCounter}) => {
  const dispatch = useDispatch();

  const decreaseStepCounter = (counter: number) => {
    if (counter > 1){
      dispatch(handleBackwardStep());
    }
  }
  
  const styleName = useTheme().mode;
  return (
    <View style={{width: '100%'}}>
      <ProgressBar
        completed={stepCounter / 0.04}
        bgColor={ThemeUtil.getThemeValue(palette.primary)(styleName)}
        height={'6px'}
        isLabelVisible={false}
        baseBgColor={colors.white}
        borderRadius={'0'}
      />
      <StyledContainer>
        <TopContainer>
          <ActionButton onPress={() => {onPress(); decreaseStepCounter(stepCounter);}}>
            {renderIcon(leftAction, styleName)}
          </ActionButton>
          <StepContainer>
            <StyledText variant={'body2'} fontColor={colors.white} lineHeight={'0'}>
              {localize('STEP', {counter: stepCounter})}
            </StyledText>
          </StepContainer>
        </TopContainer>
        <StyledText variant={'h1'} fontColor={colors.white} lineHeight={'100%'}>
          {title}
        </StyledText>
      </StyledContainer>
    </View>
  );
};

export default connect(mapStateToProps, dispatchProps)(ModalHeader);

const ActionButton = styled.TouchableOpacity`
  width: 40px;
  height: 40px;
  background-color: ${colors.white};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 24px;
`;

const TopContainer = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StepContainer = styled.View`
  width: 112px;
  height: 40px;
  border: 1px solid ${colors.white};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #403E3D;
`;

const StyledContainer = styled.View`
  position: sticky;
  width: 100%;
  height: 192px;
  padding: 16px;
  align-items: flex-start;
  z-index: 2;
  background-image: url("https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/qa/static/background_reservation_desktop.jpg")
`;
