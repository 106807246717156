import React, {useState} from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { palette } from '@features/preferences/style/themes';
import { StyledText, ModalView, ThemeUtil } from '@space/common';
import { View, Image, StyleSheet } from 'react-native';
import { localize, localizedName } from '../../localization';
import Close from '../../img/close.svg'
import { Language } from 'OrganizationFeature';
import { Space } from 'SpaceFeature';
import TagManager from 'react-gtm-module'
import { Booking } from 'BookingFeature';
import { event } from '../../utils/analysticsConstants'

type Props = {
  plan: string;
  activeLanguages: Language[];
  space: Space;
  spaceName: any;
  selected?: Booking | null;
  isFromMyReservation: boolean;
  isConfirmationView?: boolean;
};

const ModalOfficeMap: React.FC<Props> = ({ plan, activeLanguages, space, spaceName, selected, isFromMyReservation, isConfirmationView }) => {

    const [toggleModal, setToggleModal] = useState(false)

    const styleName = useTheme().mode;

    return(
      <View style={styles.mainContainer}>
        <TableMap 
          onPress={() => {
            setToggleModal(true);
            TagManager.dataLayer({
              dataLayer: {  
                event: event.GA4Map,
                button_label: `${localize("OFFICE_MAP")}`,
                current_page: `${selected && selected.id ? 'Edit' : 'Made'} a reservation`,
                step: (isFromMyReservation && !isConfirmationView) ? event.DashboardReservations : (isConfirmationView ? `4` : `2`),
                reservation_type: `${selected && selected.id ? 'Edit' : 'Made'} a reservation`,
              }
            })
          }}
          accessible={true}
          accessibilityLabel={localize('ACCESSIBILITY_OFFICE_MAP', {space: localizedName(space.name, activeLanguages)})}>
          <StyledText fontSize={16} fontWeight={'600'} lineHeight={'20px'} fontColor={palette.primary}>
            {localize("OFFICE_MAP")}
          </StyledText>
        </TableMap>
        <ModalView isVisible={toggleModal}>
          <ModalContainer>
            <HeaderContainer>
              <CloseContainer onPress={() => setToggleModal(false)}>
                <Close fill={ThemeUtil.getThemeValue(palette.tertiary)(styleName)}/>
              </CloseContainer> 
            </HeaderContainer> 
            <MainContainer>
              <Image style={styles.map} source={plan}/>
            </MainContainer>
          </ModalContainer>
        </ModalView>
      </View>
    )

}

const TableMap = styled.TouchableOpacity`
  font-family: "Barlow";
  display: inline-block;
`
const ModalContainer = styled.View`
  width: 100vw;
  max-width: 800px;
  padding: 0px 0px 32px 0px;
  background-color: ${palette.background};
`
const HeaderContainer = styled.View`
  flex-direction: row-reverse;
  padding: 24px 24px 0 24px;
  background: ${palette.background};
  position: sticky;
  top: 0;
  z-index: 2;
`;

const CloseContainer = styled.TouchableOpacity`
  width: 24px;
  height: 24px;
`;

const MainContainer = styled.View`
  justify-content: center;
  margin: 24px 0px;
  z-index: 1;
`

const styles = StyleSheet.create({
  map: {
    resizeMode: 'contain',
    height: '800px',
    width: '100%',
  },
  mainContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  }
})

export default ModalOfficeMap;