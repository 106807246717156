import {Platform, StyleSheet} from 'react-native';

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: Platform.OS === 'web' ? '100vh' : '100%',
  },
  title: {
    fontSize: 32,
  },
});

export {baseStyles};
