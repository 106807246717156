import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'StoreTypes';
import { palette, shadow, colors } from '@features/preferences/style/themes';
import styled, { css } from 'styled-components/native';
import theme from 'styled-theming';
import { Space, SpaceTag } from 'SpaceFeature';
import { BookingRequestBody } from 'BookingFeature';
import { StyledText, CircularIcon, Divider } from '@space/common';
import _ from 'lodash';
import { View, StyleSheet } from 'react-native';
import { localize, localizedName } from '../../localization';
import { Language } from 'OrganizationFeature';
import ModalOfficeMap from '@components/modal/ModalOfficeMap';
import Next from '../../img/next.svg'
import Pod from '../../img/pod.svg'
import Room from '../../img/room.svg'
import Seat from '../../img/seat.svg'
import {SpaceModel} from '@features/space';
import { handleForwardStep } from '@features/booking/actions';

type Props = {
  space: Space;
  spaceTags: SpaceTag[];
  booking: BookingRequestBody;
  activeLanguages: Language[];
  onPress: (space: Space) => void;
  plan: string;
};

const mapStateToProps = (state: RootState) => ({
  activeLanguages: state.organization.languages,
  selected: state.booking.selectedBooking,
});

const svgType = (spaceModel: SpaceModel) => {

  const props ={
    width: '32px',
    height: '32px',
    fill: colors.black
  }

  if(spaceModel === SpaceModel.POD){
    return <Pod {...props}/>
  } else if(spaceModel === SpaceModel.ROOM){
    return <Room {...props}/>
  } else if (spaceModel === SpaceModel.SEAT){
    return <Seat {...props}/>
  } else {
    return <Seat {...props}/>
  }
};  

const SpaceListItem: React.FC<Props> = ({ space, activeLanguages, booking, onPress, plan, selected }) => {

  const dispatch = useDispatch();

  const handleOnPress = () => {
    onPress(space);
    dispatch(handleForwardStep());
  };

  const model = space.type.model;
  const tags = space.tagsList.map((tag) => localizedName(tag.name, activeLanguages)).join(', ');

  return (
    <RootContainer>
      <ContentContainer>
        <View style={Styles.ViewContainer}>
          <View style={{flexDirection: 'row'}}>
            <SvgContainer>
              {svgType(model)}
            </SvgContainer>
            <SpaceDescriptionContainer>
              <SpaceNameContainer
                aria-label={localizedName(space.name, activeLanguages)}
              >
                <StyledText 
                  color={'primary'}
                  lineHeight={'100%'}
                  letterSpacing={'0.01em'}
                  variant={'h3'}>
                  {localizedName(space.name, activeLanguages)}
                </StyledText>
              </SpaceNameContainer>
              <TagsContainer>
                <StyledText 
                  color={'primary'} 
                  variant={'body1'}
                  lineHeight={'18px'}
                  letterSpacing={'0.01em'}>
                  {tags}
                </StyledText>
              </TagsContainer>
            </SpaceDescriptionContainer>
          </View>
          <ButtonContainer 
            onPress={handleOnPress}
            accessible={true}
            accessibilityLabel={localize('ACCESSIBILITY_SELECT_SPACE', {space: localizedName(space.name, activeLanguages)})}
            accessibilityHint={localize('SANITARY_CONDITION')}
            >
            <CircularIcon
              svg={Next}
              size={'xlarge'}
              variant={'fill'}
            />
          </ButtonContainer>
        </View>
        <Divider variant={'fullWidth'}/>
        <OfficeMapContainer>
          <ModalOfficeMap 
            plan={plan} 
            activeLanguages={activeLanguages} 
            space={space} 
            spaceName={localizedName(space.name, activeLanguages)}
            selected={selected}
            isFromMyReservation={false}/>
        </OfficeMapContainer>
      </ContentContainer>
    </RootContainer>
  );
};

const RootContainer = styled.View`
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0px 16px;
  margin-bottom: 16px;
`;

const ContentContainer = styled.View`
  align-items: flex-start;
  width: 100%;
  background-color: ${palette.common};
  border-radius: 16px;
  padding: 16px;
  box-shadow: ${shadow};
`;

const SvgContainer = styled.View`
  width: 32px;
  height: 32px;
`;

const SpaceNameContainer = styled.View`
  padding: 8px 0 0 0px;
`;


const TagsContainer = styled.View`
  padding: 4px 0 0 0;
  max-width: 215px;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.TouchableOpacity`
  justify-content: flex-start;
  align-items: flex-start;
  margin: -9px -9px 0 0;
`;

const SpaceDescriptionContainer = styled.View`
  flex: 1;
  margin: 0 0 16px 8px;
`;

const OfficeMapContainer = styled.View`
  margin-top: 16px;
`;

const Styles = StyleSheet.create({
  ViewContainer: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%'
  }
});

export default connect(mapStateToProps)(SpaceListItem);