import { RootEpic } from 'StoreTypes';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  fetchOrganizationAsync,
} from './actions';
import { defer } from 'rxjs';
import { Organization } from 'OrganizationFeature'

export const fetchOrganizationEpic: RootEpic = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchOrganizationAsync.request)),
    switchMap((action) =>
      defer(() => from(api.assistant.fetchOrganization(state$.value.login.user.organization.id))).pipe(
        map((data) => data as Organization),
        map(fetchOrganizationAsync.success),
        catchError((error) => of(fetchOrganizationAsync.failure(error))),
      ),
    ),
  );

