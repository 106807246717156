import {createAction, createAsyncAction} from 'typesafe-actions';
import {Message, CreateMessageQuery} from 'MessageFeature';

export const showUsersListView = createAction(
  'SHOW_USERS_LIST_VIEW',
  (value: boolean) => value,
)();

export const loadHomeMessageAsync = createAsyncAction(
  'LOAD_HOME_MESSAGE_REQUEST',
  'LOAD_HOME_MESSAGE_SUCCESS',
  'LOAD_HOME_MESSAGE_FAILURE',
)<string, Message, string>();
