import React, { useState, useRef, useEffect } from "react";
import { FlatList, Platform, StyleSheet} from 'react-native';
import styled from 'styled-components/native';
import { StyledText } from '@space/common';
import ForwardArrow from '../../img/forward_arrow.svg';
import _ from 'lodash';
import {
    colors,
    shadow
} from '@features/preferences/style/themes';
import SpacingView from '../SpacingView';
import TagManager from 'react-gtm-module'
import { event } from '../../utils/analysticsConstants'

export type SingleChoiceItem = {
    id: string;
    label: string;
};

type ViewProps = {
    width: number;
};

type ChoiceProps = {
    items: SingleChoiceItem[];
    selectedValue: string;
    onItemSelected: (selectedId: string) => void;
    width: number;
    category?: string
    action?: string
    label?: string
};

const ExpandableFilterSingleChoice: React.FC<ChoiceProps> = ({
    items,
    selectedValue,
    onItemSelected,
    width,
    category,
    action,
    label
}) => {
    const mainContainer = useRef();

    const [expanded, setExpanded] = useState(false);
    
    useEffect(() => {
        if (Platform.OS === 'web') {
            document.addEventListener("mousedown", handleClick);
            return () => {
                document.removeEventListener("mousedown", handleClick);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);   

    const handleClick = (event) => {
        if (mainContainer.current.contains(event.target)) {
            return;
        }
        setExpanded(false);
    };

    const onExpandPress = () => {
        setExpanded(!expanded);
    };

    const onCheckChange = (item: SingleChoiceItem) => {
        if (category && action) {
            TagManager.dataLayer({
                dataLayer: {
                    event: action,
                    current_page: category,
                    buttton_label: label,
                }
            })
        }
        onItemSelected(item.id);
        onExpandPress();
    }

    const renderTick = () => (
        <CheckedOnContainer />
    );

    const renderList = () => (
        <DropDownContainer width={width}>
            <FlatList
                keyExtractor={(item) => item.id}
                data={items}
                renderItem={({ item, index }) => (
                    <ItemContainer style={index + 1 !== items.length && styles.border}>
                        <TextContainer>
                            <StyledText variant={'h3'} fontColor={colors.grey4} letterSpacing='0.01rem'>
                                {item.label}
                            </StyledText>
                        </TextContainer>
                        <CheckedContainer onPress={() => onCheckChange(item)}>
                            {selectedValue === item.id ? renderTick() : null}
                        </CheckedContainer>
                    </ItemContainer>
                )}
            />
        </DropDownContainer>
    );

    return (
        <Container ref={mainContainer}>
            <ButtonView onPress={() => {
                onExpandPress();
                category && action && label && 
                TagManager.dataLayer({
                    dataLayer: {
                        event: action,
                        current_page: category,
                        buttton_label: label,
                    }
                })
            }}>
                <StyledText variant={'h3'} fontColor={colors.grey4} letterSpacing='0.01rem'>{_.first(items.filter((item) => item.id === selectedValue))?.label}</StyledText>
                <SpacingView width={'8px'} height={'8px'} />
                <ForwardArrow fill={colors.black}/>
            </ButtonView>
            {expanded ? renderList() : null}
        </Container>
    );
}

export default ExpandableFilterSingleChoice;

const Container = styled.View<ViewProps>`
    position: relative;
`;

const ItemContainer = styled.View`
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    flex-direction: row;
    min-height: 72px;
`;

const ButtonView = styled.TouchableOpacity`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 4px;
    height: 18px;
    padding: 0px 12px;
    flex-direction: row;
    align-items: center;
`;

const DropDownContainer = styled.View<ViewProps>`
    position: absolute;
    background-color: ${colors.white};
    box-shadow: ${shadow};
    border-radius: 16px;
    width: ${(props) => `${props.width}px` ?? '150px'};
    z-index: 10;
    padding: 0 24px;
    left: auto;
    right: -24px;
    bottom: auto;
    top: 45px;
`;

const TextContainer = styled.View`
    flex-grow: 1;
    flex-shrink: 1;
`;

const CheckedContainer = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 20px;
    height: 20px;
    border: 2px solid ${colors.black};
`;

const CheckedOnContainer = styled.TouchableOpacity`
    background-color: ${colors.black};
    border-radius: 6px;
    width: 10px;
    height: 10px;
`;

const styles = StyleSheet.create({
    border: {
         borderBottomColor: colors.border,
         borderBottomStyle: 'solid',
         borderBottomWidth: 1,
    },
});