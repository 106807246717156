import React from 'react';
import {StyleSheet} from 'react-native';
import styled from 'styled-components/native';
import { StyledText } from '@space/common';
import {colors, palette} from '@features/preferences/style/themes';
import {useTheme} from 'styled-components/native';
import { DateObject } from 'react-native-calendars';
import TagManager from 'react-gtm-module'
import { Booking } from 'BookingFeature';
import moment from 'moment';
import { event } from '../../utils/analysticsConstants'
import { localize } from '../../localization';

type DayProps = {
  state: string;
  marking: any;
  date: DateObject;
  occupation: number | null;
  onPress: (date: DateObject) => any;
  isBookingSelected?: Booking | null;
  isFromDashboard?: boolean;
};

const DayView: React.FC<DayProps> = ({
  state,
  marking,
  date,
  onPress,
  occupation,
  isBookingSelected,
  isFromDashboard
}) => {
  const onDayPress = () => onPress(date);

  const styleName = useTheme().mode;
  const { selected } = marking;
  const highlighted = state === 'today';

  return (
    <ItemContainer selected={selected} highlighted={highlighted}>
      <ItemTouchableOpacity
        selected={selected}
        highlighted={highlighted}
        disabled={occupation === null && state !== 'today'}
        onPress={() => {
          onDayPress();
          TagManager.dataLayer({
            dataLayer: {
              event: event.GA4SelectDate,
              current_page: `${isBookingSelected ? 'Edit' : 'Made'} a reservation`,
              button_label: `${localize('SELECTED_DATE')} - ${moment(date).locale(localize('LOCALE_LANGUAGE')).subtract(1, 'months').format(localize('DATE_ONLYDAY_FORMAT'))}`,
              step: `1`,
              reservation_type: `${isBookingSelected ? 'Edit' : 'Made'} a reservation`,
            }
          })
        }}>
        <StyledText
          variant={'caption'}
          fontSize={14}
          fontWeight={'600'}
          fontColor={
            selected
              ? styleName === 'dark'
                ? colors.black
                : colors.white
              : highlighted ? colors.black : 
              occupation === null ?
              palette.disable : colors.black
          }
          style={styles({occupation: occupation, selected: selected}).textItem}>
          {date.day}
        </StyledText>
        <IndicatorView occupation={occupation} selected={selected} accessible={false}/>
      </ItemTouchableOpacity>
    </ItemContainer>
  );
};

export default React.memo(DayView);

type DayViewProps = {
  selected: boolean;
  highlighted: boolean;
};

const ItemContainer = styled.View<DayViewProps>`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-bottom: 12px;
`;

const ItemTouchableOpacity = styled.TouchableOpacity<DayViewProps>`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${(props) =>
    props.selected ? palette.primary : props.highlighted ? palette.secondary : ''};
`;

type IndicatorViewProps = {
  selected: boolean;
  occupancy: number | null;
};

const IndicatorView = styled.TouchableOpacity<IndicatorViewProps>`
  align-self: flex-end;
  top: 0;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) => 
    props.occupancy === null
      ? ''
      : props.occupancy >= 100
      ? palette.error
      : props.occupancy > 75
      ? palette.warning
      : ''};
`;

const styles = (props) =>
  StyleSheet.create({
    textItem: {
      textAlign: 'center',
    },
  });
