import React, { useState, useEffect } from 'react';
import { TouchableOpacity, Linking, Platform, Dimensions, StyleSheet } from 'react-native';
import appConfig from '../../config';
import {connect} from 'react-redux';
import {RootState} from 'StoreTypes';
import { colors, palette, shadow } from '@features/preferences/style/themes';
import styled, { useTheme } from 'styled-components/native';
import { localize, localizedName } from '../../localization';
import { StyledText, Dialog, ThemeUtil, Divider } from '@space/common';
import Close from '../../img/close.svg';
import EmptyAvatar from '../../img/empty_avatar.svg';
import Delete from '../../img/delete.svg';
import { ThemeStyle } from '@features/preferences';
import { loadUserAsync, logoutAsync } from '@features/login/actions';
import { updateUserAsync, updateUserNoImageAsync, clearError } from "@features/user/actions";
import Avatar from '@components/Avatar';
import SpacingView from '@components/SpacingView';
import { UserRole, UserGroup } from 'UserFeature';
import StyledFileInput from "@components/StyledFileInput";
import _ from 'lodash';
import { Language } from 'OrganizationFeature';
import ExpandableFilterSingleChoice from '@components/form/ExpandableFilterSingleChoice';
import i18n from 'i18n-js';
import { AuthManager } from '@space/common';
import TagManager from 'react-gtm-module'
import { event } from '../../utils/analysticsConstants'

type UserAccountProps = {
    setShowUserAccount?: () => void;
};

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  theme: state.preferences.theme,
  activeLanguages: state.organization.languages,
  updateUserError: state.user.updateUserError,
});

const dispatchProps = {
    logout: logoutAsync.request,
    updateUser: updateUserAsync.request,
    updateUserNoImage: updateUserNoImageAsync.request,
    fetchUser: loadUserAsync.request,
    handleCloseErrorDialog: clearError,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & UserAccountProps & any;

const UserAccount: React.FC<Props> = ({
    user,
    activeLanguages,
    setShowUserAccount,
    logout,
    updateUser,
    updateUserError,
    handleCloseErrorDialog,
    updateUserNoImage,
    fetchUser
}) => {
    const [currentAvatar, setAvatar] = useState<File | string | null>(user.avatar);
    const [currentPreview, setPreview] = useState<string | ArrayBuffer | null>();
    const [preferredLanguage, setPreferredLanguage] = useState<string>(user.preferredLanguage?.id);
    const [fileSizeLimitExceeded, setFileSizeLimitExceeded] = useState(false);
    const authManager = AuthManager.getInstance();
    const styleName = useTheme().mode;
    
    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAvatar, currentPreview]);

    const onLogoutPressed = () => {
        authManager.signOut().then(() => logout());
        TagManager.dataLayer({
            dataLayer: {
                event: event.GA4Profile,
                current_page: event.GA4PageHome,
                button_label: `${localize('LOGOUT')}`,
            }
        })
    };

    const currentIdsRoles = user.rolesList?.map((role: UserRole) => {
        return role.id;
    });

    const currentIdsGroups = user.groupsList?.map((group: UserGroup) => {
        return group.id;
    });

    const onDeletePicturePress = () => {
        const updatedUser = { ...user, rolesList: currentIdsRoles.join(','), groupsList: currentIdsGroups.join(','), preferredLanguageId: user.preferredLanguage.id.toString(), avatar: null };
        updateUserNoImage(updatedUser)
        setPreview(null);
        setAvatar(null);
        TagManager.dataLayer({
            dataLayer: {
                event: event.GA4Profile,
                current_page: event.GA4PageHome,
                button_label: `${localize('REMOVE_PICTURE')}`,
            }
        })
    };

    const isEmptyAvatar = (avatar: File | string | null) => {
        if (avatar instanceof File) {
            return false;
        }

        return _.isEmpty(avatar) || avatar === 'undefined';
    }

    const updatePreferredLanguage = (selectedId: string) => {
        setPreferredLanguage(selectedId);
        if(selectedId != preferredLanguage) {
            const updatedUser = { ...user, rolesList: currentIdsRoles.join(','), groupsList: currentIdsGroups.join(','), preferredLanguageId: selectedId.toString() };
            updateUserNoImage(updatedUser);
            i18n.locale = _.first(activeLanguages.filter((lang: Language) => lang.id == selectedId)).name.split('-')[0];
        }
    }

    const onSelectFile = (file: File) => {
        setAvatar(file);
        const updatedUser = { ...user, rolesList: currentIdsRoles.join(','), groupsList: currentIdsGroups.join(','), preferredLanguageId: user.preferredLanguage.id.toString(), avatar: file };
        updateUser(updatedUser);
    }

    const renderDeleteContainer = () => {
        if (!isEmptyAvatar(user.avatar) || currentPreview || currentAvatar) {
            return <RemoveActionContainer onPress={onDeletePicturePress}>
                <StyledText variant={'body1'} fontColor={ThemeUtil.getThemeValue(palette.primary)(styleName)} fontWeight='600'> {localize('REMOVE_PICTURE')}</StyledText>
            </RemoveActionContainer>
        }
    }

    const renderPreview = () => {
        const size = 128;
        const avatarName = (currentAvatar instanceof File) ? currentAvatar.name : currentAvatar;
        return <AvatarImageContainer><img src={currentPreview} style={{ width: size, height: size, borderRadius: size / 2, objectFit: 'cover', boxShadow: ThemeUtil.getThemeValue(shadow)(styleName)}}
            alt={avatarName ? avatarName : localize('PROFILE_PICTURE')}
            height={size}
            width={size} />
        </AvatarImageContainer>
    };

    const renderAvatar = () => {
        if (!isEmptyAvatar(currentAvatar)) {
            const avatarName = (currentAvatar instanceof File) ? currentAvatar.name : currentAvatar;
            return <AvatarImageContainer>
                    <Avatar name={user?.firstName ?? ''} lastName={user?.lastName ?? ''} src={avatarName} size={'xxxlarge'} />
                </AvatarImageContainer>
        }

        return <AvatarEmptyContainer>
                <Avatar name={user.firstName} lastName={user.lastName} size={'xxxlarge'}/>
            </AvatarEmptyContainer>
    };

    const renderProfileAvatarForm = () => {
        return (
            <AvatarContainer>
                <StyledText variant={'h1'} color={'primary'}>{`${user.firstName}`}</StyledText>
                <StyledText variant={'h1'} color={'primary'}>{`${user.lastName}`}</StyledText>
                {currentPreview ? renderPreview() : renderAvatar()}
                <DownloadContainer>
                    <AvatarContent>
                        <DownloadActionContainer>
                            <StyledFileInput title={!isEmptyAvatar(user.avatar) || currentPreview || currentAvatar ? localize('EDIT_PICTURE') : localize('ADD_PICTURE')} 
                                onSelectFile={(file) => {
                                    onSelectFile(file);
                                }} onPreviewLoaded={(buffer) => {
                                    setPreview(buffer);
                                }} onFileSizeError={() => {
                                    setFileSizeLimitExceeded(true);
                                }}
                                category={event.GA4PageHome}
                                action={event.GA4Profile}
                                label={`${!isEmptyAvatar(user.avatar) || currentPreview || currentAvatar ? localize('EDIT_PICTURE') : localize('ADD_PICTURE')}`}
                            />
                        </DownloadActionContainer>

                        {renderDeleteContainer()}
                    </AvatarContent>
                </DownloadContainer>
            </AvatarContainer>
        );
    };

    const userTeams = user.groupsList?.map((group: UserGroup) => localizedName(group.name, activeLanguages)).join(', ');

    const isAuthorized = user.rolesList.map((role : UserRole) => localizedName(role.name, activeLanguages)).includes('Admin');

    const profileTypes = [
        {id: '1', label: localize('USER_LINK')}, 
        {id: '2', label: localize('ADMIN_LINK')}
    ];

    const updateProfileType = (selectedId: string) => {
        Linking.openURL(appConfig.urlAdmin.base).catch(err => console.error(localize('APP_ERROR_LINK'), err));
    }

    return (
        <>
            <CircularIconContainer onPress={() => setShowUserAccount(false)}>
                <Close fill={colors.black}/>
            </CircularIconContainer>
            <ProfileContainer>
                <SpacingView height={'97px'} />
                

                {renderProfileAvatarForm()}

                <StyledText variant={'h2'} fontColor={colors.grey4} style={styles.title}>{localize('PROFILE')}</StyledText>
                <PersonalInfoContainer>
                    {user.jobTitle && <PersonalInfo><StyledText variant={'body1'} fontColor={colors.grey9}>{localize('JOB_TITLE')}</StyledText>
                        <SpacingView width={'0'} height={'14px'} />
                        <StyledText variant={'h3'} fontColor={colors.grey4} letterSpacing='0.01rem'>{user.jobTitle}</StyledText>
                        </PersonalInfo>}

                    {userTeams && <PersonalInfo><StyledText variant={'body1'} fontColor={colors.grey9}>{localize('TEAM')}</StyledText>
                        <SpacingView width={'0'} height={'14px'} />
                        <StyledText variant={'h3'} fontColor={colors.grey4} letterSpacing='0.01rem'>{userTeams}</StyledText>
                        </PersonalInfo>}

                    {user.organization.name && <PersonalInfo><StyledText variant={'body1'} fontColor={colors.grey9}>{localize('ORGANIZATION')}</StyledText>
                        <SpacingView width={'0'} height={'14px'} />
                        <StyledText variant={'h3'} fontColor={colors.grey4} letterSpacing='0.01rem'>{user.organization.name}</StyledText>
                        </PersonalInfo>}

                    {user.phone && <PersonalInfo><StyledText variant={'body1'} fontColor={colors.grey9}>{localize('PHONE')}</StyledText>
                        <SpacingView width={'0'} height={'14px'} />
                        <StyledText variant={'h3'} fontColor={colors.grey4} letterSpacing='0.01rem'>{user.phone}</StyledText>
                        </PersonalInfo>}

                    {user.email && <PersonalInfoLast><StyledText variant={'body1'} fontColor={colors.grey9}>{localize('EMAIL')}</StyledText>
                        <SpacingView width={'0'} height={'14px'} />
                        <StyledText variant={'h3'} fontColor={colors.grey4} letterSpacing='0.01rem'>{user.email}</StyledText>
                        </PersonalInfoLast>}
                </PersonalInfoContainer>

                <SpacingView height={'48px'} />

                <StyledText variant={'h2'} fontColor={colors.grey4} style={styles.title}>{localize('ADMIN_MENU_SETTINGS')}</StyledText>
                <PreferencesContainer>
                    {isAuthorized &&
                        <SwitchProfile>
                            <StyledText variant={'body1'} fontColor={colors.grey9}>{localize('PROFILE_TYPE')}</StyledText>
                            <SpacingView width={'0'} height={'10px'} />
                            <ExpandableFilterSingleChoice
                                category={event.GA4PageHome}
                                action={event.GA4Profile}
                                label={localize('PROFILE_TYPE')}
                                width={343}
                                items={profileTypes}
                                onItemSelected={updateProfileType}
                                selectedValue={'1'}/>
                        </SwitchProfile>
                    }
                    <LanguageChoiceContainer>
                        <StyledText variant={'body1'} fontColor={colors.grey9}>{localize('LANGUAGE')}</StyledText>
                        <SpacingView width={'0'} height={'10px'} />
                        <ExpandableFilterSingleChoice
                            category={event.GA4PageHome}
                            action={event.GA4Profile}
                            label={localize('LANGUAGE')}
                                width={343}
                                items={activeLanguages.map((language: Language) => {
                                    return {
                                        id: language.id,
                                        label: localize(language.name),
                                    }})}
                                onItemSelected={updatePreferredLanguage}
                                selectedValue={preferredLanguage}/>
                    </LanguageChoiceContainer>
                    
                    {/* <View>
                        <StyledText variant={'caption'}>{localize('DARK_MODE')}</StyledText>
                        <SpacingView width={'0'} height={'10px'} />
                        <ThemeSwitch />
                    </View> */}
                </PreferencesContainer>

                <LogoutContainer onPress={onLogoutPressed}>
                    <StyledText variant={'button'} color={'primary'} fontColor={ThemeUtil.getThemeValue(palette.primary)(styleName)} fontWeight='600'>{localize('LOGOUT')}</StyledText>
                </LogoutContainer>

                {updateUserError && (
                    setShowUserAccount(false),
                    <Dialog
                    isOpen={updateUserError !== null}
                    title={localize('APP_ERROR_TITLE')}
                    body={updateUserError}
                    onClose={handleCloseErrorDialog}
                    closeIcon={true}
                />)}

                {fileSizeLimitExceeded && (
                    setShowUserAccount(false),
                    <Dialog
                    isOpen={fileSizeLimitExceeded}
                    title={localize('APP_ERROR_TITLE')}
                    body={localize('IMAGE_LIMIT')}
                    onClose={() => setFileSizeLimitExceeded(false)}
                    closeIcon={true}
                />)}
            </ProfileContainer>
        </>
    );
};

export default connect(mapStateToProps, dispatchProps)(UserAccount);

const ProfileContainer = styled.ScrollView`
    height: ${Platform.OS === 'web' ? '100vh' : `${Dimensions.get('screen').height}px`};
    width: ${Platform.OS === 'web' ? '100vw' : `${Dimensions.get('screen').width}px`};
    background-color: ${palette.background};
`;

const CircularIconContainer = styled.TouchableOpacity`
    background: ${colors.white};
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    margin: 16px;
    box-shadow: ${shadow};
    position: fixed;
    z-index: 1;
`;

const AvatarContainer = styled.View`
    min-height: 150px;
    padding: 0 24px 24px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 768px;
`;

const AvatarContent = styled.View`
    justify-content: space-around;
`;

const DownloadActionContainer = styled.TouchableOpacity`
  max-width: 263px;
  width: 263px;
  background: ${palette.primary};
  border-radius: 32px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const RemoveActionContainer = styled.TouchableOpacity`
  max-width: 263px;
  width: 263px;
  background: transparent;
  border-radius: 32px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  color: ${palette.primary};
  border: 1px solid ${palette.primary};
`;

const DownloadContainer = styled.View`
    flex-direction: row;
`;

const AvatarEmptyContainer = styled.View`
    min-width: 128px;
    min-height: 128px;
    width: 128px;
    height: 128px;
    border-radius: 4rem;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
`;

const AvatarImageContainer = styled.View`
    position: relative;
    margin: 16px 0;
`;

const PersonalInfoContainer = styled.View`
    width: 343px;
    max-width: 768px;
    min-height: 100px;
    background: white;
    border-radius: 16px;
    margin: 0 auto;
    padding: 4px 16px;
    box-shadow: ${shadow};
`;

const PersonalInfo = styled.View`
    min-height: 56px;
    padding: 24px 0;
    border-bottom-color: ${palette.border};
    border-bottom-style: solid;
    border-bottom-width: 1px;
`;

const PersonalInfoLast = styled.View`
    min-height: 56px;
    padding: 24px 0;
`;

const SwitchProfile = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 72px;
    align-items: center;
    border-bottom-color: ${palette.border};
    border-bottom-style: solid;
    border-bottom-width: 1px;
`;

const LanguageChoiceContainer = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 72px;
    align-items: center;
    z-index: -1;
`;

const PreferencesContainer = styled.View`
    width: 343px;
    max-width: 768px;
    margin: 0 auto 20px;
    background: ${colors.white};
    box-shadow: ${shadow};
    border-radius: 16px;
    padding: 0 16px;
    z-index: 1;
`;

const LogoutContainer = styled.TouchableOpacity`
    max-width: 263px;
    width: 263px;
    background: transparent;
    border-radius: 32px;
    height: 48px;
    justify-content: center;
    align-items: center;
    margin: 20px auto 40px;
    color: ${palette.primary};
    border: 1px solid ${palette.primary};
`;

const styles = StyleSheet.create({
    avatarTitle: {
      maxWidth: '263px',
      textAlign: 'center',
    },
    title: {
        width: '343px',
        marginTop: 6,
        marginBottom: 24,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
});
