import {ThemeStyle} from '.';
import {createAction} from 'typesafe-actions';

export const applyTheme = createAction(
  'APPLY_THEME',
  (style: ThemeStyle) => style,
)();

export const setLanguage = createAction(
  'SET_LANGUAGE',
  (language: string) => language,
)();

