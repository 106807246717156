import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';
import i18n from 'i18n-js';
import {Message} from 'MessageFeature';
import {
  showUsersListView,
  loadHomeMessageAsync,
} from './actions';

const reducer = combineReducers({
  userListIsDisplayed: createReducer<boolean>(false).handleAction(
    [showUsersListView],
    (state, action) => {
      return action.payload;
    },
  ),
  welcomeMessage: createReducer<string | null>(null).handleAction(
    loadHomeMessageAsync.success,
    (state, action) => action.payload?.content.find((item) => item.language.split('-')[0] === i18n.locale)?.message ?? action.payload?.content[0]?.message ?? null,
  ),
});

export default reducer;
