import React, { useMemo, useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { RootState } from 'StoreTypes';
import { connect, useDispatch } from 'react-redux';
import Calendar from '@components/Calendar/Calendar';
import {
  setSelectedSpaceType,
  selectBooking,
  setSelectedDate,
  resetBookingDeleted
} from '@features/booking/actions';
import { hideModalView } from '@features/modal/actions';
import ModalHeader from '@components/modal/ModalHeader';
import AvailabilityListItem from '@components/AvailabilityListItem';
import { FlatList } from 'react-native-gesture-handler';
import SpaceHelper from '../utils/SpaceHelper';
import moment from 'moment';
import { colors, shadow } from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import { BookingSpaceViewName } from './BookingSpaceView';
import AvatarCarousel from '@components/AvatarCarousel';
import { showUsersListView } from '@features/home/actions';
import { Booking } from 'BookingFeature';
import _ from 'lodash';
import config from '../config';
import { Divider, StyledText } from '@space/common';
import { User } from 'UserFeature';
import { localize, localizedName } from '../localization';
import SvgIcon from '@components/SvgIcon';
import { Space, SpaceGroup } from 'SpaceFeature';
import { SpaceModel } from '@features/space';
import { Language } from 'OrganizationFeature';
import SpacingView from '@components/SpacingView';
import CalendarSvg from '../img/calendar.svg'
import Pod from '../img/pod.svg'
import Room from '../img/room.svg'
import Seat from '../img/seat.svg'
import { handleForwardStep } from '@features/booking/actions';
import TagManager from 'react-gtm-module'
import { event } from '../utils/analysticsConstants'
import { loadBookingAsync } from '@features/booking/actions';
import { fetchSpacesAsync } from '@features/space/actions';

const mapStateToProps = (state: RootState) => ({
  selected: state.booking.selectedBooking,
  currentUserBookings: state.booking.currentUserBookings,
  bookings: state.booking.bookings,
  spaceList: state.space.space,
  availableSpacesCache: state.space.availableSpacesCache,
  spaceGroups: state.space.spaceGroups,
  selectedDate: state.booking.selectedDate,
  isConfirmBookingDeleteViewDisplayed: state.booking.isConfirmBookingDeleteViewDisplayed,
  bookingDeleted: state.booking.bookingDeleted,
  activeLanguages: state.organization.languages,
  user: state.login.user,
  officeOpenCache: state.organization.officeOpenCache,
});

const dispatchProps = {
  selectBooking: selectBooking,
  hideModalView: hideModalView,
  selectSpaceType: setSelectedSpaceType,
  showUsersList: showUsersListView,
  selectDate: setSelectedDate,
  resetBookingDeleted: resetBookingDeleted,
  fetchBookings: loadBookingAsync.request,
  fetchSpaces: fetchSpacesAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const BookingView: React.FC<Props> = ({
  selected,
  selectBooking,
  currentUserBookings,
  spaceList,
  availableSpacesCache,
  spaceGroups,
  selectedDate,
  selectSpaceType,
  navigation,
  showUsersList,
  bookings,
  selectDate,
  bookingDeleted,
  resetBookingDeleted,
  activeLanguages,
  user,
  officeOpenCache,
  fetchBookings,
  fetchSpaces
}) => {

  const groupids = selected && selected.spaces.map((space: Space) => space.groupId);
  const groups = groupids && spaceGroups.filter((group: SpaceGroup) => groupids.includes(group.id));
  const [multipleBookingAlert, setMultipleBookingAlert] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const startDate = moment().utc().format(config.defaultDateFormat);
      const endDate = moment().utc().add(3, 'w').format(config.defaultDateFormat);
  
      fetchBookings({ startDate, endDate });
      fetchSpaces({ startDate, endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingDeleted) {
      selectBooking(null);
      resetBookingDeleted();
      navigation.goBack();
    }
  }, [bookingDeleted]);

  const svgType = (spaceModel: SpaceModel) => {
    const props = {
      width: 24,
      height: 24,
      fill: colors.black
    }

    if(spaceModel === SpaceModel.POD){
      return <Pod {...props}/>
    } else if(spaceModel === SpaceModel.ROOM){
      return <Room {...props}/>
    } else if (spaceModel === SpaceModel.SEAT){
      return <Seat {...props}/>
    } else {
      return <Seat {...props}/>
    }
  };  

  const users: User[] = useMemo(
    () =>
      _.uniqBy(
        bookings.reduce((result: User[], item: Booking) => {
          if (
            selectedDate ===
            moment(item.schedule.startDate).format(config.defaultDateFormat)
          ) {
            return result.concat(item.users);
          }
          return result;
        }, []),
        (item) => item.email,
      ),
    [bookings, selectedDate],
  );

  const generateTitle = (booking: Booking) => {
    return moment(booking.schedule.startDate).format(config.defaultDateFormat) ===
      moment().format(config.defaultDateFormat)
      ? localize('BOOKING_TODAY_DATE', { date: moment(booking.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(config.monthdayFormat) })
      : moment(booking.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(config.weekdayFormat);
  };
  
  const generateSpacesName = (booking: Booking, activeLanguages: Language[], groups?: SpaceGroup[]) => {
    const bookingType = booking.spaces[0]?.type;
    if (bookingType.model === SpaceModel.POD) {
      return groups?.map((group, index) => {
        return index === 0 ? localizedName(group.name, activeLanguages) : ', ' + localizedName(group.name, activeLanguages);
      });
    } else {
      return booking.spaces.map((space, index) => {
        return index === 0 ? localizedName(space.name, activeLanguages) : ', ' + localizedName(space.name, activeLanguages);
      });
    }
  };
  
  const generateScheduleHours = (booking: Booking) => {
    return `${moment(booking.schedule.startDate).format(config.hoursFormat)} - ${moment(booking.schedule.endDate).format(config.hoursFormat)}`;
  };
  
  const onDaySelect = (date: string) => {
    selectDate(date)
  };

  const renderItem = ({ item }) => {
    const findUserBooking = bookings.filter((booking: Booking) => {
      return _.first(booking.users)?.id === user.id
    })
    const bookingConditionCheck = findUserBooking.find((booking: Booking) => {
    return _.first(booking.spaces).type.id === item.type.id &&
      moment(booking.schedule.startDate).format(config.defaultDateFormat) === selectedDate
    })
    
    return (
      <StyledFlatItem
        disabled={item.availability === 0}
        onPress={() => {
          selectDate(selectedDate);
          selectSpaceType(item.type);
          bookingConditionCheck && !selected? setMultipleBookingAlert(true) : navigation.navigate(BookingSpaceViewName);
          setMultipleBookingAlert(bookingConditionCheck ? true : false);
          bookingConditionCheck && !selected ? "" : dispatch(handleForwardStep());
          TagManager.dataLayer({
            dataLayer: {
              event: selected ? `${event.GA4EditBooking}` : `${event.GA4BookSpace}`,
              button_label:`${selected ? 'Edit' : 'Made'} a reservation - Step 1`,
              current_page:event.GA4PageHome,
              date_selected:`${moment(selectedDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_ONLYDAY_FORMAT'))}`,
            }
          })
        }}>
        <AvailabilityListItem
          description={item.availability + ' ' + localizedName(item.name, activeLanguages)}
          model={item.type?.model}
          rightIcon={SvgIcon.NEXT}
          disabled={item.availability === 0}
          userBookings={findUserBooking}
          navigation={navigation}
          multipleBookingAlert={multipleBookingAlert}
          setMultipleBookingAlert={setMultipleBookingAlert}
          selected={selected}
        />
      </StyledFlatItem>
    );
  };

  const bookingItem = () => {
    const currentUserBooking = currentUserBookings.find((item: Booking) => item.id === selected.id);
    const currentBooking = currentUserBooking ? currentUserBooking : selected;

    return (
      <StickyContainer>
        <TitleContainer>
          <StyledText
            variant={'h3'}
            fontColor={colors.black}
            fontWeight={'600'}
            letterSpacing={'0.01em'}>
            {localize('RESERVATION_CURRENT')}
          </StyledText>
        </TitleContainer>
        <Divider variant={'fullWidth'}/>
        <View style={{flexDirection: 'row', paddingTop: '24px'}}>
        <CalendarSvg width={24} height={24}/>
        <CalendarDescriptionContainer>
        <StyledText
            variant={'body1'}
            fontWeight={'600'}
            fontColor={colors.black}
            letterSpacing={'0.01em'}>
            {generateTitle(currentBooking)}
        </StyledText>
        <SpacingView height={'4px'} />
        <StyledText variant={'body4'} color={'hint'}>
          {localize('BOOKING_SUBTITLE')}
        </StyledText>
        </CalendarDescriptionContainer>
        </View>
        <SpaceDescriptionContainer>
          {svgType(_.first(currentBooking.spaces).type.model)}
          <SpaceNameContainer>
            <StyledText variant={'body1'} fontWeight={'600'} fontColor={colors.black} letterSpacing={'0.01em'}>
              {generateSpacesName(currentBooking, activeLanguages, groups)}
            </StyledText>
          </SpaceNameContainer>
        </SpaceDescriptionContainer>
      </StickyContainer>
    );
  };

  return (
    <StyledBackground>
      <ModalHeader
        leftAction={'back'}
        onPress={() => {
          selectBooking(null);
          navigation.goBack();
        }}
        title={selected ? localize('UPDATE_BOOKING') : localize('NEW_BOOKING')}
      />
      <ScrollViewContainer>
        <StyledCalendarContainer>
          <Calendar displayWeekView={false} onDateSelect={onDaySelect} />
        </StyledCalendarContainer>

        <AvatarCarouselContainer>
          <AvatarTitleContainer>
            <StyledText 
              variant={'h2'} 
              fontSize={24}  
              fontColor={colors.grey4}
              letterSpacing={'0.01em'}
              lineHeight={'20px'}>
              {localize('CARROUSEL_TITLE')}
            </StyledText>
          </AvatarTitleContainer>
          <StyledContainer>
            <AvatarCarousel
              users={users}
              onPress={(_usersItem) => {
                showUsersList(true);
              }}
              reloadData={false}
            />
          </StyledContainer>
        </AvatarCarouselContainer>

          <AvailableSpaceListContainer>
            <View>
              <StyledText 
                variant={'h2'} 
                fontSize={24} 
                fontColor={colors.grey4}
                letterSpacing={'0.01em'}
                lineHeight={'20px'}>
                {localize('AVAILABLE_BOOKING_HEADER')}
              </StyledText>
            </View>
            {!_.isEmpty(SpaceHelper.isOfficeOpened(selectedDate, officeOpenCache, spaceList, availableSpacesCache)) ?
              <FlatList
                data={SpaceHelper.availableSpacesPerType(
                  spaceList,
                  availableSpacesCache,
                  spaceGroups,
                  moment(selectedDate),
                )}
                extraData={selectedDate}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
              /> :
              <StyledText
                style={styles.closedDay}
                variant={'h3'} 
                fontColor={colors.destructive}>
                {localize('CLOSED_DAY')}
              </StyledText>
            }
          </AvailableSpaceListContainer>
      </ScrollViewContainer>
      {selected && bookingItem()}
    </StyledBackground>
  );
};

const StyledBackground = styled.View<any>`
  background-color: ${(props) => props.theme.palette.background};
  width: 100%;
  height: 100vh;
  align-items: center;
  margin: 0px;
`;

const ScrollViewContainer = styled.ScrollView`
  width: 100%;
  padding-bottom: 150px;
`;

const StyledFlatItem = styled.TouchableOpacity`
  width: 100%;
  align-items: center;
  margin: 16px 0 0 0;
`;

const AvatarTitleContainer = styled.View`
  padding-bottom: 16px;
`;

const AvatarCarouselContainer = styled.View`
  max-width: 343px;
  width: 100%;
  align-self: center;
  padding: 48px 0;
`;

const AvailableSpaceListContainer = styled.View`
  background-color: ${(props) => props.theme.palette.background};
  margin: 0px 0px 70px 0px;
  width: 100%;
  max-width: 343px;
  align-self: center;
`;

const BookingContainer = styled.View`
  background-color: ${(props) => props.theme.palette.background};
  width: 100%;
  padding: 0px 24px 0px 24px;
  margin-bottom: 24px;
  max-width: 327px;
`;

const BookingItemContainer = styled.View`
  width: 100%;
  align-items: center;
  margin: 12px 0px 32px 0px;
`;

const StyledCalendarContainer = styled.View`
  max-width: 343px; 
  width: 100%;
  align-self: center;
`;

const StyledContainer = styled.View`
  width: 100%;
  height: 88px;
  max-width: 343px;
  padding: 0px 16px;
  background-color: ${(props) => props.theme.palette.common};
  align-items: center;
  justify-content: center;
  box-shadow: ${shadow};
  border-radius: 16px;
`;

const VerticalSpace = styled.View`
  width: 100%;
  height: 24px;
`;

const StickyContainer = styled.View`
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  z-index: 1;
  background: white;
  width: 100%;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 0 24px 24px 24px;
`;

const TitleContainer = styled.View`
  height: 32px;
  width: 100%;
  margin: 16px 0 16px 0;
  align-items: center;
  justify-content: center;
`;

const CalendarDescriptionContainer = styled.View`
  margin: 5px 0 0 8px;
`;

const SpaceDescriptionContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const SpaceNameContainer = styled.View`
  margin: 0 0 0 8px;
`;

const styles = StyleSheet.create({
  closedDay: {
    padding: '30px',
  }
});

export const BookingViewName = 'BookingView';

export default connect(mapStateToProps, dispatchProps)(BookingView);
