import React from 'react';
import { StyleSheet, FlatList, TouchableOpacity, View } from 'react-native';
import { User } from 'UserFeature';
import Avatar from './Avatar';
import { StyledText, ThemeUtil } from '@space/common';
import {
  colors,
  palette,
} from '@features/preferences/style/themes';
import Checked from '../img/checked.svg';
import styled, { useTheme } from 'styled-components/native';
import theme from 'styled-theming';
import UserBookingSpaces from './user/UserBookingSpaces'
import _ from 'lodash';

type AvatarListProps = {
  list: User[];
  selectedUserId?: string;
  onItemSelected?: (user?: User) => void;
};

const tickColor = theme('mode', {
  light: colors.grey6,
  dark: colors.white,
});

const AvatarList: React.FC<AvatarListProps> = ({
  list,
  selectedUserId,
  onItemSelected,
}) => {
  const styleName = useTheme().mode;
  const color = ThemeUtil.getThemeValue(tickColor)(styleName);

  const renderTick = (color: string) => (
    <StyledChecked>
      <Checked fill={color} width={24} height={24} />
    </StyledChecked>
  );

  const renderItem = (user: User, color: any, selectedUserId?: string) => (
    <ItemContainer>
      <Avatar name={user.firstName} lastName={user.lastName} src={user.avatar} />
      <StyledSpace />
      <View>
        <StyledText variant={'body2'} fontColor={palette.text['primary']}>
          {user.firstName + ' ' + user.lastName}
        </StyledText>
        <UserBookingSpaces user={user}/>
      </View>
      {selectedUserId && user.id === selectedUserId ? renderTick(color) : null}
    </ItemContainer>
  );

  return (
    <Container>
      <FlatList
        data={list}
        contentContainerStyle={Styles.contentContainer}
        style={Styles.list}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => (onItemSelected ? onItemSelected(item) : null)}>
            {renderItem(item, color, selectedUserId)}
          </TouchableOpacity>
        )}
      />
    </Container>
  );
};

export default AvatarList;

const Container = styled.ScrollView`
  width: 100%;
  max-height: 668px;
  background-color: ${palette.common};
`;

const ItemContainer = styled.View`
  flex-direction: row;
  padding-bottom: 16px;
`;

const StyledSpace = styled.View`
  width: 16px;
`;

const StyledChecked = styled.View`
  margin: 0px 25px;
`;

const Styles = StyleSheet.create({
  image: {
    width: 24,
    height: 24,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 24,
  },
  list: {
    marginTop: 12,
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    marginTop: 12,
  },
  titleText: {
    marginTop: 24,
    marginLeft: 32,
  },
});
