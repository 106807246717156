import React from 'react';
import {useTheme} from 'styled-components/native';
import Pod from '../img/pod.svg';
import Seat from '../img/seat.svg';
import Room from '../img/room.svg';
import SelectUser from '../img/select_user.svg';
import Booking from '../img/booking.svg';
import Close from '../img/close.svg';
import Check from '../img/checked.svg';
import Add from '../img/add_booking.svg';
import Next from '../img/next.svg';
import EmptyAvatar from '../img/empty_avatar.svg';
import Up from '../img/up.svg';
import Down from '../img/down.svg';

import {
  palette,
} from '@features/preferences/style/themes';
import { SvgProps } from 'react-native-svg';
import { ThemeUtil } from '@space/common';

export enum SvgType {
  POD = 'pod',
  SEAT = 'seat',
  ROOM = 'room',
  SELECT_USER = 'selectUser',
  BOOKING = 'booking',
  CLOSE = 'close',
  CHECK = 'check',
  ADD = 'add',
  NEXT = 'next',
  EMPTY_AVATAR = 'emptyAvatar',
  UP = 'up',
  DOWN = 'down'
}

type SvgIconProps = {
  svg: React.FC<SvgProps>;
  fillColor?: string;
  width?: number;
  height?: number;
  inverted?: boolean;
};

const SvgIcon: React.FC<SvgIconProps> = ({
  svg,
  fillColor,
  width,
  height,
  inverted,
}) => {
  const styleName = useTheme().mode;
  const props = {
    fill:
      fillColor ? fillColor : inverted
        ? ThemeUtil.getInvertedThemeValue(palette.common)(styleName)
        : ThemeUtil.getThemeValue(palette.common)(styleName),
    width: width ?? 24,
    height: height ?? 24,
  };
  
  switch (svg) {
    case SvgType.ADD:
      return <Add {...props} />
    case SvgType.POD:
      return <Pod {...props} />;
    case SvgType.SEAT:
      return <Seat {...props} />;
    case SvgType.ROOM:
      return <Room {...props} />;
    case SvgType.SELECT_USER:
      return <SelectUser {...props} />;
    case SvgType.BOOKING:
      return <Booking {...props} />;
    case SvgType.CLOSE:
      return <Close {...props} />;
    case SvgType.CHECK:
      return <Check {...props} />;
    case SvgType.NEXT:
      return <Next {...props} />;
    case SvgType.EMPTY_AVATAR:
      return <EmptyAvatar {...props} />;
    case SvgType.UP:
      return <Up {...props} />;
    case SvgType.DOWN:
      return <Down {...props} />;
    default:
      return <Seat {...props} />;
  }
};

export default SvgIcon;
