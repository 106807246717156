import React, {useContext, useEffect} from 'react';
import {RootState} from 'StoreTypes';
import {connect} from 'react-redux';
import {StyledText} from '@space/common';
import styled from 'styled-components/native';
import _ from 'lodash';
import {StyleSheet, View} from 'react-native';
import { localize } from '../localization';
import SupportSVG from '../img/support_agent.svg'
import {palette} from '@features/preferences/style/themes';

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  welcomeMessage: state.home.welcomeMessage,
  isAuthenticated: state.login.isAuthenticated,
});

const renderWelcomeUser = (user) => (
  <StyledText variant={'h1'} color={'primary'} style={styles.userName} lineHeight={'100%'}>
    {localize('GOOD_MORNING', {name: user.firstName})}
  </StyledText>
);

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const WelcomeView: React.FC<Props> = ({
  user, 
  welcomeMessage, 
  isAuthenticated, 
  loadMessage
}) => {

  return (
    <Container>
      {_.isEmpty(user) ? null : renderWelcomeUser(user)}
      <MessageContainer>
        <TopContainer>
          <SupportSVG/>
          <StyledText variant={'h3'}  color={'primary'} style={styles.title} letterSpacing={'0.01em'}>
            {localize('MESSAGE')}
          </StyledText>
        </TopContainer>
        <BottomContainer>
          <StyledText
            variant={'body1'}
            color={'primary'}>
            {welcomeMessage ? welcomeMessage : 
            <WelcomeQuote>
              <View style={styles.firstQuote}>
                <StyledText variant={'body1'} lineHeight={'20px'}>
                  {localize("FIRST_WELCOME_QUOTE")}
                </StyledText>
              </View>
              <View>
                <StyledText variant={'body1'} lineHeight={'20ox'}>
                  {localize("SECOND_WELCOME_QUOTE")}
                </StyledText>
              </View>
            </WelcomeQuote>
            }
          </StyledText>
        </BottomContainer>
      </MessageContainer>
      </Container>
  );
};

const Container = styled.View`
  max-width: 343px;
  justify-content: center;
  align-items: center;
  padding: 0 0 22px 0;
`;

const MessageContainer = styled.View`
  width: 100%;
  background: ${palette.common};
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid ${palette.border};
  padding: 16px;
  margin-top: 24px;
`;

const TopContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const BottomContainer = styled.View`
  margin-top: 8px;
`;

const WelcomeQuote = styled.View`
  flex-direction: column;
`;

const styles = StyleSheet.create({
    title: {
      paddingLeft: '8px'
    },
    firstQuote: {
      marginBottom: '25px'
    },
    userName: {
      textAlign: 'center'
    }
  });

export default React.memo(connect(mapStateToProps, null)(WelcomeView));
