import React, {useContext} from 'react';

import {View, Text} from 'react-native';
import {baseStyles} from '../../styles';
import Menu from '../../admin/component/Menu';
import { localize } from '../../localization';

const AdminBookingsView: React.FC = () => {
  return (
    <View style={baseStyles.container}>
      <Text style={baseStyles.title}>{localize('ADMIN_MENU_BOOKINGS')}</Text>
      <Menu />
    </View>
  );
};

export const AdminBookingsViewName = 'AdminBookingsView';

export default AdminBookingsView;
