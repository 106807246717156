import {createAsyncAction, createAction} from 'typesafe-actions';
import {Space, SpaceQuery, SpaceGroup, SpaceTag} from 'SpaceFeature';

export const fetchSpacesAsync = createAsyncAction(
  'LOAD_SPACES_REQUEST',
  'LOAD_SPACES_SUCCESS',
  'LOAD_SPACES_FAILURE',
)<SpaceQuery, Space[], string>();

export const fetchSpaceGroupsAsync = createAsyncAction(
  'LOAD_SPACE_GROUPS_REQUEST',
  'LOAD_SPACE_GROUPS_SUCCESS',
  'LOAD_SPACE_GROUPS_FAILURE',
)<void, SpaceGroup[], string>();

export const fetchSpaceTagsAsync = createAsyncAction(
  'LOAD_SPACE_TAGS_REQUEST',
  'LOAD_SPACE_TAGS_SUCCESS',
  'LOAD_SPACE_TAGS_FAILURE',
)<void, SpaceTag[], string>();

export const cacheOccupancy = createAction('CACHE_SPACE_OCCUPANCY', () => { })();

