import React from 'react';
import {Text} from 'react-native';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore, { history } from './store';
import { ThemeProvider } from '@space/common';
import { ConnectedRouter } from 'connected-react-router';
import MainNavigationStack from './MainNavigationStack';
import { configureLocalization } from './localization';
import { colors, palette } from './features/preferences/style/themes';
import {RootState} from 'StoreTypes';
import { useFonts, Outfit_400Regular, Outfit_500Medium, Outfit_600SemiBold, Outfit_700Bold, Outfit_800ExtraBold} from '@expo-google-fonts/outfit';
import { FrankRuhlLibre_700Bold } from '@expo-google-fonts/frank-ruhl-libre';

export const store = configureStore();

configureLocalization();

export default function App() {
  let [fontsLoaded] = useFonts({
    Outfit_400Regular,
    Outfit_500Medium,
    Outfit_600SemiBold,
    Outfit_700Bold,
    Outfit_800ExtraBold,
    FrankRuhlLibre_700Bold,
  })

  if (!fontsLoaded) {
    return null;
  }

  return (
    <Provider store={store.store}>
      <PersistGate loading={<Text>loading</Text>} persistor={store.persistor}>
        <ConnectedRouter history={history}>
          <ThemedApp />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export const ThemedApp: React.FC = () => {
  const mode = useSelector((state: RootState) => state.preferences.theme);

  const theme = {
    mode: mode,
    palette: palette,
    color: colors
  }

  return (
    <ThemeProvider theme={theme}>
      <MainNavigationStack />
    </ThemeProvider>
  );
}