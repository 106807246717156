import Constants from 'expo-constants';

const officeId = window.location.origin?.replace(/(^\w+:|^)\/\//, '').split('.')[0].split('-')[0];

const local = {
    url: {
        server: 'http://localhost:8081',
        redirect: 'http://localhost:19006/login',
        base: 'http://localhost:19006'
    },
    urlAdmin : {
        redirect: 'http://localhost:19007/login',
        base: 'http://localhost:19007'
    },
    officeId: 'mtl',
    officeMapImagesUrl: 'https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/dev/map',
};


const dev = {
    url: {
        server: 'https://api.dev.assistant.nurunmtl.com',
        redirect: `${window.location.origin}/login`,
        base: window.location.origin
    },
    urlAdmin : {
        redirect: `https://${officeId}-admin.dev.assistant.nurunmtl.com/login`,
        base: `https://${officeId}-admin.dev.assistant.nurunmtl.com`
    },
    officeMapImagesUrl: 'https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/dev/map',
};

const qa = {
    url: {
        server: 'https://api.qa.assistant.nurunmtl.com',
        redirect: `${window.location.origin}/login`,
        base: window.location.origin
    },
    urlAdmin : {
        redirect: `https://${officeId}-admin.qa.assistant.nurunmtl.com/login`,
        base: `https://${officeId}-admin.qa.assistant.nurunmtl.com`
    },
    officeMapImagesUrl: 'https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/qa/map',
};

const prod = {
    url: {
        server: 'https://api.flexableapp.com',
        redirect: `${window.location.origin}/login`,
        base: window.location.origin
    },
    urlAdmin : {
        redirect: `https://${officeId}-admin.flexableapp.com/login`,
        base: `https://${officeId}-admin.flexableapp.com`
    },
    officeMapImagesUrl: 'https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/prod/map',
};

const config = () => {
    switch (process.env.REACT_NATIVE_STAGE) {
        case 'local':
            return local;
        case 'dev':
            return dev;
        case 'qa':
            return qa;
        default:
            return prod;
    }
};

export default {
    defaultDateFormat: 'YYYY-MM-DD',
    dayFormat: 'dddd DD MMM YYYY',
    monthdayFormat: 'DD MMM',
    weekdayFormat: 'dddd DD MMM',
    dayNameFormat: 'dddd',
    hoursFormat: 'HH:mm',
    exceptionDateFormat: 'YYYYMMDDTHHmmss',
    appRedirect: 'msauth://com.nurun.assistant/callback',
    msClientId: Constants.manifest.extra.msAppId ?? '',
    gtmId: Constants.manifest.extra.gtmId ?? '',
    officeImageUrl: 'https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/qa/map/',
    officeId: officeId,
    weeksToDisplay: 3,
    ...config()
};