import React from 'react';
import Avatar from './Avatar';
import {connect} from 'react-redux';
import {RootState} from 'StoreTypes';
import {loadBookingAsync} from '@features/booking/actions';
import {StyledText} from '@space/common';
import {colors} from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import {User} from 'UserFeature';
import { localize } from '../localization';
import { Dialog } from '../../../common/dist';
import { View } from 'react-native';
import TagManager from 'react-gtm-module'
import { event } from '../utils/analysticsConstants'

const mapStateToProps = (state: RootState) => ({
  selectedDate: state.booking.selectedDate,
  bookings: state.booking.bookings,
  error: state.booking.error,
  selected: state.booking.selectedBooking,
});

const dispatchProps = {
  loadBookings: loadBookingAsync.request,
};

type AvatarListProps = {
  users: User[];
  onPress: (user: User[]) => void;
  reloadData: boolean;
  isFromDashboard?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  AvatarListProps;

const AvatarCarousel: React.FC<Props> = ({onPress, error, users, selected, isFromDashboard }) => {

  const avatars: any[] = users.map((item, index) => (
    <View key={index} style={{marginRight: '6px'}}>
      <Avatar
        aria-labelledby={item.id}
        name={item.firstName}
        lastName={item.lastName}
        src={item.avatar}
        onPress={() => {
          onPress(users);
          TagManager.dataLayer({
            dataLayer: {
              event: event.GA4Employee,
              current_page: isFromDashboard ? event.GA4PageHome : event.GA4PageBooking,
              reservation_type: isFromDashboard ? event.DashboardOffice : `${selected ? 'Edit' : 'Made'} a reservation`,
              step: '1',
              button_label: localize('EMPLOYEE_PRESENT'),
            }
          })
        }}
      />
    </View>
  ));

  return (
    <Container title={'dialog'}>
      <ItemContainer accessibilityRole={'dialog'}>
        {avatars.length === 0 || error ? (
          <StyledText variant={'body1'} >
            {error
              ? localize('ERROR_DEFAULT')
              : localize('EMPTY_CARROUSEL_TITLE')}
          </StyledText>
        ) : (
          <AvatarContainer>
            {avatars.length <= 6 ? 
              avatars :
              <ExtraProfileContainer>
                <View style={{flexDirection: 'row'}}>
                  {avatars.slice(0, 6)}
                </View>
                <NumberOfExtraProfile onPress={() => {
                  onPress(users);
                  TagManager.dataLayer({
                    dataLayer: {
                      event: event.GA4Employee,
                      current_page: isFromDashboard ? event.DashboardOffice : `${selected ? 'Edit' : 'Made'} a reservation`,
                      button_label: localize('EMPLOYEE_PRESENT'),
                      step: '1',
                      reservation_type: `${selected ? 'Edit' : 'Made'} a reservation`
                    }
                  })
                }}>
                  <StyledText variant={'caption'} fontColor={colors.grey5}>
                    {`+${avatars.length - 6}`}
                  </StyledText>
                </NumberOfExtraProfile>
              </ExtraProfileContainer>
            }
          </AvatarContainer>
        )}
      </ItemContainer>
    </Container>
  );
};

const Container = styled.View`
  max-width: 328px;
  width: 100%;
  height: 40px;
  justify-content: center;
`;

const ItemContainer = styled.View`
  flex-direction: row;
`;

const ExtraProfileContainer = styled.View`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AvatarContainer = styled.View`
  flex-direction: row;
  display: flex;
  width: 100%;
`;

const NumberOfExtraProfile = styled.TouchableOpacity`
  width: 32px;
  height: 32px;
  border: 2px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  right: 0;
`;

export default connect(mapStateToProps, dispatchProps)(AvatarCarousel);
