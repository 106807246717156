import {Condition} from 'ConditionsFeature';
import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';
import {setConditions} from './actions';

const reducer = combineReducers({
  conditions: createReducer<Condition[]>({} as Condition[]).handleAction(
    [setConditions],
    (state, action) => action.payload,
  ),
});

export type AppState = ReturnType<typeof reducer>;

export default reducer;
