import React, { useEffect } from 'react';
import { Platform, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { AuthManager } from '@space/common';
import { AuthProviderName } from '../auth/AuthProviderName';
import {
  StandardAlert,
  StyledText,
  ThemeUtil,
  Dialog
} from '@space/common';
import { RootState } from 'StoreTypes';
import { authenticateAsync, clearError } from '@features/login/actions';
import { AuthenticationResult } from '@azure/msal-browser';
import Logo from '../img/flexable_logo.svg';
import styled, { useTheme } from 'styled-components/native';
import { palette, colors } from '@features/preferences/style/themes';
import { localize } from '../localization';
import * as _ from 'lodash';
import config from '../config';
import SpacingView from '@components/SpacingView';

const mapStateToProps = (state: RootState) => ({
  isAuthenticating: state.login.isLoading,
  isAuthenticated: state.login.isAuthenticated,
  loginError: state.login.error,
  theme: state.preferences.theme,
});

const dispatchProps = {
  authenticate: authenticateAsync.request,
  clearError: clearError,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const LoginView: React.FC<Props> = ({
  navigation,
  authenticate,
  isAuthenticated,
  isAuthenticating,
  loginError,
  clearError,
}) => {
  const authManager = AuthManager.getInstance(config);
  const theme = useTheme();
  const styleName = useTheme().mode;

  useEffect(() => {
    if (Platform.OS === 'web' && !isAuthenticating && _.isEmpty(loginError)) {
      authManager.client
        .handleRedirectPromise()
        .then((authResult: AuthenticationResult | null) => {
          if (authResult) {
            authenticate({
              token: authResult?.accessToken,
              provider: AuthProviderName.PUBLICIS,
            });
          }
        })
        .catch((error: Error) => {
          showLoginFailed();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoginFailed = () =>
    StandardAlert(localize('LOGIN_FAILED'), localize('ERROR_DEFAULT'));

  const closeLoginError = () => {
    clearError();
  }

  const onSignInClicked = async () => {
    if (Platform.OS === 'web') {
      await authManager.signIn().catch((error) => {
        console.log('🔴', error);
        showLoginFailed();
      });
    } else {
      await authManager
        .signIn()
        .then(async () => {
          await authManager.getToken().then((token) => {
            authenticate({
              token: token,
              provider: AuthProviderName.PUBLICIS,
            });
          });
        })
        .catch((error) => {
          console.log('🔴', error);
          showLoginFailed();
        });
    }
  };

  if (isAuthenticated) {
    navigation.navigate('HomeView');
  } else if (loginError) {
    console.log('🔴', loginError);
    showLoginFailed();
  }

  const logoProps = {
    fill: ThemeUtil.getInvertedThemeValue(theme.palette.common)(styleName),
    width: 250,
    height: 50,
  };

  return (
    <RootContainer>
      <RoundBackground></RoundBackground>
      <Header>
        <Logo {...logoProps} />
        <SpacingView height={'25px'} />
        <StyledText variant={'body1'} color={'primary'} fontSize={18}>
          {localize('LOGIN_MESSAGE')}
        </StyledText>
      </Header>
      <PicturesContainer>
        <LeftContainer>
          <img src={`https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/qa/static/login-view-static-1.png`} style={{ width: 132, height: 220}}/>
        </LeftContainer>
        <RightContainer>
          <img src={`https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/qa/static/login-view-static-2.png`} style={{ width: 132, height: 132}}/>
          <SpacingView height={'24px'} />
          <img src={`https://s3.ca-central-1.amazonaws.com/assistant.nurunmtl.com/qa/static/login-view-static-3.png`} style={{ width: 132, height: 212}}/>
        </RightContainer>

      </PicturesContainer>
      <Footer>
        <LoginButtonContainer onPress={onSignInClicked}>
          <StyledText variant={'body1'} fontColor={colors.white} fontWeight='600'> {localize('LOGIN')}</StyledText>
        </LoginButtonContainer>
      </Footer>
      {loginError && <Dialog
        isOpen={loginError}
        title={loginError}
        onClose={() => closeLoginError()}
        closeIcon={true}
      />}
    </RootContainer>
  );
};

const RootContainer = styled.View`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${Platform.OS === 'web' ? '100vw' : `${Dimensions.get('screen').width}px`};
  height: ${Platform.OS === 'web' ? '100vh' : `${Dimensions.get('screen').height}px`};
  overflow-y: hidden;
`;

const RoundBackground = styled.View`
  min-height: 418px;
  height: 50%;
  background: ${colors.white};
  border-bottom-left-radius: 12rem;
  border-bottom-right-radius: 12rem;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: auto;
  right: auto;
  left: auto;
  height: 50%;
`;

const Header = styled.View`
  max-width: 375px;
  display: flex;
  justify-content: center;
  padding: 48px 0 40px;
`;

const PicturesContainer = styled.View`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LeftContainer = styled.View`
  padding: 0 16px;
  justify-content: center;
`;

const RightContainer = styled.View`
  padding: 0 16px;
`;

const LoginButtonContainer = styled.TouchableOpacity`
  max-width: 327px;
  width: 327px;
  background: ${palette.primary};
  border-radius: 32px;
  height: 56px;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
`;

const Footer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoginViewName = 'LoginView';

export default connect(mapStateToProps, dispatchProps)(LoginView);
