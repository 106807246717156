import React, {useEffect, useContext} from 'react';
import {FlatList} from 'react-native';
import {
  deleteBookingAsync,
  loadMyBookingAsync,
  setSelectedDate,
  selectBooking,
} from '@features/booking/actions';
import {connect} from 'react-redux';
import {RootState} from 'StoreTypes';
import config from '../../config';
import moment from 'moment';
import BookingItemView from './BookingItemView';
import { colors, palette } from '@features/preferences/style/themes';
import styled, { useTheme } from 'styled-components/native';
import {useNavigation} from '@react-navigation/native';
import { Dialog, StyledText } from '@space/common';
import {BookingViewName} from '../../routes/BookingView';
import {Booking} from 'BookingFeature';
import { localize } from '../../localization';
import { SpaceHelper } from '../../utils/SpaceHelper';
import { ThemeUtil } from '@space/common';
import { fetchSpacesAsync } from '@features/space/actions';
import TagManager from 'react-gtm-module'
import { event } from '../../utils/analysticsConstants'
import { isEmpty } from 'lodash';

const mapStateToProps = (state: RootState) => ({
  isLoading: state.booking.isLoadingMyBooking,
  user: state.login.user,
  error: state.booking.error,
  bookings: state.booking.currentUserBookings,
  lastUserBooking: state.booking.userLastBooking,
  spaceGroups: state.space.spaceGroups,
  spaceTags: state.space.spaceTags,
  bookingError: state.booking.error,
});

const dispatchProps = {
  loadBooking: loadMyBookingAsync.request,
  showBooking: () => {},
  selectDate: setSelectedDate,
  deleteBooking: deleteBookingAsync.request,
  selectBooking: selectBooking,
  fetchSpaces: fetchSpacesAsync.request,
};
type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const BookingsList: React.FC<Props> = ({
  bookings,
  loadBooking,
  user,
  lastUserBooking,
  selectDate,
  deleteBooking,
  selectBooking,
  spaceGroups,
  spaceTags,
  fetchSpaces
}) => {
  const [booking, setBooking] = React.useState<Booking | null>(null);
  const navigation = useNavigation();
  const styleName = useTheme().mode;

  useEffect(() => {
    loadBooking(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceGroups]);

  useEffect(() => {
    const startDate = moment().utc().format(config.defaultDateFormat);
    const endDate = moment().utc().add(3, 'w').format(config.defaultDateFormat);
    fetchSpaces({ startDate, endDate });
  },[!isEmpty(bookings)])

  const renderItem = (data) => {
    return (
      <BookingItemView
        confirmationView={false}
        key={data.item.id}
        booking={data.item}
        spaceTags={spaceTags}
        spaceGroups={spaceGroups}
        onClosePress={() => {
          setBooking(data.item);
          TagManager.dataLayer({
            dataLayer: {
              event: event.GA4DeleteBooking,
              current_page: event.GA4PageHome,
              button_label: `${localize('DELETE')}`,
              date_selected: `${moment(data.item.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_ONLYDAY_FORMAT'))}`,
            }
          })
        }}
        onPress={() => {
          selectBooking(data.item);
          selectDate(
            moment(data.item.schedule.startDate).format(
              config.defaultDateFormat,
            ),
          );
          navigation.navigate(BookingViewName);
          TagManager.dataLayer({
            dataLayer: {
              event: event.GA4EditBooking,
              current_page: event.GA4PageHome,
              button_label: `${localize('MODIFY')}`,
              date_selected:`${moment(data.item.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_ONLYDAY_FORMAT'))}`,
            }
          })
        }}
        isNew={data.item.id === lastUserBooking?.id}
      />
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <StyledText variant={'h2'} fontColor={colors.black}>
          {localize('MY_BOOKINGS')}
        </StyledText>
        <OccupationContainer>
          <StyledText variant={'body1'} color={'primary'} fontWeight={600} fontSize={16}>
            {bookings.length}
          </StyledText>
          <StyledText variant={'body1'} color={'primary'} fontWeight={600} fontSize={16}>
            {' / ' + SpaceHelper.userMaxReservation(user)}
          </StyledText>
        </OccupationContainer>
      </HeaderContainer>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={bookings}
        renderItem={renderItem}
        contentContainerStyle={{paddingBottom: 24}}
      />
      {booking && (
        <Dialog
          isOpen={booking !== null}
          title={localize('ATTENTION')}
          body={localize('DELETE_BOOKING_ALERT_TITLE')}
          onClose={() => {
            setBooking(null);
            TagManager.dataLayer({
              dataLayer: {
                event: event.GA4DeleteBooking,
                current_page: event.GA4PageHome,
                button_label: `${localize('CANCEL')}`,
                date_selected:`${moment(booking?.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_ONLYDAY_FORMAT'))}`,
              }
            })
          }}
          actionTitle={localize('DELETE')}
          cancelTitle={localize('CANCEL')}
          cancelVariant={'primary'}
          cancelInverted={false}
          cancelFontColor={ThemeUtil.getThemeValue(palette.common)(styleName)}
          onbuttonAction={() => {
            deleteBooking(booking);
            setBooking(null);
            TagManager.dataLayer({
              dataLayer: {
                event: event.GA4DeleteBooking,
                current_page: event.GA4PageHome,
                button_label: `${localize('DELETE')}`,
                date_selected:`${moment(booking?.schedule.startDate).locale(localize('LOCALE_LANGUAGE')).format(localize('DATE_ONLYDAY_FORMAT'))}`,
              }
            })
          }}
        />
      )}
    </Container>
  );
};
export default connect(mapStateToProps, dispatchProps)(BookingsList);

const Container = styled.View`
  width: 100%;
  padding: 40px 0px 0px 0px;
  margin-bottom: 170px;
`;

const HeaderContainer = styled.View`
  width: 100%;
  max-width: 343px;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const OccupationContainer = styled.View`
  flex-direction: row;
  align-items: center;
  align-content: flex-end;
`;
