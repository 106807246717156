import { combineEpics } from 'redux-observable';

import * as booking from '@features/booking/epics';
import * as login from '@features/login/epics';
import * as space from '@features/space/epics';
import * as user from '@features/user/epics';
import * as organization from '@features/organization/epics';
import * as home from '@features/home/epics';

export default combineEpics(
  ...Object.values(booking),
  ...Object.values(login),
  ...Object.values(space),
  ...Object.values(user),
  ...Object.values(organization),
  ...Object.values(home),
);
