import React, { useContext, useEffect, useState } from 'react';
import { RootState } from 'StoreTypes';
import { connect } from 'react-redux';
import { TouchableOpacity, FlatList, Modal } from 'react-native';
import {
  setSelectedSpacesForBooking,
  showUserSelectionView,
  setSelectedUserMappingForBooking,
  createBookingAsync,
} from '@features/booking/actions';
import { useNavigation } from '@react-navigation/native';
import { hideModalView } from '@features/modal/actions';
import { ConditionsViewName } from '../ConditionsView';
import ModalHeader from '@components/modal/ModalHeader';
import { colors, palette, shadow } from '@features/preferences/style/themes';
import styled from 'styled-components/native';
import _ from 'lodash';
import CalendarHeader from '@components/Calendar/CalendarHeader';
import { Button, ModalView, StyledText, CircularIcon } from '@space/common';
import { SvgType } from '@components/SvgIcon';
import UserSelectionView from '../user/UserSelectionView';
import { Space, SpaceTag } from 'SpaceFeature';
import { User } from 'UserFeature';
import { BookingRequestBody, SpaceUserMapping } from 'BookingFeature';
import {
  loadUsersAsync,
  setSpaceIdSelectedForMapping,
} from '@features/user/actions';
import Avatar from '@components/Avatar';
import { updateDateWithOfficeHours } from '../../utils';
import { localize } from '../../localization';
import SelectUser from '../../img/select_user.svg';

const mapStateToProps = (state: RootState) => ({
  isUserSelectionViewDisplayed: state.booking.isUserSelectionViewDisplayed,
  spaceList: state.space.space,
  spaceGroups: state.space.spaceGroups,
  spaceTags: state.space.spaceTags,
  selectedDate: state.booking.selectedDate,
  selectedSpaceType: state.booking.selectedSpaceType,
  selectedSpaces: state.booking.selectedSpacesForBooking,
  selectedUserMappingForBooking: state.booking.selectedUserMappingForBooking,
  selectedSpaceIdForMapping: state.user.spaceIdSelectedForMapping,
  users: state.user.users,
  user: state.login.user,
  createBookingError: state.booking.bookingError,
  lastBookingCreated: state.booking.userLastBooking,
  office: state.organization.office
});

const dispatchProps = {
  hideModalView: hideModalView,
  showUserSelection: showUserSelectionView,
  selectSpaces: setSelectedSpacesForBooking,
  selectSpaceIdForMapping: setSpaceIdSelectedForMapping,
  selectUserMapping: setSelectedUserMappingForBooking,
  fetchUsers: loadUsersAsync.request,
  createBooking: createBookingAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & any;

const BookingAssignSpacesView: React.FC<Props> = ({
  isUserSelectionViewDisplayed,
  selectedSpaceType,
  showUserSelection,
  selectedSpaces,
  users,
  user,
  selectedUserMappingForBooking,
  selectSpaceIdForMapping,
  selectedDate,
  selectSpaces,
  selectUserMapping,
  fetchUsers,
  createBooking,
  createBookingError,
  route,
  spaceTags,
  office
}) => {
  React.useEffect(() => {
    if (route?.params?.term) {
      createBooking(booking);
      selectSpaces([]);
      selectSpaceIdForMapping([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.params?.term]);

  const [booking, setBooking] = useState<BookingRequestBody>();
  const navigation = useNavigation();

  const handleValidateBooking = () => {
    const date = updateDateWithOfficeHours(
      office,
      selectedDate,
    );
    const userIds = selectSpaceIdForMapping.map((item: SpaceUserMapping)=>item.userId);
    setBooking({
      title: 'Booking - Pod',
      description: '',
      typeId: selectedSpaceType._id,
      organizer: user,
      spaceUserMapping: selectedUserMappingForBooking,
      users: [users.filter((user: User)=>userIds.contains(user.id))],
      spaces: selectedSpaces,
      schedule: {
        startDate: date.startDate,
        endDate: date.endDate,
        rrule: '',
      },
    });
    navigation.navigate(ConditionsViewName, {
      redirectViewName: BookingAssignSpacesViewName,
    });
  };

  const renderEmptySpace = (space: Space, tags: string) => {
    return (
      <StyledItemContainer>
        <StyledItemLeftContainer>
          <CircularIcon
            svg={SelectUser}
            inverted={false}
            size={'small'}
            accessibilityLabel={
              localize('BOOKING_ASSIGN_SPACE_SELECT_USER_ACCESSIBILITY')
            }
            variant={'fill'}
            fillColor={colors.grey4}
          />
        </StyledItemLeftContainer>
        <StyledItemRightContainer>
          <StyledText variant={'h5'}>{space.name}</StyledText>
          <StyledText variant={'body4'} fontColor={colors.grey3}>
            {tags}
          </StyledText>
        </StyledItemRightContainer>
      </StyledItemContainer>
    );
  };

  const renderUserMappedSpace = (
    space: Space,
    tags: string,
    mapping: SpaceUserMapping,
  ) => {
    const selectedUser = users
      ? (_.first(
        users.filter((user: User) => user.id === mapping.userId),
      ) as User)
      : null;

    const name = selectedUser
      ? selectedUser.firstName + ' ' + selectedUser.lastName
      : 'Undefined';

    return (
      <StyledItemContainer>
        <StyledItemLeftContainer>
          <Avatar
            name={selectedUser?.firstName ?? ''}
            lastName={selectedUser?.lastName ?? ''}
          />
        </StyledItemLeftContainer>

        <StyledItemRightContainer>
          <StyledText variant={'body1'}>{name}</StyledText>
          <StyledText variant={'h5'}>{space.name}</StyledText>
          <StyledText variant={'body4'} fontColor={colors.grey3}>
            {tags}
          </StyledText>
        </StyledItemRightContainer>
      </StyledItemContainer>
    );
  };

  const renderSpaceItem = ({ item }) => {
    const tags = spaceTags.filter((tag: SpaceTag) => item.tagIdsList.includes(tag.id)).map((tag: SpaceTag) => tag.name).join('. ');

    const mapping = _.first(
      selectedUserMappingForBooking.filter(
        (userSpace) => userSpace.spaceId === item.id,
      ),
    ) as SpaceUserMapping;

    return (
      <TouchableOpacity
        onPress={() => {
          selectSpaceIdForMapping(item.id);
          showUserSelection(true);
        }}>
        {mapping
          ? renderUserMappedSpace(item, tags, mapping)
          : renderEmptySpace(item, tags)}
      </TouchableOpacity>
    );
  };

  const title = localize('BOOKING_ASSIGN_SPACE_TITLE', { name: selectedSpaceType.name })

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBackground>
      <ModalHeader
        leftAction={'back'}
        title={title}
        onPress={() => {
          selectUserMapping([]);
          navigation.goBack();
        }}
      />
      {createBookingError && (
        <Modal visible={true}>
          <StyledText>{createBookingError}</StyledText>
        </Modal>
      )}
      <StyledContent>
        <CalendarHeader displayWeekView={false} />

        <FlatList
          data={selectedSpaces}
          renderItem={renderSpaceItem}
          keyExtractor={(item) => item.id}
          extraData={selectedUserMappingForBooking}
        />
        <StyledFooter>
          <Button
            fontColor={colors.grey5}
            disabled={
              selectedUserMappingForBooking.length === selectSpaces.length
            }
            title={localize('BOOK')}
            onPress={handleValidateBooking}
          />
        </StyledFooter>
      </StyledContent>
      <ModalView isVisible={isUserSelectionViewDisplayed}>
        <UserSelectionView />
      </ModalView>
    </StyledBackground>
  );
};

const StyledBackground = styled.View<any>`
  background-color: ${palette.background};
  height: 100vh;
  width: 100%;
  align-items: center;
`;

const StyledItemContainer = styled.View`
  align-items: stretch;
  flex-direction: row;
  min-width: 256px;
  border: 1px solid ${palette.border};
  border-radius: 4px;
  min-height: 64px;
  padding: 4px 15px;
  margin: 8px 24px;
  box-shadow: ${shadow};
`;

const StyledItemLeftContainer = styled.View`
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-right: 10px;
  width: 44px;
  height: 44px;
`;

const StyledItemRightContainer = styled.View`
  align-items: flex-start;
  justify-content: center;
`;

const StyledContent = styled.View`
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

const StyledFooter = styled.View`
  flex: 1 1 auto;
  margin: 0px 24px;
  justify-content: center;
  align-items: stretch;
  padding-top: 33px;
`;

export const BookingAssignSpacesViewName = 'BookingAssignSpacesView';

export default connect(mapStateToProps, dispatchProps)(BookingAssignSpacesView);
