import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';''
import { Space, SpaceGroup, SpaceTag, Occupancy, SpaceCache } from 'SpaceFeature';
import { SpaceModel } from '@features/space';
import {
  fetchSpaceGroupsAsync,
  fetchSpacesAsync,
  fetchSpaceTagsAsync,
  cacheOccupancy
} from './actions';
import config from '../../config';
import { Booking } from 'BookingFeature';
import { weeksForOfficeInAdvanceBookingLimit, openDates } from "@space/common";
import SpaceHelper from '../../utils/SpaceHelper';
import moment from 'moment';
import _ from "lodash";

const reducer = combineReducers({
  isLoading: createReducer(false as boolean)
    .handleAction([fetchSpacesAsync.request], (state, action) => true)
    .handleAction(
      [fetchSpacesAsync.success, fetchSpacesAsync.failure],
      (state, action) => false,
    ),

  space: createReducer([] as Space[]).handleAction(
    fetchSpacesAsync.success,
    (state, action) => {
      action.payload.map((space: Space)=>{
        const bookings = space.bookings.filter((booking : Booking) => booking.office.hostId === config.officeId );
        space.bookings = bookings;
        return space;
      })
      return action.payload;
    },
  ),

  availableSpacesCache: createReducer(new Map() as Map<string, SpaceCache>).handleAction(
    fetchSpacesAsync.success,
    (state, action) => {

      var cache:Map<string, SpaceCache> = new Map();
      const firstSpace = _.first(action.payload) as Space;
      if(firstSpace != undefined) {
        const office = firstSpace.office;
        const officeBookingWeeks = weeksForOfficeInAdvanceBookingLimit(office.inAdvanceBookingLimit);
        const officeOpenDates = openDates(office.rrule, officeBookingWeeks);
        officeOpenDates.forEach((value: boolean, dateKey: string) => {

          var spaceIsBookable:Map<string, boolean> = new Map();
          var occupancies:Map<string, Occupancy | null> = new Map();

          if(!value) {
            action.payload.map((space: Space)=>{
              spaceIsBookable.set(space.id, false);

              occupancies.set('all', null);
              occupancies.set('seat', null);
              occupancies.set('room', null);
              occupancies.set('pod', null);
            });
          } else {
            const mDate = moment(dateKey);
            action.payload.map((space: Space)=>{
              spaceIsBookable.set(space.id, SpaceHelper.isOpenSpace(space, mDate));
            });

            const allOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey);
            occupancies.set('all', allOccupancy);
            const seatOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey, SpaceModel.SEAT);
            occupancies.set('seat', seatOccupancy);
            const roomOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey, SpaceModel.ROOM);
            occupancies.set('room', roomOccupancy);
            const podOccupancy = SpaceHelper.occupationForDate(action.payload, spaceIsBookable, dateKey, SpaceModel.POD);
            occupancies.set('pod', podOccupancy);
          }

          cache.set(dateKey, {openSpaces: spaceIsBookable, occupancies: occupancies});
        });
      }
      return cache;
    },
  ),

  isLoadingSpaceGroups: createReducer<boolean>(false)
    .handleAction([fetchSpaceGroupsAsync.request], (state, action) => true)
    .handleAction(
      [fetchSpaceGroupsAsync.success, fetchSpaceGroupsAsync.failure],
      (state, action) => false,
    ),

  spaceGroupsError: createReducer('')
    .handleAction(
      [fetchSpaceGroupsAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction([fetchSpaceGroupsAsync.request], (state, action) => ''),

  spaceGroups: createReducer([] as SpaceGroup[]).handleAction(
    fetchSpaceGroupsAsync.success,
    (state, action) => {
      return action.payload;
    },
  ),

  error: createReducer('')
    .handleAction(fetchSpacesAsync.failure, (state, action) => action.payload)
    .handleAction(fetchSpacesAsync.request, (state, action) => ''),

  isLoadingSpaceTags: createReducer<boolean>(false)
    .handleAction([fetchSpaceTagsAsync.request], (state, action) => true)
    .handleAction(
      [fetchSpaceTagsAsync.success, fetchSpaceTagsAsync.failure],
      (state, action) => false,
    ),

  spaceTagsError: createReducer('')
    .handleAction(
      [fetchSpaceTagsAsync.failure],
      (state, action) => action.payload,
    )
    .handleAction([fetchSpaceTagsAsync.request], (state, action) => ''),

  spaceTags: createReducer([] as SpaceTag[]).handleAction(
    fetchSpaceTagsAsync.success,
    (state, action) => {
      return action.payload;
    },
  ),

});

export default reducer;
