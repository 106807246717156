import React from "react";
import { TouchableOpacity } from 'react-native';
import View from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { StyledText, ThemeUtil } from '@space/common';
import { useState } from "react";
import {
    colors,
    palette,
} from '@features/preferences/style/themes';
import theme from 'styled-theming';
import TagManager from 'react-gtm-module'
import { event } from '../utils/analysticsConstants'

type FileInputProps = {
    title: string;
    onSelectFile: (file: File) => void;
    onPreviewLoaded: (buffer: string | ArrayBuffer | null) => void;
    onFileSizeError: () => void;
    category: string;
    action: string;
    label: string;
};

const fontOptions = theme('mode', {
    light: colors.grey5,
    dark: colors.white,
});

const StyledFileInput: React.FC<FileInputProps> = ({
    title,
    onSelectFile,
    onPreviewLoaded,
    onFileSizeError,
    category,
    action,
    label
}) => {

    const styleName = useTheme().mode;
    const fontColor = ThemeUtil.getThemeValue(fontOptions)(styleName);
    const maxAllowedSize = 2097152;

    const [currentFile, setFile] = useState<File | undefined>();

    const resetFile = () => {
        setFile(undefined);
    };

    const onlocalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files[0].size > maxAllowedSize) {
            onFileSizeError();
            resetFile();
            event.target.value = "";
            return;
        };
     
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            setFile(file);
            let reader = new FileReader();

            reader.onloadend = () => {
                onPreviewLoaded(reader.result);
            };

            reader.readAsDataURL(file);
            onSelectFile(file);
        }
        event.target.value = "";
    }

    return (
        <StyledTouchableOpacity>
            <input type="file" name="file" id="file" style={{
                width: '0.1px',
                height: '0.1px',
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute'
            }} onChange={onlocalChange} />
            <label 
                onClick={() => 
                    category && action && label &&
                    TagManager.dataLayer({
                        dataLayer: { 
                            event: action,
                            current_page: category,
                            buttton_label: label,
                        }
                    })
                } 
                htmlFor="file" 
                style={{
                    fontFamily: 'Outfit_400Regular',
                    color: colors.white,
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}>
                {title}
            </label>
        </StyledTouchableOpacity>
    );
}

export default StyledFileInput;

const StyledTouchableOpacity = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;


